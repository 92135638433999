import { format, isAfter } from 'date-fns';
import { ReactElement, useMemo } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { slotsUtility } from '../../../utility/slots';
import CalendarDayJob from './CalendarDayJob';
import {
  AllEnum,
  CalendarPeriod,
  useCalendarContext,
} from '../../../utility/calendarContext';
import { Option } from '../../atoms/Dropdown';
import {
  getFragmentData,
  JobDifficulty,
  JobStatus,
  JobType,
} from '@monorepo/graphql';
import EmptyState from '../EmptyState';
import AddSlot from './AddSlot';
import { client } from '../../../main';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

interface Props {
  date: Date;
  organisationUuid?: string;
}

const CalendarDay = ({ date, organisationUuid }: Props): ReactElement => {
  const { filters, selectedPeriod } = useCalendarContext();

  const filterVars = useMemo(() => {
    const statuses = filters.states.filter(
      (opt): opt is Option<JobStatus> => opt.value !== AllEnum.all,
    );
    const difficulties = filters.difficulty.filter(
      (opt): opt is Option<JobDifficulty> => opt.value !== AllEnum.all,
    );
    const jobTypes = filters.jobType.filter(
      (opt): opt is Option<JobType> => opt.value !== AllEnum.all,
    );
    const schemes = filters.schemes.filter(
      (opt): opt is Option<string> => opt.value !== 'all',
    );
    const contractors = filters.contractors.filter(
      (opt): opt is Option<string> => opt.value !== 'all',
    );
    return {
      status: statuses.length ? statuses.map(({ value }) => value) : undefined,
      difficulty: difficulties.length
        ? difficulties.map(({ value }) => value)
        : undefined,
      jobType: jobTypes.length ? jobTypes.map(({ value }) => value) : undefined,
      schemes: schemes.length ? schemes.map(({ value }) => value) : undefined,
      contractors: contractors.length
        ? contractors.map(({ value }) => value)
        : undefined,
    };
  }, [filters]);

  const { data } = useSuspenseQuery(slotsUtility.queries.GET_SLOTS, {
    variables: {
      input: {
        filter: {
          date: new Date(format(date, 'yyyy-MM-dd')), // prevent stupid UTC conversion
          organisationUuid,
          ...filterVars,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    context: {
      isBatched: true,
    },
  });

  return (
    <div className="flex-grow w-full">
      {data.indexSlots.items.length ? (
        <div className="pt-px space-y-2">
          {data.indexSlots.items.map((s, i) => (
            <CalendarDayJob
              isOrganisation={!!organisationUuid}
              onDelete={(uuid) => {
                if (uuid) {
                  client.graphqlClient().cache.updateQuery(
                    {
                      query: slotsUtility.queries.GET_SLOTS,
                      variables: {
                        input: {
                          filter: {
                            date,
                            organisationUuid,
                            ...filterVars,
                          },
                        },
                      },
                    },
                    (q) => ({
                      indexSlots: {
                        ...q?.indexSlots,
                        items:
                          q?.indexSlots.items.filter(
                            (fragment) =>
                              getFragmentData(
                                slotsUtility.queries.SLOT_FRAGMENT,
                                fragment,
                              ).uuid !== uuid,
                          ) ?? [],
                      },
                    }),
                  );
                  client.graphqlClient().cache.gc();
                }
              }}
              key={i}
              slot={s}
              date={date}
            />
          ))}
        </div>
      ) : (
        selectedPeriod === CalendarPeriod.day && (
          <div className="flex-grow bg-white">
            <EmptyState
              title="No jobs"
              description="There are no jobs booked on this day."
            />
          </div>
        )
      )}
      {isAfter(date, new Date()) ? (
        <AddSlot
          selectedOrganisationUuid={organisationUuid}
          date={date}
          onAdd={(data) => {
            if (data) {
              client.graphqlClient().cache.updateQuery(
                {
                  query: slotsUtility.queries.GET_SLOTS,
                  variables: {
                    input: {
                      filter: {
                        date,
                        organisationUuid,
                        ...filterVars,
                      },
                    },
                  },
                },
                (q) => ({
                  indexSlots: {
                    ...q?.indexSlots,
                    items: [...(q?.indexSlots.items ?? []), data],
                  },
                }),
              );
            }
          }}
        />
      ) : (
        <div className="pb-10" />
      )}
    </div>
  );
};
export default (props: Props) => (
  <SuspendedComponent>
    <CalendarDay {...props} />
  </SuspendedComponent>
);
