import {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Button } from '../../atoms/Button';
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  PhoneIcon,
  RectangleStackIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import AvatarStack from '../../atoms/AvatarStack';
import { Link, useRouter } from '@tanstack/react-router';
import Dropdown from '../../atoms/Dropdown';
import Tag from '../../atoms/Tag';
import UserDropdown from '../../molecules/UserDropown';
import TwoLineText from '../../atoms/TwoLineText';
import DifficultyIndicator from '../../atoms/DifficultyIndactor';
import Tabs from '../../molecules/Tabs';
import { SurveyTab } from './Tabs/Survey';
import { jobsUtility } from '../../../utility/jobs';
import { JobTab } from './Tabs/Job';
import SimpleModal from '../../molecules/Modals/Simple';
import AddDatesToJob from '../../molecules/Modals/AddDatesToJob';
import TasksTab from './Tabs/Tasks';
import SkillsTab from './Tabs/Skills';
import AuditModal from '../../molecules/Modals/Audit';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import {
  gql,
  ReadJobQuery,
  TargetType,
  JobStatus,
  UserProfileSource,
  AddDatesToJobType,
  RevisitRequiredDataStatus,
} from '@monorepo/graphql';
import { styleUtility } from '../../../utility/styleUtility';
import { useFragment, useMutation } from '@apollo/client';
import { notify } from '../../../utility/notify';
import ContractorPanel from './ContractorPanel';
import RoofTab from './Tabs/Roofs';
import CommentsTab from './Tabs/Comments';
import DnoTab from './Tabs/Dno';
import ProgressIndicator from './ProgressIndicator';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';
import AuditorsSelection from './AuditorsSelection';
import AuditsTab from './Tabs/Audits';
import FormSubmission from './Tabs/FormSubmission';
import { Modal, ModalWrapper } from '../../molecules/Modals/Base';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { format } from 'date-fns';
import { CircleIcon } from '../../icons/Circle';
import Alert from '../../atoms/Alerts';

interface Props {
  jobUuid: string;
}

enum JobMenuOption {
  reschedule = 'reschedule',
  cancel = 'cancel',
  revisitRequests = 'revisitRequests',
}

const JobContext = createContext<IJobContext>({} as IJobContext);

export const useJobContext = () => {
  const context = useContext(JobContext);
  return context;
};

const UPDATE_JOB = gql(`
  mutation UpdateJob ($input: UpdateJobInput!) {
    updateJob(input: $input) {
      status
    }
  }  
`);

interface IJobContext {
  job: ReadJobQuery['readJob'];
  canAmendSurveyDetails: boolean;
  canAmendInstallationDetails: boolean;
}

const Job = ({ jobUuid }: Props): ReactElement => {
  const { data: job, complete } = useFragment({
    from: `Job:${jobUuid}`,
    fragment: jobsUtility.queries.JOB_FRAGMENT,
  });

  if (!complete) throw new Error('Job fragment is incomplete.');

  const [operatorUuid, setOperatorUuid] = useState<string>(
    job.operatorUuid ?? '',
  );
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const router = useRouter();

  const [jobMenuSelection, setJobMenuSelection] = useState<JobMenuOption>();

  const [saveJob, { loading: savingJob }] = useMutation(UPDATE_JOB, {
    onCompleted: () => notify.success('Updated job.'),
  });

  const canAmendInstallationDetails = useMemo(
    () =>
      [JobStatus.completed, JobStatus.installed, JobStatus.cancelled].includes(
        job.status,
      )
        ? false
        : true,
    [job],
  );

  const isPendingRevisit = useMemo(
    () => job.revisitRequired?.status === RevisitRequiredDataStatus.pending,
    [job],
  );

  return (
    <JobContext.Provider
      value={{
        job,
        canAmendSurveyDetails: job.customer.source !== UserProfileSource.api,
        canAmendInstallationDetails,
      }}
    >
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col w-full h-full">
          <div className="flex items-center p-4">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Job #{job.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
            <Dropdown
              buttonClassname="bg-white h-11 px-2.5 rounded border border-grey-500"
              ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
              onOptionSelect={(opt) => setJobMenuSelection(opt.value)}
              options={[
                {
                  value: JobMenuOption.reschedule,
                  name:
                    job.status === JobStatus.cancelled
                      ? 'Book new date'
                      : job.revisitRequired
                        ? job.revisitRequired.status !==
                          RevisitRequiredDataStatus.booked
                          ? 'Book revisit'
                          : 'Change revisit date'
                        : 'Change job date',
                  Icon: <CalendarDaysIcon className="size-5 " />,
                },
                ...(job.revisitRequired?.requests.length
                  ? [
                      {
                        value: JobMenuOption.revisitRequests,
                        name: 'View revisit requests',
                        Icon: <WrenchScrewdriverIcon className="size-5" />,
                      },
                    ]
                  : []),
                ...(job.status !== JobStatus.cancelled
                  ? [
                      {
                        value: JobMenuOption.cancel,
                        name: 'Cancel Job',
                        itemClassname: 'text-red',
                        Icon: <XCircleIcon className="size-5 text-red" />,
                      },
                    ]
                  : []),
              ]}
            />
          </div>
          <div className="flex flex-col overflow-hidden h-full">
            <div className="overflow-scroll flex h-full flex-col px-4">
              <div className="bg-white rounded-lg">
                <div className="p-5 flex justify-start">
                  <AvatarStack
                    height="h-12"
                    width="w-12"
                    avatars={[
                      {
                        firstName: job.customer.firstName,
                        lastName: job.customer.lastName,
                      },
                    ]}
                  />
                  <div className="space-y-2.5 px-5 flex-grow">
                    <Link
                      to="/contacts/$uuid"
                      params={{ uuid: job.customer.userUuid }}
                    >
                      <h1 className="text-h1-small font-bold font-nunito underline">
                        {job.customer.firstName} {job.customer.lastName}
                      </h1>
                    </Link>
                    {job.address && (
                      <h4 className="font-nunito text-h4">
                        {[
                          job.address.line1,
                          job.address.line2,
                          job.address.city,
                          job.address.postcode,
                        ]
                          .filter((a) => a)
                          .join(', ')}
                      </h4>
                    )}
                  </div>
                  <div>
                    <Tag
                      size="lg"
                      colour={job.status}
                      text={jobsUtility.jobStatusNiceMap[job.status]}
                    />
                  </div>
                </div>
                <hr className="border-none w-full h-px bg-grey-700" />
                <div className="flex items-center p-5 space-x-12">
                  <div className="flex flex-col flex-grow items-center w-1/4">
                    <UserDropdown
                      stacked
                      userUuid={operatorUuid}
                      setUserUuid={(_, profileUuid) => {
                        setOperatorUuid(profileUuid);
                        void saveJob({
                          variables: {
                            input: {
                              uuid: job.uuid,
                              operatorUuid: profileUuid,
                            },
                          },
                        });
                      }}
                      partnerUuid={job.partner.uuid}
                    />
                    <span className="text-body-small text-text-low-priority">
                      Operator
                    </span>
                  </div>
                  <div className="flex flex-col flex-grow justify-center items-center w-1/4">
                    <AuditorsSelection />
                  </div>
                  <div className="flex flex-col flex-grow items-center w-1/4">
                    <SuspendedComponent multiplier={0.5}>
                      <ProgressIndicator />
                    </SuspendedComponent>
                  </div>
                  {/* <div className="flex flex-col items-center w-1/4">
                    <LifebuoyIcon className="text-grey-400 size-9" />
                    <span className="text-body-small mt-1.5">Ticket #4456</span>
                    <Link
                      to="/tickets/$uuid"
                      params={{ uuid: '' }}
                      className="text-body-small text-primary underline"
                    >
                      View Ticket
                    </Link>
                  </div> */}
                </div>
                <hr className="border-none w-full h-px bg-grey-700" />
                <div className="grid grid-cols-2 gap-5 p-5">
                  <div className="flex-grow">
                    <TwoLineText
                      label="Difficulty"
                      text={styleUtility.capitalise(job.difficulty)}
                      Icon={<DifficultyIndicator difficulty={job.difficulty} />}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Scheme"
                      text={job.schemeName}
                      Icon={
                        <RectangleStackIcon className="size-5 text-grey-400" />
                      }
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Partner"
                      text={job.partner.name}
                      Icon={
                        <BuildingOffice2Icon className="size-5 text-grey-400" />
                      }
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Customer email"
                      text={job.customer.email}
                      Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Type"
                      text={styleUtility.capitalise(job.type)}
                      Icon={jobsUtility.jobTypeIconMap[job.type]}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Customer phone"
                      text={job.customer.phoneNumber}
                      Icon={<PhoneIcon className="size-5 text-grey-400" />}
                    />
                  </div>
                </div>
              </div>
              <div className="my-5 flex-grow">
                <Tabs
                  tabs={[
                    ...(job.survey.formSubmission
                      ? [
                          {
                            name: 'Form Submission',
                            value: 'form',
                            component: (
                              <FormSubmission
                                formSubmission={job.survey.formSubmission}
                              />
                            ),
                          },
                        ]
                      : []),
                    {
                      name: styleUtility.capitalise(job.type),
                      value: 'job',
                      component: (
                        <SuspendedComponent>
                          <JobTab />
                        </SuspendedComponent>
                      ),
                    },
                    {
                      name: 'Survey',
                      value: 'survey',
                      component: <SurveyTab />,
                    },
                    {
                      name: 'Tasks',
                      value: 'tasks',
                      component: <TasksTab />,
                    },
                    {
                      name: 'Comments',
                      value: 'comments',
                      component: <CommentsTab />,
                    },
                    {
                      name: 'Roofs',
                      value: 'roofs',
                      component: <RoofTab />,
                    },
                    {
                      name: 'Skills',
                      value: 'skills',
                      component: <SkillsTab />,
                    },
                    ...(job.dnoUuid
                      ? [
                          {
                            name: 'DNO',
                            value: 'dno',
                            component: <DnoTab dnoUuid={job.dnoUuid} />,
                          },
                        ]
                      : []),

                    {
                      name: 'Audits',
                      value: 'audits',
                      component: <AuditsTab />,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 bg-white h-full w-full max-w-90 overflow-scroll space-y-5">
          <h3 className="font-nunito text-h3 font-bold">Assignments</h3>
          {job.status === JobStatus.cancelled ? (
            <Alert
              alertType="error"
              text="This job is cancelled."
            />
          ) : isPendingRevisit ? (
            <Alert
              alertType="warning"
              text="This job is pending a revisit, book in a return date to complete the job."
            />
          ) : undefined}
          <div className="flex justify-between">
            <TwoLineText
              label="Job date"
              text={job.displayDate}
              Icon={<CalendarIcon className="size-5 text-grey-400" />}
            />
            {job.isSigned &&
              canAmendInstallationDetails &&
              !isPendingRevisit && (
                <Button
                  className="!border-none"
                  Icon={<PencilSquareIcon className="size-6" />}
                  bStyle="light"
                  onClick={() => setJobMenuSelection(JobMenuOption.reschedule)}
                />
              )}
          </div>

          {job.organisationName && (
            <TwoLineText
              label="Organisaton"
              text={job.organisationName}
              Icon={<UserGroupIcon className="size-5 text-grey-400" />}
            />
          )}

          <TwoLineText
            label="Duration"
            text={
              job.daysRequired > 1
                ? `${job.daysRequired} days`
                : job.duration < 8
                  ? `${job.duration} hours`
                  : `1 day`
            }
            Icon={<ClockIcon className="h-5 text-grey-400" />}
          />

          {!isPendingRevisit && (
            <>
              <hr className="border-none w-full h-px bg-grey-700" />
              <div className="flex-grow flex flex-col">
                <ContractorPanel />
              </div>
            </>
          )}
        </div>
      </div>
      <SimpleModal
        text="If you can this job you will have to contact the customer to rebook it."
        title="Cancel Job"
        loading={savingJob}
        onConfirm={() => {
          void saveJob({
            variables: {
              input: {
                uuid: job.uuid,
                status: JobStatus.cancelled,
              },
            },
            update: (cache) =>
              cache.updateFragment(
                {
                  id: cache.identify(job),
                  fragment: jobsUtility.queries.JOB_FRAGMENT,
                },
                (d) => {
                  if (d && Object.keys(d).length) {
                    return {
                      ...d,
                      status: JobStatus.cancelled,
                    };
                  }
                },
              ),
            onCompleted: () => {
              setJobMenuSelection(undefined);
            },
          });
        }}
        icon="critical"
        onConfirmText="Confirm Cancellation"
        open={jobMenuSelection === JobMenuOption.cancel}
        onClose={() => setJobMenuSelection(undefined)}
      />

      <AddDatesToJob
        open={jobMenuSelection === JobMenuOption.reschedule}
        onClose={() => setJobMenuSelection(undefined)}
        type={
          job.revisitRequired
            ? AddDatesToJobType.revisit
            : AddDatesToJobType.reschedule
        }
      />

      <ModalWrapper
        onClose={() => setJobMenuSelection(undefined)}
        open={jobMenuSelection === JobMenuOption.revisitRequests}
        dialogPanelClassname="w-140"
      >
        <Modal
          title="View revisit requests"
          closeText="Close"
          onClose={() => setJobMenuSelection(undefined)}
        >
          <div className="m-5">
            {job.revisitRequired?.requests.map((r, i) => (
              <Disclosure
                as="div"
                key={i}
                className="p-2 border rounded border-grey-700 flex flex-col mb-2"
              >
                <DisclosureButton className="flex items-center">
                  {({ open }) => (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center space-x-2">
                        <AvatarStack
                          showNameOnOneResult
                          avatars={[
                            {
                              firstName: r.submittedBy.firstName,
                              lastName: r.submittedBy.lastName,
                              avatarSrc: r.submittedBy.avatarSrc,
                            },
                          ]}
                        />
                        <CircleIcon multiplier={2} />
                        <h6 className="text-body-small leading-none ml-2">
                          {format(r.submittedOn, 'do MMMM, yyyy')}
                        </h6>
                      </div>
                      <button>
                        <img
                          className={`${open ? 'rotate-180' : ''} transition-all`}
                          src={ExpandMoreDark}
                          alt="Expand more"
                        />
                      </button>
                    </div>
                  )}
                </DisclosureButton>
                <DisclosurePanel
                  transition
                  className="origin-top translate-y-0 transition-all duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
                >
                  <div className="my-5 flex flex-col space-y-5">
                    <TwoLineText label="Description" text={r.description} />
                    <TwoLineText
                      label="Estimated duration"
                      text={r.estimatedDuration}
                    />
                    <TwoLineText
                      label="Items left on site"
                      text={r.itemsLeftOnSite}
                    />
                    <TwoLineText
                      label="Items required"
                      text={r.itemsRequired}
                    />
                    <TwoLineText label="Reason" text={r.reason} />
                    <TwoLineText
                      label="Special requests"
                      text={r.specialRequests}
                    />
                    <TwoLineText
                      label="Is roofer required?"
                      text={r.isRooferRequired ? 'Yes' : 'No'}
                    />
                    <TwoLineText
                      label="Is electrician required?"
                      text={r.isElectricianRequired ? 'Yes' : 'No'}
                    />
                  </div>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </div>
        </Modal>
      </ModalWrapper>

      <AuditModal
        targetType={TargetType.job}
        targetUuid={job.uuid}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />
    </JobContext.Provider>
  );
};
export default Job;
