import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../atoms/Button';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  FunnelIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { styleUtility } from '../../../utility/styleUtility';
import { addDays, addWeeks, format, subDays, subWeeks } from 'date-fns';
import CalendarDays from '../../molecules/CalendarDays';
import DateSelector from '../../atoms/DateSelector';
import ButtonSelector from '../../atoms/ButtonSelector';
import { useNavigate } from '@tanstack/react-router';
import Drawer from '../../molecules/Drawer';
import { DropdownWithBorder, Option } from '../../atoms/Dropdown';
import { CircleIcon } from '../../icons/Circle';
import {
  AllEnum,
  CalendarPeriod,
  CalendarProvider,
  defaultFilters,
  difficultyOptions,
  jobTypeOptions,
  stateOptions,
  schemeOptions as schemeOptionsBase,
  contractorOptions as contractorOptionsBase,
} from '../../../utility/calendarContext';
import { gql, UserProfile } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';
import { AddJobModal } from '../../molecules/Modals/AddJob';
import OrganisationDropdown from '../../molecules/OrganisationDropdown';

const periodSelect = {
  [CalendarPeriod.week]: {
    sub: subWeeks,
    add: addWeeks,
  },
  [CalendarPeriod.day]: {
    sub: subDays,
    add: addDays,
  },
  [CalendarPeriod.weekdays]: {
    sub: subWeeks,
    add: addWeeks,
  },
};

const GET_SCHEMES = gql(`
  query IndexSchemesForCalendarFilters($filters: IndexSchemesFilterInput) {
    indexSchemes(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
`);

const GET_CONTRACTORS = gql(`
  query IndexContractorsForCalendarFilters($filters: IndexUsersFilterInput, $pagination: PaginationInput) {
    indexUsers(filters: $filters, pagination: $pagination) {
      items {
        uuid
        firstName
        lastName
        profile {
          ... on UserContractorProfile {
            uuid
          }
        }
      }
    }
  }
`);

interface Props {
  period: CalendarPeriod;
  selectedDate: string;
}

const Calendar = ({
  period = CalendarPeriod.weekdays,
  selectedDate,
}: Props): ReactElement => {
  const navigate = useNavigate({ from: '/calendar' });
  const date = useMemo(() => new Date(selectedDate), [selectedDate]);
  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useState(defaultFilters);
  const [cachedFilters, setCachedFilters] = useState(filters);
  const [term, setTerm] = useState<string>();

  const [organisationUuid, setOrgnisationUuid] = useState<string | undefined>(
    localStorage.getItem('__ir_organisation_uuid__') ?? 'all',
  );

  useEffect(() => {
    if (organisationUuid) {
      if (organisationUuid !== 'all') {
        localStorage.setItem('__ir_organisation_uuid__', organisationUuid);
      } else {
        localStorage.removeItem('__ir_organisation_uuid__');
      }
    }
  }, [organisationUuid]);

  const { data } = useQuery(GET_SCHEMES, {
    context: {
      isBatched: true,
    },
  });

  const { data: contractorData } = useQuery(GET_CONTRACTORS, {
    variables: {
      filters: {
        organisationUuid: organisationUuid === 'all' ? undefined : organisationUuid,
        term,
        userProfile: UserProfile.contractor,
      },
      pagination: {
        perPage: 10,
        page: 1,
      },
    },
    context: {
      isBatched: true,
    },
  });

  const schemeOptions = useMemo(
    () => [
      ...schemeOptionsBase,
      ...(data?.indexSchemes.items.map(({ name, uuid }) => ({
        name,
        value: uuid,
      })) ?? []),
    ],
    [data],
  );

  const contractorOptions = useMemo(
    () => [
      ...contractorOptionsBase,
      ...(contractorData?.indexUsers.items.map(
        ({ firstName, lastName, profile }) => ({
          name: `${firstName} ${lastName}`,
          // lazy coding
          value: (profile as { uuid: string }).uuid,
        }),
      ) ?? []),
    ],
    [contractorData],
  );

  const addOrRemove = useCallback(
    (opt: Option<string>, key: keyof typeof filters) => {
      setFilters((ft) => {
        let options = ft[key].filter((opt) => opt.value !== 'all');

        if (opt.value === 'all') {
          options = [opt];
        } else {
          const indexOfOption = options.indexOf(opt);
          if (indexOfOption > -1) {
            options.splice(indexOfOption, 1);
          } else {
            options = [...options, opt];
          }
        }

        return {
          ...ft,
          [key]:
            options.length > 0
              ? options
              : [{ name: 'All', value: AllEnum.all }],
        };
      });
    },
    [],
  );

  const flatFilters = useMemo(
    () =>
      Object.keys(filters).reduce<Array<Option<string>>>((prev, k) => {
        const options = filters[k as keyof typeof filters];
        return [...prev, ...options.filter((k) => k.value !== 'all')];
      }, []),
    [filters],
  );

  const [showAddJobModal, setShowAddJobModal] = useState(false);

  return (
    <>
      <div className="flex items-center p-5">
        <h1 className="text-h1-small font-bold font-nunito mr-5">Calendar</h1>
        <div className="flex space-x-3 items-center flex-grow">
          <div className="w-60">
            <OrganisationDropdown
              all
              organisationUuid={organisationUuid}
              setOrganisationUuid={setOrgnisationUuid}
              hideLabel
              hideLeadIcon
              buttonClassname="!mb-0 bg-white"
            />
          </div>
          <Button
            bText="Today"
            bStyle="light"
            onClick={() =>
              void navigate({
                search: {
                  period,
                  date: new Date().toISOString(),
                },
              })
            }
          />
          <DateSelector
            setSelectedDate={(dt) =>
              void navigate({
                search: {
                  date: dt.toISOString(),
                  period,
                },
              })
            }
            selectedDate={date}
          />
          <Button
            bStyle="clean"
            className="hover:!bg-grey-900/5"
            Icon={
              <ChevronLeftIcon
                stroke={styleUtility.colours['text-normal']}
                className="size-6"
              />
            }
            onClick={() =>
              void navigate({
                search: {
                  date: periodSelect[period].sub(selectedDate, 1).toISOString(),
                  period,
                },
              })
            }
          />
          <Button
            bStyle="clean"
            className="hover:!bg-grey-900/5"
            Icon={
              <ChevronRightIcon
                stroke={styleUtility.colours['text-normal']}
                className="size-6"
              />
            }
            onClick={() =>
              void navigate({
                search: {
                  date: periodSelect[period].add(selectedDate, 1).toISOString(),
                  period,
                },
              })
            }
          />
          <h3 className="text-h3 font-semibold">
            {format(selectedDate, 'MMMM')} 2024
          </h3>
        </div>
        <div className="flex items-center space-x-3">
          <ButtonSelector
            selectedValue={period}
            setSelectedValue={(p) =>
              void navigate({
                search: {
                  period: p,
                  date: selectedDate,
                },
              })
            }
            options={[
              {
                name: 'Week',
                value: CalendarPeriod.week,
              },
              {
                name: '5 Days',
                value: CalendarPeriod.weekdays,
              },
              {
                name: 'Day',
                value: CalendarPeriod.day,
              },
            ]}
          />
          <div className="relative shrink-0">
            <Button
              onClick={() => setShowFilters((ft) => !ft)}
              bStyle="light"
              Icon={<FunnelIcon className="size-6" />}
            />
            {!!flatFilters.length && (
              <CircleIcon
                className="text-primary absolute top-0 right-0 translate-x-1/4 transform -translate-y-1/4"
                multiplier={6}
              />
            )}
          </div>
          <Button
            onClick={() => setShowAddJobModal(true)}
            bText="Add job"
            Icon={<PlusIcon className="size-6" />}
          />
        </div>
      </div>
      <CalendarProvider selectedPeriod={period} filters={cachedFilters}>
        <CalendarDays
          organisationUuid={organisationUuid === 'all' ? undefined : organisationUuid}
          period={period}
          selectedDate={date}
        />
      </CalendarProvider>
      <Drawer setShow={setShowFilters} show={showFilters} title="Filters">
        <div className="w-72 flex flex-col">
          <div className="mb-5 flex gap-3 flex-wrap">
            {Object.keys(filters).map((f) =>
              filters[f as keyof typeof filters]
                .filter((f) => f.value !== 'all')
                .map((opt) => (
                  <button
                    key={opt.value}
                    onClick={() => addOrRemove(opt, f as keyof typeof filters)}
                    className="h-8 px-2 flex items-center bg-grey-900 border border-grey-500 rounded space-x-1.5"
                  >
                    <span className="text-body-small">{opt.name}</span>
                    <XMarkIcon className="size-5" />
                  </button>
                )),
            )}
          </div>
          <DropdownWithBorder
            selected={filters.jobType}
            options={jobTypeOptions}
            onOptionSelect={(opt) => addOrRemove(opt, 'jobType')}
            label="Types"
            buttonClassname="justify-between w-50 mb-5"
            bubble
            buttonText={
              filters.jobType.length > 1
                ? `Types (${filters.jobType.length})`
                : filters.jobType[0].name
            }
          />
          <DropdownWithBorder
            selected={filters.states}
            options={stateOptions}
            onOptionSelect={(opt) => addOrRemove(opt, 'states')}
            label="States"
            buttonClassname="justify-between w-50 mb-5"
            bubble
            buttonText={
              filters.states.length > 1
                ? `States (${filters.states.length})`
                : filters.states[0].name
            }
          />
          <DropdownWithBorder
            selected={filters.difficulty}
            options={difficultyOptions}
            onOptionSelect={(opt) => addOrRemove(opt, 'difficulty')}
            label="Difficulty"
            buttonClassname="justify-between w-50 mb-5"
            bubble
            buttonText={
              filters.states.length > 1
                ? `Difficulty (${filters.states.length})`
                : filters.states[0].name
            }
          />
          <DropdownWithBorder
            selected={filters.schemes}
            options={schemeOptions}
            onOptionSelect={(opt) => addOrRemove(opt, 'schemes')}
            label="Schemes"
            buttonClassname="justify-between w-50 mb-5"
            bubble
            buttonText={
              filters.schemes.length > 1
                ? `Schemes (${filters.schemes.length})`
                : filters.schemes[0].name
            }
          />
          <DropdownWithBorder
            selected={filters.contractors}
            options={contractorOptions}
            onOptionSelect={(opt) => addOrRemove(opt, 'contractors')}
            label="Contractors"
            buttonClassname="justify-between w-50 mb-5"
            bubble
            handlesSearch
            term={term}
            setTerm={setTerm}
            buttonText={
              filters.contractors.length > 1
                ? `Contractors (${filters.contractors.length})`
                : filters.contractors[0].name
            }
          />
        </div>
        <div className="flex flex-col space-y-3">
          <Button
            bText="Apply filters"
            className="w-full justify-center"
            onClick={() => {
              setShowFilters(false);
              setCachedFilters(filters);
            }}
          />
          <Button
            disabled={flatFilters.length === 0}
            bText="Clear all"
            className="w-full justify-center"
            bStyle="clean"
            onClick={() => {
              setFilters(defaultFilters);
              setShowFilters(false);
            }}
          />
        </div>
      </Drawer>


      <AddJobModal
        open={showAddJobModal}
        onClose={() => setShowAddJobModal(false)}
        date={new Date()}
      />

    </>
  );
};
export default (props: Props) => (
  <SuspendedComponent>
    <Calendar {...props} />
  </SuspendedComponent>
);
