import { ReactElement, useState } from 'react';
import logoSrc from './../../../assets/images/logo.svg';
import { useForm } from '@tanstack/react-form';
import { Input, NumberInput, PhoneNumberInput } from '../../atoms/Input';
import NumberInputControlled from '../../atoms/NumberInput';
import { Button } from '../../atoms/Button';
import { AvailableDate, gql, VerifyPartnerTokenQuery } from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import Alert from '../../atoms/Alerts';
import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { addressUtility } from '../../../utility/address';
import DateSelector from '../../atoms/DateSelector';
import { DropdownWithBorder } from '../../atoms/Dropdown';
import { format, isSameDay } from 'date-fns';
import { notify } from '../../../utility/notify';

interface Props {
  verifiedPartner: VerifyPartnerTokenQuery['verifyPartnerToken'];
  token: string;
}

const SearchAvailableDatesQuery = gql(`
  query SearchForAvailableDatesOnExternalBooking ($input: SearchDatesForBookingFromVerifiedPartner!) {
    searchDatesForRebookFromVerifiedPartner (input: $input) {
      slotDate
      displayDate
      slotUuids
    }
  }  
`);

const CreateJob = gql(`
  mutation createJobFromVerifiedPartnerToken ($input: CreateJobFromVerifiedPartnerTokenInput!) {
    createJobFromVerifiedPartnerToken (input: $input) {
      uuid
    }
  }
`);

const jobDefaultValues = {
  selectedDate: undefined,
  targetInstallationDate: new Date(),
  panelQuantity: '',
  batteryQuantity: '',
  notes: '',
};

const customerDefaultValues = {
  customerEmail: '',
  customerReference: '',
  customerFirstName: '',
  customerLastName: '',
  customerPhoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  county: '',
  postcode: '',
};

const ExternalBooking = ({ verifiedPartner, token }: Props): ReactElement => {
  const [stage, setStage] = useState<'customer' | 'job'>('customer');

  const [fetchingPostcode, setFetchingPostcode] = useState(false);

  const [targetDate, setTargetDate] = useState(new Date());
  const [numberOfRequiredDays, setNumberOfRequiredDays] = useState(1);

  const [create, { data, error, loading: creatingJobLoading }] =
    useMutation(CreateJob);

  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lon: number;
  }>();

  const [availableDates, setAvailableDates] = useState<AvailableDate[]>([]);

  const { error: availableDatesError, loading: datesLoading } = useQuery(
    SearchAvailableDatesQuery,
    {
      variables: {
        input: {
          numberOfRequiredDays,
          dateToBeginSearch: targetDate,
          partnerUuid: verifiedPartner.uuid,
          token,
        },
      },
      skip: stage === 'customer',
      onCompleted: (data) => {
        setAvailableDates(data.searchDatesForRebookFromVerifiedPartner);
        jobForm.setFieldValue('selectedDate', undefined);
      },
    },
  );

  const loading = datesLoading || creatingJobLoading;

  const customerForm = useForm({
    onSubmit: () => {
      setStage('job');
    },
    defaultValues: customerDefaultValues,
  });

  const jobForm = useForm<
    Omit<typeof jobDefaultValues, 'selectedDate'> & { selectedDate?: Date }
  >({
    onSubmit: ({ value }) => {
      const selectedDate = value.selectedDate;
      if (!selectedDate) return;
      const selectedSlots = availableDates.find(({ slotDate }) =>
        isSameDay(slotDate, selectedDate),
      );

      if (!selectedSlots) {
        notify.error(`No slots available.`);
        return;
      }

      if (!coordinates) {
        notify.error('Invalid coordinates');
        setStage('customer');
        return;
      }

      const values = {
        ...value,
        ...customerForm.state.values,
      };

      void create({
        variables: {
          input: {
            token,
            slotUuids: selectedSlots.slotUuids,
            duration: 8,
            partnerUuid: verifiedPartner.uuid,
            targetDate: selectedDate,
            panelQuantity: parseInt(values.panelQuantity),
            batteryQuantity: parseInt(values.batteryQuantity),
            notes: values.notes,
            customerEmail: values.customerEmail,
            customerReference: values.customerReference,
            customerFirstName: values.customerFirstName,
            customerLastName: values.customerLastName,
            customerPhoneNumber: values.customerPhoneNumber,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            county: values.county,
            postcode: values.postcode,
            latitude: coordinates.lat,
            longitude: coordinates.lon,
          },
        },
      });

      console.log({ values });
    },
    defaultValues: jobDefaultValues,
  });

  return (
    <div className="h-full justify-center flex overflow-hidden">
      <div className="max-w-160 w-full bg-white h-full py-10 px-10 overflow-scroll">
        <div className="flex justify-center">
          <img src={logoSrc} alt="Infinity Logo" className="mb-5 h-14" />
        </div>
        {data?.createJobFromVerifiedPartnerToken ? (
          <div className="flex mt-20 flex-col items-center justify-center space-y-5">
            <div className="h-20 w-20 flex items-center bg-primary justify-center rounded-full">
              <CheckCircleIcon className="text-white size-11" />
            </div>
            <h2 className="text-h2 font-bold font-nunito">
              Successfully created job
            </h2>
            <p className='text-center'>
              Thanks, the reference for your job is{' '}
              <span className="italic font-bold">
                {data.createJobFromVerifiedPartnerToken.uuid}
              </span>
              . You can close this page now.
            </p>
          </div>
        ) : (
          <>
            <h1 className="text-h1-small mx-auto font-bold font-nunito mb-2">
              {verifiedPartner.name} Booking Form
            </h1>
            <p className="mb-5 text-text-low-priority">
              Please fill in the details to book in an installation with us.
            </p>
            {stage === 'customer' && (
              <form
                className="w-full border p-5 flex flex-col rounded border-grey-700"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void customerForm.handleSubmit();
                }}
              >
                <h3 className="font-nunito text-h3 mb-2 font-bold">
                  Customer Details
                </h3>

                <customerForm.Field
                  name="customerReference"
                  children={({ state, handleChange }) => (
                    <Input
                      required
                      label="Reference"
                      className="w-40"
                      onChange={(e) => handleChange(e.target.value)}
                      value={state.value}
                    />
                  )}
                />

                <customerForm.Field
                  name="customerEmail"
                  children={({ state, handleChange }) => (
                    <Input
                      required
                      className="w-full"
                      label="Email"
                      type="email"
                      onChange={(e) => handleChange(e.target.value)}
                      value={state.value}
                    />
                  )}
                />

                <div className="flex space-x-5 w-full">
                  <customerForm.Field
                    name="customerFirstName"
                    children={({ state, handleChange }) => (
                      <Input
                        required
                        className="w-full"
                        label="First name"
                        onChange={(e) => handleChange(e.target.value)}
                        value={state.value}
                      />
                    )}
                  />
                  <customerForm.Field
                    name="customerLastName"
                    children={({ state, handleChange }) => (
                      <Input
                        required
                        className="w-full"
                        label="Last name"
                        onChange={(e) => handleChange(e.target.value)}
                        value={state.value}
                      />
                    )}
                  />
                </div>

                <customerForm.Field
                  name="customerPhoneNumber"
                  children={({ state, handleChange }) => (
                    <PhoneNumberInput
                      label="Phone number"
                      required
                      value={state.value}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  )}
                />

                <div className="flex space-x-5 w-full">
                  <customerForm.Field
                    name="addressLine1"
                    children={({ state, handleChange }) => (
                      <Input
                        required
                        value={state.value}
                        onChange={(e) => handleChange(e.target.value)}
                        label="Address line one"
                        error={state.meta.errors.join(', ')}
                        className="w-full"
                      />
                    )}
                  />
                </div>

                <div className="flex space-x-5 w-full">
                  <customerForm.Field
                    name="city"
                    children={({ state, handleChange }) => (
                      <Input
                        value={state.value}
                        onChange={(e) => handleChange(e.target.value)}
                        label="City (optional)"
                        error={state.meta.errors.join(', ')}
                      />
                    )}
                  />

                  <customerForm.Field
                    name="county"
                    children={({ state, handleChange }) => (
                      <Input
                        value={state.value}
                        onChange={(e) => handleChange(e.target.value)}
                        label="County (optional)"
                        error={state.meta.errors.join(', ')}
                      />
                    )}
                  />

                  <customerForm.Field
                    name="postcode"
                    validators={{
                      onSubmitAsync: async ({ value }) => {
                        if (coordinates) return undefined;
                        if (!value.length) return 'Please add a valid postcode';
                        setFetchingPostcode(true);
                        const rsp = await addressUtility.getLatLon(value);
                        setFetchingPostcode(false);
                        if (!rsp) return 'Invalid postcode.';
                        setCoordinates(rsp);
                        return undefined;
                      },
                    }}
                    children={({ state, handleChange, handleBlur }) => (
                      <Input
                        value={state.value}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (coordinates) setCoordinates(undefined);
                          handleChange(e.target.value);
                        }}
                        label="Postcode"
                        error={state.meta.errors.join(', ')}
                        loading={fetchingPostcode}
                        success={coordinates ? 'Saved.' : undefined}
                      />
                    )}
                  />
                </div>
                <div className="flex justify-end space-x-5 w-full">
                  <Button bText="Next step" type="submit" loading={loading} />
                </div>
              </form>
            )}

            {stage === 'job' && (
              <form
                className="w-full border p-5 flex flex-col rounded border-grey-700"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void jobForm.handleSubmit();
                }}
              >
                <h3 className="font-nunito text-h3 mb-2 font-bold">
                  Job Details
                </h3>

                <div className="flex space-x-5 w-full">
                  <jobForm.Field
                    name="panelQuantity"
                    children={({ state, handleChange }) => (
                      <NumberInput
                        label="How many panels?"
                        value={state.value}
                        required
                        className="w-full"
                        onChange={(e) => handleChange(e.target.value)}
                      />
                    )}
                  />
                  <jobForm.Field
                    name="batteryQuantity"
                    children={({ state, handleChange }) => (
                      <NumberInput
                        label="How many batteries?"
                        required
                        value={state.value}
                        className="w-full"
                        onChange={(e) => handleChange(e.target.value)}
                      />
                    )}
                  />
                </div>
                <jobForm.Field
                  name="notes"
                  children={({ state, handleChange }) => (
                    <Input
                      type="textarea"
                      label="Notes (optional)"
                      value={state.value}
                      className="w-full"
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  )}
                />

                <div className="mb-5">
                  <NumberInputControlled
                    max={5}
                    count={numberOfRequiredDays}
                    setCount={setNumberOfRequiredDays}
                    title="Number of days required"
                  />
                </div>

                <div className="mb-5">
                  <span className="mb-2 text-input-label block font-semibold">
                    Target date
                  </span>
                  <DateSelector
                    selectedDate={targetDate}
                    setSelectedDate={(date) => setTargetDate(date)}
                    showLabel
                  />
                  {availableDatesError ? (
                    <div className="mt-2">
                      <Alert
                        alertType="error"
                        text={availableDatesError.message}
                      />
                    </div>
                  ) : (
                    availableDates.length === 0 && (
                      <div className="my-2">
                        <Alert
                          alertType="warning"
                          text="No dates found with this target date, try choosing a different date."
                        />
                      </div>
                    )
                  )}
                </div>

                <jobForm.Field
                  name="selectedDate"
                  validators={{
                    onSubmit: ({ value }) =>
                      !value ? 'Please select a date' : undefined,
                  }}
                  children={({ state, handleChange }) => (
                    <DropdownWithBorder
                      error={state.meta.errors.join(', ')}
                      buttonClassname="w-full justify-between mb-5"
                      label="Selected date"
                      buttonText={
                        state.value
                          ? format(state.value, 'd MMMM yyyy')
                          : '-- Select --'
                      }
                      disabled={availableDates.length === 0}
                      options={availableDates.map(
                        ({ slotDate, displayDate }) => ({
                          name: displayDate,
                          value: slotDate,
                        }),
                      )}
                      respectButtonWidth
                      onOptionSelect={(option) =>
                        handleChange(new Date(option.value))
                      }
                    />
                  )}
                />
                <div className="flex justify-between space-x-5 w-full">
                  <Button
                    bStyle="outline"
                    bText="Back"
                    Icon={<ArrowLeftIcon className="size-6" />}
                    onClick={() => setStage('customer')}
                  />
                  <Button bText="Submit" type="submit" loading={loading} />
                </div>
              </form>
            )}

            {error && (
              <div className="my-5">
                <Alert alertType="error" text={error.message} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default ExternalBooking;
