import { ReactElement, useEffect } from 'react';
import { gql } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import {
  TableColumns,
  TableContainer,
  TableRows,
  useTableContext,
} from '../../../../../molecules/Table';
import AvatarStack from '../../../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import Tag from '../../../../../atoms/Tag';
import { jobsUtility } from '../../../../../../utility/jobs';
import { Button } from '../../../../../atoms/Button';
import { ContextualTablePagination } from '../../../../../molecules/Pagination';
import CircularProgress from '../../../../../atoms/CircularProgress';
import { SuspendedComponent } from '../../../../../atoms/SuspendedComponent';

const INDEX_FOCUS_JOBS = gql(`
  query IndexFocusJobsForSection ($pagination: PaginationInput, $myAssignment: Boolean!) {
    focusJobs (pagination: $pagination, myAssignment: $myAssignment) {
      items {
        uuid 
        status 
        totalActionCount
        completedActionCount
        customer {
          uuid
          userUuid
          firstName
          lastName
          avatarSrc
        }
        address {
          uuid
          postcode
        }
      }
      pagination {
        lastPage
      }
    }
  }  
`);

interface Props {
  myAssignment: boolean;
}

const FocusJobs = ({ myAssignment }: Props): ReactElement => {
  const { page, setTotalPages } = useTableContext();
  const { data } = useSuspenseQuery(INDEX_FOCUS_JOBS, {
    variables: {
      pagination: {
        perPage: 15,
        page,
      },
      myAssignment,
    },
  });

  useEffect(() => {
    setTotalPages(data.focusJobs.pagination.lastPage);
  }, [data, setTotalPages]);

  return (
    <TableRows
      widthType="pc"
      rows={data.focusJobs.items.map((j) => ({
        uuid: j.uuid,
        cells: [
          {
            content: (
              <Link
                className="flex items-center"
                to="/contacts/$uuid"
                params={{ uuid: j.customer.userUuid }}
              >
                <AvatarStack
                  width="w-9"
                  height="h-9"
                  avatars={[
                    {
                      firstName: j.customer.firstName,
                      lastName: j.customer.lastName,
                      avatarSrc: j.customer.avatarSrc,
                    },
                  ]}
                />
                <span className="text-sm underline ml-2">
                  {j.customer.firstName} {j.customer.lastName}
                </span>
              </Link>
            ),
            width: 30,
          },
          {
            content: (
              <div className="flex items-center space-x-2">
                <CircularProgress
                  size={18}
                  completed={j.completedActionCount}
                  total={j.totalActionCount}
                  hideText
                  strokeWidth={4}
                />
                <span className="text-sm">
                  {j.completedActionCount}/{j.totalActionCount} actions
                  completed
                </span>
              </div>
            ),
            width: 35,
          },
          {
            content: (
              <Tag
                colour={j.status}
                text={jobsUtility.jobStatusNiceMap[j.status]}
              />
            ),
            width: 20,
          },
          {
            content: (
              <div className="flex justify-end w-full">
                <Button
                  bStyle="light"
                  className="h-9"
                  bText="View"
                  href={`/job/${j.uuid}`}
                />
              </div>
            ),
            width: 15,
          },
        ],
      }))}
    />
  );
};

export default (props: Props) => (
  <TableContainer>
    <TableColumns
      widthType="pc"
      columns={[
        {
          heading: 'customer',
          width: 30,
        },
        {
          heading: 'progress',
          width: 35,
        },
        {
          heading: 'state',
          width: 20,
        },
        {
          width: 15,
        },
      ]}
    />
    <SuspendedComponent>
      <FocusJobs {...props} />
    </SuspendedComponent>
    <ContextualTablePagination />
  </TableContainer>
);
