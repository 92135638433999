import {
  gql,
  IndexJobActionsQuery,
  JobActionType,
  JobDifficulty,
  JobStatus,
  JobType,
  RoofTile,
  ScaffoldingLocation,
  ScaffoldingLocationArea,
  ScaffoldingStatus,
} from '@monorepo/graphql';
import { Option } from '../components/atoms/Dropdown';
import { ReactNode } from 'react';
import ToolBoxSmall from '../assets/icons/ToolBoxSmall.svg';
import {
  Battery0Icon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';

import Installation from '../assets/icons/Installation.svg';
import Remedial from '../assets/icons/Remedial.svg';

const jobActionNiceMap: (props: {
  action: IndexJobActionsQuery['indexJobActions'][0];
}) => string = ({ action }) => {
  const actionTypes: Record<JobActionType, string> = {
    [JobActionType.customerSignature]: 'Received customer signature',
    [JobActionType.dnoG98Recevied]: 'DNO G98 Completed',
    [JobActionType.dnoG99Received]: 'DNO G99 Completed',
    [JobActionType.feedbackReceived]: 'Feedback received from customer',
    [JobActionType.invoicePaid]: 'Customer paid invoice',
    [JobActionType.postAuditGeneral]: `General audit ${action.failedAt ? 'failed' : 'completed'}`,
    [JobActionType.postAuditTechnical]: `Technical audit ${action.failedAt ? 'failed' : 'completed'}`,
    [JobActionType.preAudit]: `Pre audit ${action.failedAt ? 'failed' : 'completed'}`,
    [JobActionType.scaffoldingBooked]: 'Scaffolding booked',
    [JobActionType.scaffoldingUp]: 'Scaffolding up',
    [JobActionType.scaffoldingDown]: 'Scaffolding down',
  };

  return actionTypes[action.type];
};

const jobStatusNiceMap: Record<JobStatus, string> = {
  [JobStatus.ready]: 'Ready',
  [JobStatus.booked]: 'Booked',
  [JobStatus.unsigned]: 'Unsigned',
  [JobStatus.installed]: 'Installed',
  [JobStatus.partiallyInstalled]: 'Partially installed',
  [JobStatus.closed]: 'Closed',
  [JobStatus.completed]: 'Completed',
  [JobStatus.onHold]: 'On hold',
  [JobStatus.cancelled]: 'Cancelled',
};

const jobScaffoldingStatusNiceMap: Record<ScaffoldingStatus, string> = {
  [ScaffoldingStatus.required]: 'Required',
  [ScaffoldingStatus.notRequired]: 'Not required',
  [ScaffoldingStatus.booked]: 'Booked',
  [ScaffoldingStatus.erected]: 'Erected',
  [ScaffoldingStatus.struck]: 'Struck',
};

const roofTileNiceMap: Record<RoofTile, string> = {
  [RoofTile.flat]: 'Flat',
  [RoofTile.concrete]: 'Concrete',
  [RoofTile.concreteFlat]: 'Concrete flat tile',
  [RoofTile.flatPlain]: 'Flat plain',
  [RoofTile.metal]: 'Metal',
  [RoofTile.slate]: 'Slate',
  [RoofTile.unspecified]: 'Unknown',
};

const slotIconComponentMap = {
  installation: (
    <img alt="installation" className="size-5" src={Installation} />
  ),
  remedial: <img alt="remedial" className="size-5" src={Remedial} />,
  battery: <Battery0Icon className="size-5 text-grey-500" />,
};

const scaffoldingLocationNiceMap: Record<ScaffoldingLocation, string> = {
  [ScaffoldingLocation.garage]: 'Garage',
  [ScaffoldingLocation.mainProperty]: 'Main Property',
  [ScaffoldingLocation.other]: 'Other',
  [ScaffoldingLocation.outbuilding]: 'Outbuilding',
};

const scaffoldingLocationAreaNiceMap: Record<ScaffoldingLocationArea, string> =
  {
    [ScaffoldingLocationArea.back]: 'Back',
    [ScaffoldingLocationArea.front]: 'Front',
    [ScaffoldingLocationArea.leftSide]: 'Left side',
    [ScaffoldingLocationArea.other]: 'Other',
    [ScaffoldingLocationArea.rightSide]: 'Right side',
  };

const statusOptions: Array<Option<JobStatus>> = Object.keys(JobStatus)
  .sort((a, b) =>
    Object.keys(jobStatusNiceMap).indexOf(a) >
    Object.keys(jobStatusNiceMap).indexOf(b)
      ? 1
      : -1,
  )
  .map((s) => ({
    name: jobStatusNiceMap[s as keyof typeof JobStatus],
    value: s as JobStatus,
  }));

const jobTypeIconMap: Record<JobType, ReactNode> = {
  [JobType.installation]: <img src={ToolBoxSmall} alt="Toolbox" />,
  [JobType.remedial]: (
    <WrenchScrewdriverIcon className="size-5 text-grey-400" />
  ),
  [JobType.battery]: <Battery0Icon className="size-5 text-grey-400" />,
};

const difficultyStatusOptions: Array<Option<JobDifficulty>> = [
  {
    name: 'Easy',
    value: JobDifficulty.easy,
  },
  {
    name: 'Normal',
    value: JobDifficulty.normal,
  },
  {
    name: 'Difficult',
    value: JobDifficulty.difficult,
  },
];

const JOB_AUDIT_FRAGMENT = gql(`
  fragment JobAuditFragment on JobAudit {
    uuid
    notes
    hasPassed
    auditType
    submittedAt
    auditor {
      uuid
      firstName
      lastName
      avatarSrc
    }
    meta {
      metaKey
      metaValue
    }
  }
`);

const JOB_FRAGMENT = gql(`
  fragment JobFragment on Job {
    uuid
    difficulty
    schemeName
    type
    scaffoldingStatus
    status
    displayDate
    displayLink
    operatorUuid
    solarPanelCount
    revisedSolarPanelCount
    duration
    targetDate
    daysRequired
    isRooferRequired
    isElectricianRequired
    dnoUuid
    isSigned
    organisationName
    activeSlots {
      uuid
      date
    }
    partner {
      uuid
      name
    }
    customer {
      uuid
      userUuid
      email
      firstName
      lastName
      phoneNumber
      source
    }
    address {
      uuid
      line1
      line2
      city
      postcode
    }
    survey {
      uuid
      formSubmission {
        uuid
        reference
        panelQuantity
        batteryQuantity
        createdAt
      }
    }
    revisitRequired {
      uuid
      partiallInstalledOn
      status
      requests {
        uuid
        submittedOn
        submittedBy { 
          firstName
          lastName
          avatarSrc
        }
        description
        itemsLeftOnSite
        itemsRequired
        reason
        specialRequests
        estimatedDuration
        isRooferRequired
        isElectricianRequired
      }
    }
  }
`);

export const jobsUtility = {
  jobScaffoldingStatusNiceMap,
  jobStatusNiceMap,
  statusOptions,
  difficultyStatusOptions,
  scaffoldingLocationAreaNiceMap,
  scaffoldingLocationNiceMap,
  roofTileNiceMap,
  jobTypeIconMap,
  slotIconComponentMap,
  jobActionNiceMap,
  queries: {
    JOB_FRAGMENT,
    JOB_AUDIT_FRAGMENT,
  },
};
