import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Modal, ModalWrapper } from '../Base';
import {
  Exact,
  gql,
  IndexJobContractorsQuery,
  IndexPotentialContractorsForJobQuery,
  IndexPotentialTeamsForJobsQuery,
  JobType,
} from '@monorepo/graphql';
import {
  QueryRef,
  useMutation,
  useReadQuery,
  useSuspenseQuery,
} from '@apollo/client';
import { Input } from '../../../atoms/Input';
import {
  ArrowRightIcon,
  CalendarIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { useDebounce } from 'use-debounce';
import AvatarStack from '../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { Button } from '../../../atoms/Button';
import TwoLineText from '../../../atoms/TwoLineText';
import { useJobContext } from '../../../organisms/Job';
import EmptyState from '../../EmptyState';
import AssignedContractor from './AssignedContractor';
import Alert from '../../../atoms/Alerts';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import { notify } from '../../../../utility/notify';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

const INDEX_POTENTIAL_CONTRACTORS_FOR_JOB = gql(`
  query IndexPotentialContractorsForJob ($filters: IndexPotentialContractorsForJobFilter!) {
    indexPotentialContractorsForJob (filters: $filters) {
      userUuid
      contractorProfileUuid
      firstName
      lastName
      avatarSrc
      isElectrician
      isRoofer
      isTrainee
      isPrimary
      distance
    }
  }
`);

const INDEX_POTENTIAL_TEAMS_FOR_JOB = gql(`
  query IndexPotentialTeamsForJobs ($filters: IndexPotentialContractorsForJobFilter!) {
    indexPotentialTeamsForJob (filters: $filters) {
      uuid
      name
      distance
      contractors {
        userUuid
        contractorProfileUuid
        firstName
        lastName
        avatarSrc
        isElectrician
        isRoofer
        isTrainee
        distance
      }
    }
  }
`);

const PotentialContractorsInner = ({
  isElectrician,
  isRoofer,
  term,
  assignedContractors,
  setAssignedContractors,
  date,
}: {
  isElectrician: boolean;
  isRoofer: boolean;
  term: string;
  assignedContractors: AssignedContractors;
  date: Date;
  setAssignedContractors: Dispatch<SetStateAction<AssignedContractors>>;
}): ReactElement => {
  const { job } = useJobContext();

  const [potentialContractors, setPotentialContractors] =
    useState<PotentialContractors>([]);

  const { data } = useSuspenseQuery(INDEX_POTENTIAL_CONTRACTORS_FOR_JOB, {
    variables: {
      filters: {
        jobUuid: job.uuid,
        date,
        isElectrician,
        isRoofer,
        term,
      },
    },
  });

  useEffect(() => {
    const contractors = data.indexPotentialContractorsForJob;
    setPotentialContractors(
      contractors
        .filter(
          (c) =>
            !assignedContractors.some(
              (a) => a.contractorProfileUuid === c.contractorProfileUuid,
            ),
        )
        .sort((a, b) => {
          if (a.distance > b.distance) return 1;
          if (a.distance === b.distance) return 0;
          return -1;
        }),
    );
  }, [data, assignedContractors]);

  return potentialContractors.length ? (
    <>
      {potentialContractors.map((u) => (
        <div
          key={u.userUuid}
          className="border border-grey-400/40 shrink-0 w-full rounded-md overflow-hidden"
        >
          <div className="p-2 flex items-center">
            <AvatarStack
              height="h-9"
              width="w-9"
              avatars={[
                {
                  firstName: u.firstName,
                  lastName: u.lastName,
                  avatarSrc: u.avatarSrc ?? undefined,
                },
              ]}
            />
            <div className="space-y-2.5 px-5 flex-grow">
              <Link
                to="/contacts/$uuid"
                params={{
                  uuid: u.userUuid,
                }}
              >
                <p className="font-semibold underline">
                  {u.firstName} {u.lastName}
                </p>
              </Link>
              <span className="text-body-small text-text-low-priority">
                Trade:{' '}
                {[
                  u.isElectrician ? 'Electrician' : undefined,
                  u.isRoofer ? 'Roofer' : undefined,
                ]
                  .filter((s) => s)
                  .join(', ')}
              </span>
            </div>
          </div>
          <div className="p-2 bg-background-secondary flex items-center">
            <div className="flex items-center flex-grow">
              <MapPinIcon className="size-5 text-grey-400 mr-1" />
              <span className="text-body-small">
                {u.distance.toLocaleString('en-GB', {
                  maximumFractionDigits: 2,
                })}{' '}
                miles
              </span>
            </div>
            <Button
              className="justify-center"
              reverse
              onClick={() => {
                setAssignedContractors((as) => [
                  {
                    uuid: u.contractorProfileUuid,
                    contractorProfileUuid: u.contractorProfileUuid,
                    isElectrician: u.isElectrician,
                    isRoofer: u.isRoofer,
                    isTrainee: u.isTrainee,
                    isPrimary: false,
                    user: {
                      uuid: u.userUuid,
                      firstName: u.firstName,
                      lastName: u.lastName,
                      avatarSrc: u.avatarSrc,
                    },
                    dates: job.activeSlots.map(s => new Date(s.date)),
                    distance: u.distance,
                    displayDateRequired: '-'
                  },
                  ...as,
                ]);
                setPotentialContractors((pc) =>
                  pc.filter(
                    ({ contractorProfileUuid }) =>
                      contractorProfileUuid !== u.contractorProfileUuid,
                  ),
                );
              }}
              bText="Assign"
              Icon={<ArrowRightIcon className="size-5" />}
            />
          </div>
        </div>
      ))}
    </>
  ) : (
    <EmptyState
      title="No contractors available"
      description="Uh oh, no contractors are available for this job."
    />
  );
};

const PotentialTeamsInner = ({
  isElectrician,
  isRoofer,
  term,
  assignedContractors,
  setAssignedContractors,
  date,
}: {
  isElectrician: boolean;
  isRoofer: boolean;
  term: string;
  assignedContractors: AssignedContractors;
  date: Date;
  setAssignedContractors: Dispatch<SetStateAction<AssignedContractors>>;
}): ReactElement => {
  const { job } = useJobContext();

  const [potentialTeams, setPotentialTeams] = useState<PotentialTeams>([]);

  const { data } = useSuspenseQuery(INDEX_POTENTIAL_TEAMS_FOR_JOB, {
    variables: {
      filters: {
        jobUuid: job.uuid,
        date,
        isElectrician,
        isRoofer,
        term,
      },
    },
  });

  useEffect(() => {
    const teams = data.indexPotentialTeamsForJob;
    setPotentialTeams(
      teams
        .filter(
          (c) =>
            !assignedContractors.some((a) =>
              c.contractors.some(
                ({ contractorProfileUuid }) =>
                  contractorProfileUuid === a.contractorProfileUuid,
              ),
            ),
        )
        .sort((a, b) => {
          if (a.distance > b.distance) return 1;
          if (a.distance === b.distance) return 0;
          return -1;
        }),
    );
  }, [data, assignedContractors]);

  return potentialTeams.length ? (
    <>
      {potentialTeams.map((t) => (
        <div
          key={t.uuid}
          className="border border-grey-400/40 shrink-0 w-full rounded-md overflow-hidden"
        >
          <div className="p-2 flex items-center">
            <AvatarStack
              height="h-9"
              width="w-9"
              avatars={t.contractors.map(
                ({ firstName, lastName, avatarSrc }) => ({
                  firstName,
                  lastName,
                  avatarSrc,
                }),
              )}
            />
            <div className="space-y-2.5 px-5 flex-grow">
              <p className="font-semibold">{t.name}</p>
              <span className="text-body-small text-text-low-priority">
                Members: {t.contractors.length}
              </span>
            </div>
          </div>
          <div className="p-2 bg-background-secondary flex items-center">
            <div className="flex items-center flex-grow">
              <MapPinIcon className="size-5 text-grey-400 mr-1" />
              <span className="text-body-small">
                {t.distance.toLocaleString('en-GB', {
                  maximumFractionDigits: 2,
                })}{' '}
                miles
              </span>
            </div>
            <Button
              className="justify-center"
              reverse
              onClick={() => {
                setAssignedContractors(
                  t.contractors.map((u) => ({
                    uuid: u.contractorProfileUuid,
                    contractorProfileUuid: u.contractorProfileUuid,
                    isElectrician: u.isElectrician,
                    isRoofer: u.isRoofer,
                    isPrimary: true,
                    isTrainee: u.isTrainee,
                    user: {
                      uuid: u.userUuid,
                      firstName: u.firstName,
                      lastName: u.lastName,
                      avatarSrc: u.avatarSrc,
                    },
                    dates: job.activeSlots.map(s => new Date(s.date)),
                    distance: u.distance,
                    displayDateRequired: '-',
                  })),
                );
                setPotentialTeams((teams) =>
                  teams.filter(
                    (c) =>
                      !assignedContractors.some((a) =>
                        c.contractors.some(
                          ({ contractorProfileUuid }) =>
                            contractorProfileUuid === a.contractorProfileUuid,
                        ),
                      ),
                  ),
                );
              }}
              bText="Assign"
              Icon={<ArrowRightIcon className="size-5" />}
            />
          </div>
        </div>
      ))}
    </>
  ) : (
    <EmptyState
      title="No contractors available"
      description="Uh oh, no contractors are available for this job."
    />
  );
};

const ATTACH_JOB_CONTRACTORS = gql(`
  mutation AttachJobContractors ($input: AttachSlotContractorsInput!) {
    attachSlotContractors (input: $input) 
  }  
`);

interface Props {
  open: boolean;
  onClose: (success: boolean, contractors?: AssignedContractors) => void;
  queryRef: QueryRef<
    IndexJobContractorsQuery,
    Exact<{
      uuid: string;
    }>
  >;
}

const AssignmentModal = ({ open, onClose, queryRef }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AssignmentModalChild queryRef={queryRef} onClose={onClose} />
  </ModalWrapper>
);

type AssignedContractors = Array<Omit<IndexJobContractorsQuery['indexContractorsForJob'][0], 'slots'> & { dates: Date[] }>;
type PotentialContractors =
  IndexPotentialContractorsForJobQuery['indexPotentialContractorsForJob'];
type PotentialTeams =
  IndexPotentialTeamsForJobsQuery['indexPotentialTeamsForJob'];

const AssignmentModalChild = ({
  onClose,
  queryRef,
}: Omit<Props, 'open'>): ReactElement => {
  const [isElectrician, setIsElectrician] = useState(true);
  const [isRoofer, setIsRoofer] = useState(true);

  const [search, setSearch] = useState('');
  const { job } = useJobContext();

  if (!job.targetDate) throw new Error('Invalid target date');

  const [attach, { loading: attachLoading }] = useMutation(
    ATTACH_JOB_CONTRACTORS,
    {
      onError: (err) =>
        notify.error(`Unable to attach contractors to job \n ${err.message}`),
      onCompleted: () => {
        notify.success('Saved contractors.');
        onClose(true, assignedContractors);
      },
    },
  );

  const [searchDebounced] = useDebounce(search, 500);

  const contractors = useReadQuery(queryRef);

  useEffect(() => {
    setAssignedContractors(contractors.data.indexContractorsForJob.map(c => ({
      ...c,
      dates: c.slots.map(s => new Date(s.date))
    })));
  }, [contractors]);

  const [assignedContractors, setAssignedContractors] =
    useState<AssignedContractors>([]);

  const isInstallation = job.type === JobType.installation;

  const [error, setError] = useState<string>();

  return (
    <Modal
      scroll={false}
      title="Assign Contractors"
      onClose={onClose}
      confirmText="Save"
      loading={attachLoading}
      confirmCallback={() => {

        
        const hasPrimaryElectrician =
          assignedContractors.some(
            ({ isPrimary, isElectrician }) => isPrimary && isElectrician,
          ) || !job.isElectricianRequired;

        const hasPrimaryRoofer =
          assignedContractors.some(
            ({ isPrimary, isRoofer }) => isPrimary && isRoofer,
          ) || !job.isRooferRequired;

        if (job.type === JobType.installation) {
          if (!hasPrimaryElectrician || !hasPrimaryRoofer) {
            let error = 'Please select a primary';
            if (!hasPrimaryElectrician) {
              error = `${error} electrician`;
            }
            if (!hasPrimaryRoofer) {
              error = `${error} ${!hasPrimaryElectrician ? 'and' : ''} roofer`;
            }
            setError(`${error}.`);
            return;
          }
        }

        if (job.type === JobType.remedial) {
          let error = '';
          if (!hasPrimaryElectrician && job.isElectricianRequired) {
            error = `Please select a primary electrician`;
          }
          if (!hasPrimaryRoofer && job.isRooferRequired) {
            error = 'Please select a primary roofer';
          }
          if (error.length) {
            setError(error);
            return;
          }
        }

        if (job.type === JobType.battery) {
          let error = '';
          if (!hasPrimaryElectrician) {
            error = `Please select a primary electrician`;
          }
          if (error.length) {
            setError(error);
            return;
          }
        }

        const hasContractorsWithNoDates = assignedContractors.some((c => c.dates.length === 0)); 

        if (hasContractorsWithNoDates) {
          setError('Please assign at least one date to all contractors.');
          return; 
        }

        setError(undefined);
        void attach({
          variables: {
            input: {
              jobUuid: job.uuid,
              contractors: assignedContractors.map((c) => ({
                contractorProfileUuid: c.contractorProfileUuid,
                isElectrician: c.isElectrician,
                isRoofer: c.isRoofer,
                isTrainee: c.isTrainee,
                isPrimary: c.isPrimary,
                dates: c.dates
              })),
            },
          },
        });
      }}
    >
      <div className="flex">
        <div className="flex flex-col w-110">
          <div className="p-5 space-y-5">
            <h3 className="text-h3 font-nunito font-bold">
              Find{' '}
              {isInstallation && !job.revisitRequired ? 'teams' : 'contractors'}
            </h3>
            <div className="flex space-x-5">
              <SimpleCheckbox
                checked={isElectrician}
                setChecked={setIsElectrician}
                label="Electricians?"
              />
              <SimpleCheckbox
                checked={isRoofer}
                setChecked={setIsRoofer}
                label="Roofers?"
              />
            </div>
            <Input
              value={search}
              className="!mb-0 w-100 h-11"
              Icon={<MagnifyingGlassIcon className="size-6" />}
              onChange={(e) => setSearch(e.target.value)}
            />
            {(!!search.length || job.duration < 8) && (
              <Alert
                alertType="warning"
                text="Adding a contractor directly you are circumnavigating skill and distance checks."
              />
            )}
          </div>
          <div className="flex overflow-hidden">
            <div className="overflow-scroll flex items-center flex-col space-y-2 w-full px-5">
              <SuspendedComponent>
                {searchDebounced.length ||
                job.type !== JobType.installation ||
                job.duration < 8 ? (
                  <PotentialContractorsInner
                    date={new Date(job.targetDate)}
                    isElectrician={isElectrician}
                    isRoofer={isRoofer}
                    term={searchDebounced}
                    assignedContractors={assignedContractors}
                    setAssignedContractors={setAssignedContractors}
                  />
                ) : (
                  <PotentialTeamsInner
                    date={new Date(job.targetDate)}
                    isElectrician={isElectrician}
                    isRoofer={isRoofer}
                    term={searchDebounced}
                    assignedContractors={assignedContractors}
                    setAssignedContractors={setAssignedContractors}
                  />
                )}
              </SuspendedComponent>
            </div>
          </div>
        </div>
        <hr className="h-full shrink-0 w-px border-none bg-grey-700" />
        <div className="flex flex-col w-110">
          <div className="space-y-5 p-5">
            <TwoLineText
              label="Job Address"
              text={
                job.address
                  ? [
                      job.address.line1,
                      job.address.line2,
                      job.address.city,
                      job.address.postcode,
                    ]
                      .filter((a) => a)
                      .join(', ')
                  : ''
              }
              Icon={<MapPinIcon className="h-5 text-grey-400" />}
            />
            <TwoLineText
              label="Installation date"
              text={job.displayDate}
              Icon={<CalendarIcon className="h-5 text-grey-400" />}
            />
            <h3 className="text-h3 font-nunito font-bold">
              Assigned Contractors
            </h3>
            {error && <Alert alertType="error" text={error} />}
          </div>
          <div className="flex px-5 justify-center w-full h-full overflow-hidden mb-5">
            {assignedContractors.length === 0 ? (
              <div className="py-5 flex items-center justify-center">
                <div className="max-w-90">
                  <EmptyState
                    title="No contractors"
                    description="Start by finding contractors on the left and assigning them"
                  />
                </div>
              </div>
            ) : (
              <div className="overflow-scroll space-y-2 w-full">
                {assignedContractors.map((u) => (
                  <AssignedContractor
                    updateContractor={(attributes) =>
                      setAssignedContractors((as) =>
                        as.map((a) => {
                          if (
                            a.contractorProfileUuid === u.contractorProfileUuid
                          )
                            return {
                              ...a,
                              ...attributes,
                            };
                          return a;
                        }),
                      )
                    }
                    key={u.uuid}
                    contractor={u}
                    onDelete={(contractor) => {
                      setAssignedContractors((as) =>
                        as.filter(
                          ({ contractorProfileUuid }) =>
                            contractorProfileUuid !==
                            contractor.contractorProfileUuid,
                        ),
                      );
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AssignmentModal;
