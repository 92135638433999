import { ReactElement, useState } from 'react';
import AvatarStack from '../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { Button } from '../../../atoms/Button';
import { CheckCircleIcon, MapPinIcon } from '@heroicons/react/24/outline';
import AssignmentModal from '../../../molecules/Modals/Assignment';
import { Exact, gql, IndexJobContractorsQuery } from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { useJobContext } from '..';
import EmptyState from '../../../molecules/EmptyState';
import { client } from '../../../../main';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import Tag from '../../../atoms/Tag';
import { CircleIcon } from '../../../icons/Circle';
import { format } from 'date-fns';

const INDEX_JOB_CONTRACTORS = gql(`
  query IndexJobContractors ($uuid: String!) {
    indexContractorsForJob (uuid: $uuid) {
      uuid
      isElectrician
      isRoofer 
      isTrainee
      distance
      isPrimary
      user {
        uuid
        firstName
        lastName
        avatarSrc
      }
      displayDateRequired
      slots {
        uuid
        date
      }
      contractorProfileUuid
    }
  }
`);

const ContractorPanelInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexJobContractorsQuery,
    Exact<{
      uuid: string;
    }>
  >;
}): ReactElement => {
  const data = useReadQuery(queryRef);

  return data.data.indexContractorsForJob.length ? (
    <>
      {data.data.indexContractorsForJob.map((c) => (
        <div
          key={c.uuid}
          className={`${c.isPrimary ? 'border-primary border' : 'border-grey-400/40 border'} rounded-md overflow-hidden mb-3`}
        >
          <div className="p-2 flex items-center">
            <AvatarStack
              height="h-12"
              width="w-12"
              avatars={[
                {
                  firstName: c.user.firstName,
                  lastName: c.user.lastName,
                  avatarSrc: c.user.avatarSrc,
                },
              ]}
            />
            <div className="space-y-2.5 ml-2.5 flex-grow">
              <div className="flex items-center space-x-2.5 justify-between">
                <Link to="/contacts/$uuid" params={{ uuid: c.user.uuid }}>
                  <p className="font-semibold underline">
                    {c.user.firstName} {c.user.lastName}
                  </p>
                </Link>
              </div>

              <span className="text-body-small text-text-low-priority">
                Trade:
                {c.isElectrician && c.isRoofer
                  ? ' Electrician, roofer '
                  : c.isElectrician
                    ? ' Electrician'
                    : ' Roofer'}
              </span>
            </div>
          </div>
          <div className="p-2 bg-background-secondary flex items-center border-b border-grey-400/40">
            <div className="flex items-center flex-grow">
              <MapPinIcon className="size-5 text-grey-400 mr-1" />
              <span className="text-body-small py-2">
                {c.distance.toLocaleString('en-GB', {
                  maximumFractionDigits: 2,
                })}{' '}
                miles
              </span>
            </div>
            {c.isPrimary && <Tag text="Primary" colour="resolved" />}
          </div>

          <div className="p-2 bg-background-secondary flex items-center">
            <CheckCircleIcon className="size-5 text-grey-400 mr-1" />
            <span className="text-body-small">
              {c.isElectrician && c.isRoofer
                ? 'Electrician, roofer '
                : c.isElectrician
                  ? 'Electrician'
                  : 'Roofer'}
            </span>
            <div className="mx-2">
              <CircleIcon />
            </div>
            <span className="text-body-small">{c.displayDateRequired}</span>
          </div>
        </div>
      ))}
    </>
  ) : (
    <EmptyState
      title="No contractors"
      description="Start by finding contractors on the left and assigning them"
    />
  );
};

const ContractorPanel = (): ReactElement => {
  const { job, canAmendInstallationDetails } = useJobContext();

  const [queryRef] = useBackgroundQuery(INDEX_JOB_CONTRACTORS, {
    variables: {
      uuid: job.uuid,
    },
  });

  const [showAssignmentModal, setShowAssignmentModal] = useState(false);

  return (
    <>
      <div className="flex-grow">
        <span className="text-body-small text-text-low-priority mb-2 block">
          Contractors
        </span>
      </div>
      <div className="overflow-scroll h-full">
        <SuspendedComponent>
          <ContractorPanelInner queryRef={queryRef} />
        </SuspendedComponent>
      </div>
      {canAmendInstallationDetails && (
        <Button
          className="w-full flex items-center justify-center"
          bText="Assign contractors"
          onClick={() => setShowAssignmentModal(true)}
        />
      )}
      <AssignmentModal
        queryRef={queryRef}
        open={showAssignmentModal}
        onClose={(success, contractors) => {
          if (success && contractors) {
            client.graphqlClient().cache.writeQuery({
              query: INDEX_JOB_CONTRACTORS,
              variables: {
                uuid: job.uuid,
              },
              data: {
                indexContractorsForJob: contractors.map((c) => ({
                  ...c,
                  displayDateRequired:
                    c.dates.length === 1
                      ? format(c.dates[0], 'd MMMM yyyy')
                      : `${format(c.dates[0], 'd')} - ${format(
                          c.dates[c.dates.length - 1],
                          'd MMMM yyyy',
                        )}`,
                  slots: [],
                })),
              },
            });
          }
          setShowAssignmentModal(false);
        }}
      />
    </>
  );
};
export default ContractorPanel;
