import { ReactElement } from 'react';
import { JobFragmentFragment } from '@monorepo/graphql';
import TwoLineText from '../../../../atoms/TwoLineText';
import { format } from 'date-fns';

interface Props {
  formSubmission: NonNullable<JobFragmentFragment['survey']['formSubmission']>;
}
const FormSubmission = ({ formSubmission }: Props): ReactElement => (
  <div className="p-5 h-full bg-white space-y-6">
    <div className="flex items-center justify-between">
      <h2 className="font-bold font-nunito text-h2">Details</h2>
    </div>
    <div className="grid grid-cols-3 gap-5">
      <TwoLineText label="Reference" text={formSubmission.reference} />
      <TwoLineText label="Panel quantity" text={formSubmission.panelQuantity} />
      <TwoLineText
        label="Battery quantity"
        text={formSubmission.batteryQuantity}
      />
      <TwoLineText
        label="Created at"
        text={format(formSubmission.createdAt, 'do MMMM yyyy')}
      />
    </div>
  </div>
);
export default FormSubmission;
