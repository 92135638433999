import {
  CatchBoundary,
  Outlet,
  createFileRoute,
  useLocation,
  useRouter,
} from '@tanstack/react-router';
import Navigation from '../components/molecules/Navigation';
import CollapseSidebar from '../assets/icons/CollapseSidebar.svg';
import ExpandSidebar from '../assets/icons/ExpandSidebar.svg';
import { useEffect, useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import NavigationProfile from '../components/molecules/NavigationProfile';
import ErrorScreen from '../components/organisms/ErrorScreen';
import * as Sentry from '@sentry/react';
import { UserProvider } from '../utility/authentication';
import { SearchProvider, useSearch } from '../utility/search';
import { Button } from '../components/atoms/Button';
import CustomersTab from '../components/organisms/Contacts/Tabs/Customers';
import UsersTab from '../components/organisms/Contacts/Tabs/Users';
import ContractorsTab from '../components/organisms/Contacts/Tabs/Contractors';

export const Route = createFileRoute('/_authenticated')({
  component: LayoutComponent,
});

const LayoutComponentInner = () => {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const router = useRouter();
  const { searchTerm, setSearchTerm, constraint, setConstraint } = useSearch();

  const location = useLocation();

  useEffect(() => {
    setSearchTerm('');
    setConstraint('');
  }, [location, setSearchTerm, setConstraint]);

  return (
    <CatchBoundary
      onCatch={(error) => Sentry.captureException(error)}
      getResetKey={() => router.state.resolvedLocation.state.key ?? 1}
      errorComponent={ErrorScreen}
    >
      <UserProvider>
        <div className="w-full h-full flex">
          <Navigation display={displaySidebar} />
          <div className="flex w-full flex-col">
            <div className="flex bg-white border-b border-grey-700 py-3 px-5 items-center">
              <div className="flex-grow flex items-center">
                <button
                  type="button"
                  className="p-2.5 hover:bg-grey-900/70 rounded"
                  onClick={() => setDisplaySidebar((ds) => !ds)}
                >
                  <img
                    src={displaySidebar ? CollapseSidebar : ExpandSidebar}
                    alt="Collapse Sidebar"
                  />
                </button>

                <div className="text-text-normal group -outline-offset-1 flex border relative rounded overflow-hidden border-grey-500 transition-all duration-100 hover:border-grey-100 h-11 focus-within:outline-primary focus-within:outline focus-within:outline-2 ml-5 w-100">
                  <div className="flex items-center space-x-2 ml-3">
                    <MagnifyingGlassIcon className="size-6" />
                    {constraint && (
                      <div className="h-8 flex items-center space-x-1 bg-grey-900 border border-grey-500 rounded">
                        <span className="text-body-small ml-2">
                          {constraint}
                        </span>
                        <span className="text-body-small">/</span>
                        <Button
                          Icon={<XMarkIcon className="size-5" />}
                          bStyle="clean-dark"
                          onClick={() => setConstraint('')}
                          className="!p-0 !h-8 !w-8 justify-center hover:!bg-transparent cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                  <input
                    className="outline-none ml-2 w-full"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
              <div className="flex-grow flex justify-end">
                <NavigationProfile />
              </div>
            </div>

            <CatchBoundary
              onCatch={(error) => Sentry.captureException(error)}
              getResetKey={() => router.state.resolvedLocation.state.key ?? 1}
              errorComponent={ErrorScreen}
            >
              {searchTerm.length > 0 && constraint.length === 0 ? (
                <div className="flex overflow-scroll flex-col px-5 space-y-5 my-5">
                    <CustomersTab hideOnZero />
                    <UsersTab hideOnZero />
                    <ContractorsTab hideOnZero />
                </div>
              ) : (
                <Outlet />
              )}
            </CatchBoundary>
          </div>
        </div>
      </UserProvider>
    </CatchBoundary>
  );
};

function LayoutComponent() {
  return (
    <SearchProvider>
      <LayoutComponentInner />
    </SearchProvider>
  );
}
