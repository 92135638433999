import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';

interface SearchContext {
  searchTerm: string;
  debouncedSearchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  constraint: string;
  setConstraint: Dispatch<SetStateAction<string>>;
}

const SearchContext = createContext<SearchContext>({} as SearchContext);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [constraint, setConstraint] = useState('');

  const [debouncedSearchTerm] = useDebounce(searchTerm, 250); 

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        debouncedSearchTerm,
        setSearchTerm,
        constraint,
        setConstraint,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export function useSearch() {
  const context = useContext(SearchContext);
  return context;
}