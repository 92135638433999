import {
  useContext,
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { MeQuery, gql } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { usersUtility } from './users';

export interface UserContext {
  user: MeQuery['user'];
  setUser: Dispatch<SetStateAction<MeQuery['user']>>;
  logout: () => void;
}

const UserContext = createContext<UserContext>({} as UserContext);


export const RefreshGql = gql(`
  query Refresh($input: RefreshTokenInput) {
    refresh(input: $input) {
      accessToken
    }
  }
`);

const MeGql = gql(`
  query Me {
    user: findUniqueUser {
      uuid
      firstName
      lastName
      email
      avatarSrc
      unreadNotificationCount
      profile {
        ... on UserUserProfile {
          uuid
          role
        }
        ... on UserContractorProfile {
          uuid
        }
        ... on UserCustomerProfile {
          uuid
        }
      }
    }
  }
`);

export function UserProvider ({ children }: { children: ReactNode }) {

  const { data: userRsp } = useSuspenseQuery(MeGql);
  const { logout } = usersUtility.useLogout(); 

  const [user, setUser] = useState(userRsp.user);

  return (
    <UserContext.Provider value={{ user, logout, setUser }}>
      {children}
    </UserContext.Provider>
  );


}

export function useUser() {
  const context = useContext(UserContext);
  return context;
}
