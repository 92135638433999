import {
  ChangeEvent,
  DragEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import {
  CloudArrowUpIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  FolderIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { CircleIcon } from '../../../icons/Circle';
import { Button } from '../../../atoms/Button';
import ProgressBar from '../../../atoms/ProgressBar';
import { filesUtility } from '../../../../utility/files';
import Alert from '../../../atoms/Alerts';
import { FileTargetType } from '@monorepo/graphql';
import { notify } from '../../../../utility/notify';
import FilePreview from '../ExpandedFileViewer';

type DropEvent =
  | DragEvent
  | Event
  | React.ChangeEvent<HTMLInputElement>
  | React.DragEvent<HTMLElement>;

type Props = Omit<ModalWrapperPropsExtends, 'onClose'> & {
  title?: string;
  allowedFiles?: Array<(typeof filesUtility.mimeTypes)[number]>;
  targetType: FileTargetType;
  targetUuid: string;
  customerProfileUuid: string;
  onClose: (
    fromConfirm: boolean,
    files?: Array<
      ManagedFile & {
        uuid: string;
        key: string;
        src: string;
      }
    >,
  ) => void;
};

type ManagedFile = {
  file: File;
  progress: number;
  uuid?: string;
  src?: string;
  key?: string;
  error?: string;
  update: ManagedFileUpdateFn;
};

type ManagedFileUpdateFn = (
  attributes: Partial<Omit<ManagedFile, 'file' | 'update'>>,
) => void;

const UploadModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <UploadModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const UploadModalChild = ({
  onClose,
  title = 'Upload files',
  allowedFiles,
  targetType,
  targetUuid,
  customerProfileUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<ManagedFile[]>([]);

  const fileTypes = allowedFiles ?? filesUtility.mimeTypes;

  const { putSignedUrl, upload } = filesUtility.useFileUploader();

  const doSetDragging: EventListener = (ev) => {
    if (ev.type === 'dragover') {
      setDragging(true);
    } else {
      setDragging(false);
    }
  };

  const updateFileInProgress: (file: File) => ManagedFileUpdateFn = useCallback(
    (file: File) => (attributes) => {
      setFiles((files) =>
        files.map((f) => {
          if (f.file === file) {
            return {
              ...f,
              ...attributes,
            };
          }
          return f;
        }),
      );
    },
    [],
  );

  useEffect(() => {
    window.addEventListener('dragover', doSetDragging);
    window.addEventListener('dragleave', doSetDragging);
    return () => {
      window.removeEventListener('dragover', doSetDragging);
      window.removeEventListener('dragleave', doSetDragging);
    };
  });

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragover') {
      setDragging(true);
    }
    if (e.type === 'dragleave') {
      setDragging(false);
    }
    if (e.type === 'drop') {
      setDragging(false);
      void onChange(e);
    }
  };

  const onUpload = useCallback(
    async ({ files }: { files: ManagedFile[] }) => {
      await Promise.all(
        files.map(async (f) => {
          // file types is technically an array of mimeTypes but file.type is string so typescript hates me
          if (!(fileTypes as string[]).includes(f.file.type)) {
            f.update({
              error: `${f.file.type} file type is not allowed`,
              progress: 101, // error code for the progress bar
            });
            return;
          } else {
            const { data, errors } = await putSignedUrl({
              variables: {
                input: {
                  targetType,
                  targetUuid,
                  customerProfileUuid,
                  contentDisposition: `inline; filename=${f.file.name}`,
                },
              },
            });

            if (!data || errors) {
              f.update({
                error:
                  errors?.map(({ message }) => message).join(', ') ??
                  'Unable to upload file',
              });
            } else {
              try {
                const image = await upload({
                  url: data.putSignedFileUrl.url,
                  key: data.putSignedFileUrl.key,
                  file: f.file,
                  onProgress: (progress) => f.update({ progress }),
                  targetType,
                  targetUuid,
                  customerProfileUuid,
                });
                f.update({
                  src: f.file.type.includes('image') ? image.src : undefined,
                  uuid: image.uuid,
                  key: data.putSignedFileUrl.key,
                });
              } catch (e) {
                f.update({
                  error:
                    typeof (e as Error).message === 'string'
                      ? (e as Error).message
                      : 'Unkown error occurred.',
                });
              }
            }
          }
        }),
      );
    },
    [
      customerProfileUuid,
      fileTypes,
      putSignedUrl,
      targetType,
      targetUuid,
      upload,
    ],
  );

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const onChange = async (e: ChangeEvent<HTMLInputElement> | DropEvent) => {
    try {
      setUploadInProgress(true);
      const files =
        e.type === 'drop'
          ? (e as DragEvent).dataTransfer?.files
          : (e as ChangeEvent<HTMLInputElement>).target.files;
      if (!files || files.length === 0) return;
      const newFiles = Array.from(files).map((f) => ({
        progress: 0,
        update: updateFileInProgress(f),
        file: f,
      }));
      setFiles((f) => [...f, ...newFiles]);
      await onUpload({ files: newFiles });
      setUploadInProgress(false);
    } catch (e) {
      setUploadInProgress(false);
      throw new Error((e as Error).message);
    }
  };

  const [expandedImg, setExpandedImg] = useState<{
    src: string;
    name: string;
    mimeType: string;
  }>();

  return (
    <>
      <Modal
        title={title}
        onClose={(fromConfirm) => onClose(fromConfirm)}
        confirmText="Save"
        confirmCallback={() => {
          const savedFiles = files.filter(
            (
              f,
            ): f is ManagedFile & { uuid: string; key: string; src: string } =>
              !!f.uuid && !!f.key,
          );
          notify.success(
            `Successfully uploaded ${files.length} file${
              files.length > 1 ? 's' : ''
            }.`,
          );
          onClose(true, savedFiles);
          setFiles([]);
        }}
        loading={uploadInProgress}
      >
        <div className="p-5">
          <div
            onDrop={handleDrop}
            onDragOver={handleDrop}
            onDragLeave={handleDrop}
            className={`flex items-center flex-col p-5 rounded border-dashed border ${
              dragging ? 'border-primary border-solid' : 'border-grey-500'
            } px-40`}
          >
            <CloudArrowUpIcon className="size-20 text-grey-600" />
            <p className="font-semibold mb-1">
              Drag and drop files here to upload
            </p>
            <span className="text-body-small block mb-5">
              Allowed file types:{' '}
              {fileTypes
                .map((type) => filesUtility.mimeTypesFileExtensions[type])
                .reduce(
                  (prev, curr) =>
                    `${prev.includes(curr) ? '' : `${curr}, `}${prev}`,
                  '',
                )
                .slice(0, -2)}
            </span>
            <label
              htmlFor="file-upload"
              className="flex cursor-pointer items-center py-2 px-3 border rounded border-grey-500"
            >
              <span className="font-bold font-nunito text-body-small mr-2">
                Browse
              </span>
              <FolderIcon className="size-5" />
              <input
                onChange={onChange}
                type="file"
                multiple
                accept={fileTypes.join(',')}
                className="hidden"
                id="file-upload"
              />
            </label>
          </div>
          <div>
            {files.map(({ file: f, progress, src, error }, index) => (
              <div className="py-3" key={index}>
                <div className="flex items-center mb-2">
                  <div className="flex-grow flex items-center space-x-2">
                    {src ? (
                      <img
                        src={src}
                        onClick={() =>
                          setExpandedImg({
                            name: f.name,
                            src,
                            mimeType: f.type,
                          })
                        }
                        className="size-6"
                        alt={f.name}
                      />
                    ) : (
                      <DocumentTextIcon className="size-6" />
                    )}
                    <span className="text-body-small">{f.name}</span>
                    <CircleIcon multiplier={1} />
                    <span className="text-body-small">
                      {filesUtility.getSize(f.size)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    {error ? (
                      <ExclamationCircleIcon className="size-6 mr-3 text-red" />
                    ) : (
                      <span className="text-body-small mr-3 transition-all">
                        {progress}%
                      </span>
                    )}
                    <Button
                      bStyle="light"
                      className="h-9 w-9 !p-0 justify-center"
                      Icon={<XCircleIcon className="size-5 shrink-0" />}
                      onClick={() =>
                        setFiles((files) => files.filter((fi) => fi.file !== f))
                      }
                    />
                  </div>
                </div>
                <ProgressBar progress={progress} />
                {error && (
                  <div className="mt-2">
                    <Alert text={error} alertType="error" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <FilePreview
        open={!!expandedImg}
        onClose={() => setExpandedImg(undefined)}
        file={expandedImg}
      />
    </>
  );
};
export default UploadModal;
