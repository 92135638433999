import { ReactElement, useEffect, useMemo, useState } from 'react';
import { IndexJobContractorsQuery } from '@monorepo/graphql';
import AvatarStack from '../../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { MapPinIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import { DropdownWithBorder } from '../../../../atoms/Dropdown';
import { useJobContext } from '../../../../organisms/Job';
import { format } from 'date-fns';

const jobRoles = [
  {
    name: 'Roofer',
    value: 'isRoofer',
  },
  {
    name: 'Electrician',
    value: 'isElectrician',
  },
];

type ContractorType = Omit<
  IndexJobContractorsQuery['indexContractorsForJob'][0],
  'slots'
> & { dates: Date[] };

interface Props {
  contractor: ContractorType;
  updateContractor: (contractor: Partial<ContractorType>) => void;
  onDelete: (contractor: ContractorType) => void;
}
const AssignedContractor = ({
  contractor,
  onDelete,
  updateContractor,
}: Props): ReactElement => {
  const [selectedJobRoles, setSelectedJobsRoles] = useState(
    jobRoles.filter(
      ({ value }) => contractor[value as keyof typeof contractor] === true,
    ),
  );

  const { job } = useJobContext();

  const activeDates = useMemo(
    () =>
      job.activeSlots.map((d) => {
        const date = new Date(d.date);
        return {
          name: format(date, `d MMMM`),
          value: date,
        };
      }),
    [job],
  );

  const [selectedDates, setSelectedDates] =
    useState<Array<Omit<(typeof activeDates)[0], 'itemClassname'>>>(
      activeDates,
    );

  const [isPrimary, setIsPrimary] = useState(contractor.isPrimary);

  useEffect(() => {
    const isElectrician = selectedJobRoles.some(
      ({ value }) => value === 'isElectrician',
    );
    const isRoofer = selectedJobRoles.some(({ value }) => value === 'isRoofer');
    if (
      selectedDates.length !== contractor.dates.length ||
      contractor.isElectrician !== isElectrician ||
      contractor.isRoofer !== isRoofer ||
      contractor.isPrimary !== isPrimary
    )
      updateContractor({
        dates: selectedDates.map(({ value }) => value),
        isElectrician,
        isRoofer,
        isPrimary,
      });
  }, [
    job,
    selectedJobRoles,
    selectedDates,
    updateContractor,
    contractor,
    isPrimary,
  ]);

  return (
    <div
      className={`${isPrimary ? 'border-primary border' : 'border-grey-400/40 border'} rounded-md overflow-hidden`}
    >
      <div className="p-2 flex items-center">
        <AvatarStack
          height="h-9"
          width="w-9"
          avatars={[
            {
              firstName: contractor.user.firstName,
              lastName: contractor.user.lastName,
              avatarSrc: contractor.user.avatarSrc ?? undefined,
            },
          ]}
        />
        <div className="space-y-2.5 px-2 flex-grow">
          <Link to="/contacts/$uuid" params={{ uuid: contractor.user.uuid }}>
            <p className="font-semibold underline">
              {contractor.user.firstName} {contractor.user.lastName}
            </p>
          </Link>
          <span className="text-body-small text-text-low-priority">
            Trade: {selectedJobRoles.map(({ name }) => name).join(', ')}
          </span>
        </div>
      </div>
      <div className="p-2 bg-background-secondary">
        <div className="flex items-center justify-between flex-grow">
          <div className="flex items-center flex-grow">
            <MapPinIcon className="size-5 text-grey-400 mr-1" />
            <span className="text-body-small">
              {contractor.distance.toLocaleString('en-GB', {
                maximumFractionDigits: 2,
              })}{' '}
              miles
            </span>
          </div>
          <Button
            className="justify-center h-9 mr-2"
            bText={`${isPrimary ? 'Remove' : 'Make'} primary`}
            bStyle="clean"
            onClick={() => setIsPrimary(!isPrimary)}
          />
          <Button
            className="justify-center h-9 w-9 !p-0"
            bStyle="light"
            onClick={() => onDelete(contractor)}
            Icon={<TrashIcon className="size-5 text-red" />}
          />
        </div>
        <div className="flex flex-col mt-2 text-body-small space-y-2">
          {contractor.isElectrician && contractor.isRoofer && (
            <DropdownWithBorder
              buttonClassname="h-9 bg-white flex-grow justify-between text-body-small"
              buttonText={
                selectedJobRoles.length
                  ? selectedJobRoles.map(({ name }) => name).join(', ')
                  : '-- Select --'
              }
              selected={selectedJobRoles}
              options={jobRoles}
              respectButtonWidth
              onOptionSelect={(val) => {
                const index = selectedJobRoles.findIndex(
                  ({ value }) => value === val.value,
                );
                if (index !== -1) {
                  setSelectedJobsRoles((t) => t.filter((_, i) => i !== index));
                } else {
                  setSelectedJobsRoles((t) => [...t, val]);
                }
              }}
            />
          )}
          <DropdownWithBorder
            buttonClassname="h-9 bg-white justify-between text-body-small"
            disabled={activeDates.length === 1}
            buttonText={
              selectedDates.length
                ? selectedDates.map((d) => d.name).join(', ')
                : '-- Select --'
            }
            respectButtonWidth
            selected={selectedDates}
            options={activeDates}
            onOptionSelect={(val) => {
              const index = selectedDates.findIndex(
                ({ value }) => value === val.value,
              );
              if (index !== -1) {
                setSelectedDates((t) => t.filter((_, i) => i !== index));
              } else {
                setSelectedDates((t) => [...t, val]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AssignedContractor;
