import { ReactElement, useState } from 'react';
import Roof from '../../../../../assets/icons/Roof.svg';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import RoofModal, {
  RoofEditFormProps,
} from '../../../../molecules/Modals/UpsertRoof';
import TwoLineText from '../../../../atoms/TwoLineText';
import { gql, ScaffoldingStatus } from '@monorepo/graphql';
import { jobsUtility } from '../../../../../utility/jobs';
import SimpleModal from '../../../../molecules/Modals/Simple';
import { useMutation, useQuery } from '@apollo/client';
import { useJobContext } from '../..';
import Alert from '../../../../atoms/Alerts';
import Loader from '../../../../icons/Loader';
import EmptyState from '../../../../molecules/EmptyState';
import { client } from '../../../../../main';
import { notify } from '../../../../../utility/notify';
import { Button } from '../../../../atoms/Button';
import Dropdown from '../../../../atoms/Dropdown';
import { TagLight } from '../../../../atoms/Tag';

const statusOptions = [
  {
    name: 'Required',
    value: ScaffoldingStatus.required,
  },
  {
    name: 'Not Required',
    value: ScaffoldingStatus.notRequired,
  },
  {
    name: 'Booked',
    value: ScaffoldingStatus.booked,
  },
  {
    name: 'Erected',
    value: ScaffoldingStatus.erected,
  },
  {
    name: 'Struck',
    value: ScaffoldingStatus.struck,
  },
];

const INDEX_ROOFS = gql(`
  query IndexRoofsForJob ($uuid: String!) {
    indexRoofsForJob (uuid: $uuid) {
      uuid
      tile
      scaffolding {
        uuid
        location
        locationArea
        locationOtherDescription
        isBridgeRequired
        isTowerRequired
        height
        width
        notes
      }
      arrays {
        uuid
        panels
        orientation
      }
    }
  }  
`);

const DELETE_ROOF = gql(`
  mutation DeleteRoof ($uuid: String!) {
    deleteRoof (uuid: $uuid)
  }
`);

const UPDATE_JOB = gql(`
  mutation UpdateJobScaffoldingStatus ($input: UpdateJobInput!) {
    updateJob(input: $input) {
      status
    }
  }  
`);

const RoofTab = (): ReactElement => {
  const [showRoofModal, setShowRoofModal] = useState(false);
  const [roofToEdit, setRoofToEdit] = useState<RoofEditFormProps>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { job, canAmendSurveyDetails, canAmendInstallationDetails } = useJobContext();

  const [deleteRoof, { loading }] = useMutation(DELETE_ROOF);

  const { data, error } = useQuery(INDEX_ROOFS, {
    variables: {
      uuid: job.survey.uuid,
    },
    skip: !job.survey,
  });

  const [saveJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => notify.success('Updated job.'),
  });

  const selected = statusOptions.find(
    ({ value }) => value === job.scaffoldingStatus,
  );

  return (
    <>
      <div className="rounded-b-lg overflow-hidden">
        <div className="bg-white p-5">
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-h2 font-bold font-nunito">Scaffolding</h2>
            <Dropdown
              anchor="bottom end"
              selected={selected}
              buttonClassname="flex items-center space-x-2 p-2"
              options={statusOptions}
              disabled={!canAmendInstallationDetails}
              onOptionSelect={(opt) => {
                void saveJob({
                  variables: {
                    input: {
                      uuid: job.uuid,
                      scaffoldingStatus: opt.value,
                    },
                  },
                  update: (cache) => {
                    cache.evict({
                      fieldName: 'indexJobActions',
                      args: {
                        jobUuid: job.uuid,
                      },
                    });
                    cache.updateFragment(
                      {
                        fragment: jobsUtility.queries.JOB_FRAGMENT,
                        id: cache.identify(job),
                      },
                      (data) =>
                        data
                          ? {
                              ...data,
                              scaffoldingStatus: opt.value,
                            }
                          : data,
                    );
                  },
                });
              }}
              ButtonLeadIcon={
                <TagLight
                  size="rg"
                  colour={job.scaffoldingStatus}
                  text={
                    jobsUtility.jobScaffoldingStatusNiceMap[
                      job.scaffoldingStatus
                    ]
                  }
                />
              }
              ButtonIcon={<ChevronDownIcon className="size-5" />}
            />
            {canAmendSurveyDetails && (
              <Button
                reverse
                bStyle="outline"
                bText="Add Roof"
                onClick={() => setShowRoofModal(true)}
                Icon={<PlusIcon className="size-5" />}
              />
            )}
          </div>
          <div className="space-y-3">
            {data ? (
              data.indexRoofsForJob.length ? (
                data.indexRoofsForJob.map((d, i) => (
                  <div
                    key={d.uuid}
                    className="p-5 border border-grey-700 rounded flex flex-col"
                  >
                    <div className="flex items-center justify-between mb-5">
                      <div className="space-x-2 flex items-center">
                        <img src={Roof} alt="Roof" className="p-px" />
                        <h3 className="font-nunito text-h3 font-bold">
                          Roof {i + 1}
                        </h3>
                        <h3 className="font-nunito text-h3 text-text-low-priority">
                          -
                          {` ${d.arrays.length} array${
                            d.arrays.length > 1 ? 's' : ''
                          } (${d.arrays.reduce(
                            (prev, curr) => prev + curr.panels,
                            0,
                          )} total panels)`}
                        </h3>
                      </div>

                      <div className="flex items-center space-x-3">
                        {canAmendSurveyDetails && (
                          <>
                            <Button
                              className="h-9 w-9 !p-0 justify-center"
                              bStyle="light"
                              onClick={() => {
                                setRoofToEdit({
                                  uuid: d.uuid,
                                  roofTile: d.tile,
                                  arrays: d.arrays,
                                  location: d.scaffolding?.location,
                                  locationArea: d.scaffolding?.locationArea,
                                  isTowerRequired:
                                    d.scaffolding?.isTowerRequired,
                                  isBridgeRequired:
                                    d.scaffolding?.isBridgeRequired,
                                  locationAreaOtherDescription:
                                    d.scaffolding?.locationOtherDescription ??
                                    undefined,
                                  height: d.scaffolding?.height,
                                  width: d.scaffolding?.width,
                                  notes: d.scaffolding?.notes ?? undefined,
                                });
                                setShowRoofModal(true);
                              }}
                              Icon={<PencilSquareIcon className="size-5" />}
                            />
                            <Button
                              className="h-9 w-9 !p-0 justify-center"
                              bStyle="light"
                              onClick={() => {
                                setRoofToEdit({
                                  uuid: d.uuid,
                                  roofTile: d.tile,
                                  arrays: d.arrays,
                                });
                                setShowDeleteModal(true);
                              }}
                              Icon={<TrashIcon className="size-5 text-red" />}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mb-5">
                      <TwoLineText
                        label="Roof type"
                        text={jobsUtility.roofTileNiceMap[d.tile]}
                      />
                      {d.scaffolding && (
                        <>
                          <TwoLineText
                            label="Scaffolding location"
                            text={`${
                              jobsUtility.scaffoldingLocationNiceMap[
                                d.scaffolding.location
                              ]
                            } (${
                              jobsUtility.scaffoldingLocationAreaNiceMap[
                                d.scaffolding.locationArea
                              ]
                            })`}
                          />
                          <TwoLineText
                            label="Scaffolding width"
                            text={`${d.scaffolding.width} metres`}
                          />
                          <TwoLineText
                            label="Scaffolding height"
                            text={`${d.scaffolding.height} metres`}
                          />
                          <TwoLineText
                            Icon={
                              d.scaffolding.isBridgeRequired ? (
                                <CheckCircleIcon className="size-5 text-primary" />
                              ) : (
                                <XCircleIcon className="size-5 text-red" />
                              )
                            }
                            label="Bridge"
                            text={`A bridge is ${
                              d.scaffolding.isBridgeRequired ? '' : 'not'
                            } required`}
                          />
                          <TwoLineText
                            Icon={
                              d.scaffolding.isTowerRequired ? (
                                <CheckCircleIcon className="size-5 text-primary" />
                              ) : (
                                <XCircleIcon className="size-5 text-red" />
                              )
                            }
                            label="Tower"
                            text={`A tower is ${
                              d.scaffolding.isTowerRequired ? '' : 'not'
                            } required`}
                          />
                          <TwoLineText
                            label="Scaffolding notes"
                            text={d.scaffolding.notes}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <EmptyState
                  title="No roofs on this job"
                  description="Currently there are no roofs added to this job. You can amend roofs in the survey tool."
                />
              )
            ) : (
              <div className="flex items-center justify-center">
                {error ? (
                  <Alert alertType="error" text={error.message} />
                ) : (
                  <Loader />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <RoofModal
        title="Add Roof"
        open={showRoofModal}
        formProps={roofToEdit}
        onClose={(success) => {
          if (success) {
            void client.graphqlClient().refetchQueries({
              include: [INDEX_ROOFS],
            });
          }
          setRoofToEdit(undefined);
          setShowRoofModal(false);
        }}
        surveyUuid={job.survey.uuid}
      />
      <SimpleModal
        text="Are you sure you want to remove the roof and scaffolding requirements?"
        title="Remove Roof"
        onConfirm={() => {
          if (roofToEdit) {
            void deleteRoof({
              variables: {
                uuid: roofToEdit.uuid,
              },
              onCompleted: () => {
                notify.success('Deleted roof');
                void client.graphqlClient().refetchQueries({
                  include: [INDEX_ROOFS],
                });
                setShowDeleteModal(false);
              },
              onError: (err) =>
                notify.error(`Unable to delete roof \n ${err.message}`),
            });
          } else {
            setShowDeleteModal(false);
          }
        }}
        loading={loading}
        onConfirmText="Remove Roof"
        open={showDeleteModal}
        icon="critical"
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};
export default RoofTab;
