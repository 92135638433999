import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import {
  Exact,
  gql,
  IndexUsersFilterInput,
  IndexUsersForCustomersScreenQuery,
  InputMaybe,
  JobStatus,
  PaginationInput,
  UserCustomerProfile,
  UserProfile,
} from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import AvatarStack from '../../../../atoms/AvatarStack';
import {
  BuildingOffice2Icon,
  FunnelIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import { CircleIcon } from '../../../../icons/Circle';
import { customerUtiltiy } from '../../../../../utility/customers';
import { AllEnum } from '../../../../../utility/calendarContext';
import { DropdownWithBorder, Option } from '../../../../atoms/Dropdown';
import Drawer from '../../../../molecules/Drawer';
import PartnerDropdown from '../../../../molecules/PartnerDropdown';
import AddUserWithProfile from '../../../../molecules/Modals/AddUserWithProfile';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { useSearch } from '../../../../../utility/search';

const GET_USERS = gql(`
  query IndexUsersForCustomersScreen ($filters: IndexUsersFilterInput, $pagination: PaginationInput) {
	indexUsers (filters: $filters, pagination: $pagination) {
		items {
			uuid
      schemeName
			firstName
      lastName
			avatarSrc
      lastLogin
      address {
        postcode
      }
      profile {
        __typename
        ... on UserCustomerProfile {
          uuid
          partner {
            name
          }
        }
      }
		}
    pagination {
      lastPage
      total
    }
	}
}
`);

type UserWithCustomerProfileType =
  IndexUsersForCustomersScreenQuery['indexUsers']['items'][0] & {
    profile: UserCustomerProfile;
  };

const CustomersTabInner = ({
  queryRef,
  setTotalPages,
  setTotal,
}: {
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  queryRef: QueryRef<
    IndexUsersForCustomersScreenQuery,
    Exact<{
      filters: InputMaybe<IndexUsersFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotal: Dispatch<SetStateAction<number>>;
}) => {
  const customers = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(customers.data.indexUsers.pagination.lastPage);
    setTotal(customers.data.indexUsers.pagination.total);
  }, [setTotalPages, customers, setTotal]);

  return (
    <TableRows
      widthType="pc"
      rows={customers.data.indexUsers.items
        .filter(
          (i): i is UserWithCustomerProfileType =>
            i.profile.__typename === 'UserCustomerProfile',
        )
        .map((u) => ({
          uuid: u.uuid,
          cells: [
            {
              width: 22,
              content: (
                <div className="flex items-center space-x-2">
                  <AvatarStack
                    avatars={[
                      {
                        firstName: u.firstName,
                        lastName: u.lastName,
                        avatarSrc: u.avatarSrc ?? undefined,
                      },
                    ]}
                  />
                  <span className="text-body-small">
                    {u.firstName} {u.lastName}
                  </span>
                </div>
              ),
            },
            {
              width: 22,
              content: u.address?.postcode,
            },
            {
              width: 22,
              content: (
                <div className="flex items-center space-x-2">
                  <BuildingOffice2Icon className="size-5 text-grey-400" />
                  <span className="text-body-small">
                    {u.profile.partner.name}
                  </span>
                </div>
              ),
            },
            {
              width: 22,
              content: u.schemeName,
            },
            {
              width: 12,
              content: (
                <div className="flex justify-end w-full">
                  <Button
                    href={`/contacts/${u.uuid}`}
                    bText="View"
                    bStyle="outline"
                    className="h-9"
                  />
                </div>
              ),
            },
          ],
        }))}
    />
  );
};

const CustomerTab = ({
  constrainSearch,
  hideOnZero
}: {
  constrainSearch?: boolean; 
  hideOnZero?: boolean; 
}): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [partnerUuid, setPartnerUuid] = useState('all');
  const [totalPages, setTotalPages] = useState<number>();
  const [showFilters, setShowFilters] = useState(false);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const { debouncedSearchTerm, setConstraint } = useSearch();  
  const [total, setTotal] = useState<number>(-1);

  const [queryRef] = useBackgroundQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile: UserProfile.customer,
        partnerUuid: partnerUuid === 'all' ? undefined : partnerUuid,
        term: debouncedSearchTerm
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  useEffect(() => {
    if (constrainSearch) {
      setConstraint('Customers');
    }
  }, [setConstraint, constrainSearch]);

  const [filters, setFilters] = useState(customerUtiltiy.defaultFilters);

  const flatFilters = useMemo(
    () => [
      ...Object.keys(filters).reduce<Array<Option<string>>>((prev, k) => {
        const options = filters[k as keyof typeof filters];
        // if (!Array.isArray(options))
        //   return options.value !== AllEnum.all ? [...prev, options] : prev;
        return [...prev, ...options.filter((k) => k.value !== AllEnum.all)];
      }, []),
      ...(partnerUuid !== 'all'
        ? [
            {
              name: partnerUuid,
              value: partnerUuid,
            },
          ]
        : []),
    ],
    [filters, partnerUuid],
  );

  const addOrRemove = useCallback(
    (opt: Option<AllEnum | JobStatus>, key: keyof typeof filters) => {
      setFilters((ft) => {
        const item = ft[key];
        if (!Array.isArray(item)) return ft;
        let options = item.filter((opt) => opt.value !== AllEnum.all);

        if (opt.value === AllEnum.all) {
          options = [opt];
        } else {
          const indexOfOption = options.indexOf(opt);
          if (indexOfOption > -1) {
            options.splice(indexOfOption, 1);
          } else {
            options = [...options, opt];
          }
        }

        return {
          ...ft,
          [key]:
            options.length > 0
              ? options
              : [{ name: 'All', value: AllEnum.all }],
        };
      });
    },
    [],
  );

  return total === 0 && hideOnZero ? <></> : (
    <>
      <TableContainer
        title={`Customers ${debouncedSearchTerm.length && total > 0 ? `(${total})` : ''}`}
        toolbar={
          <>
            <div className="relative mr-2">
              <Button
                onClick={() => setShowFilters((ft) => !ft)}
                bStyle="light"
                className="h-11 w-11 !p-0 justify-center"
                Icon={<FunnelIcon className="size-6" />}
              />
              {!!flatFilters.length && (
                <CircleIcon
                  className="text-primary absolute top-0 right-0 translate-x-1/4 transform -translate-y-1/4"
                  multiplier={6}
                />
              )}
            </div>
            <Button
              bText="Add"
              Icon={<PlusIcon className="text-white size-6" />}
              onClick={() => setShowAddCustomerModal(true)}
              reverse
            />
            {/* <Button
              bText="Import"
              Icon={<DocumentPlusIcon className="size-6" />}
              reverse
            /> */}
          </>
        }
      >
        <TableColumns
          columns={[
            {
              width: 22,
              heading: 'name',
            },
            {
              width: 22,
              heading: 'Postcode',
            },
            {
              width: 22,
              heading: 'Partner',
            },
            {
              width: 22,
              heading: 'Scheme',
            },
            {
              width: 12,
            },
          ]}
          widthType="pc"
        />
        <SuspendedComponent>
          <CustomersTabInner
            setTotalPages={setTotalPages}
            setTotal={setTotal}
            queryRef={queryRef}
          />
        </SuspendedComponent>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </TableContainer>
      <Drawer setShow={setShowFilters} show={showFilters} title="Filters">
        <div className="w-72 flex flex-col">
          <SuspendedComponent>
            <div className="mb-5 flex gap-3 flex-wrap">
              {Object.keys(filters).map((f) => {
                const key = f as keyof typeof filters;
                const item = filters[key];
                if (Array.isArray(item)) {
                  return item
                    .filter((f) => f.value !== AllEnum.all)
                    .map((opt) => (
                      <button
                        key={opt.value}
                        onClick={() =>
                          addOrRemove(opt, f as keyof typeof filters)
                        }
                        className="h-8 px-2 flex items-center bg-grey-900 border border-grey-500 rounded space-x-1.5"
                      >
                        <span className="text-body-small">{opt.name}</span>
                        <XMarkIcon className="size-5" />
                      </button>
                    ));
                }
                return <></>;
                // if (item.value !== AllEnum.all)
                //   return (
                //     <button
                //       key={item.value}
                //       onClick={() =>
                //         setFilters((f) => ({
                //           ...f,
                //           [key]: contractorUtility.defaultFilters[key],
                //         }))
                //       }
                //       className="h-8 px-2 flex items-center bg-grey-900 border border-grey-500 rounded space-x-1.5"
                //     >
                //       <span className="text-body-small">{item.name}</span>
                //       <XMarkIcon className="size-5" />
                //     </button>
                //   );
              })}
            </div>
            <DropdownWithBorder
              selected={filters.customerStatus}
              options={customerUtiltiy.customerStatusOptions}
              onOptionSelect={(opt) => addOrRemove(opt, 'customerStatus')}
              label="Customer status"
              buttonClassname="justify-between mb-5"
              bubble
              buttonText={
                filters.customerStatus.length > 1
                  ? `Customer status (${filters.customerStatus.length})`
                  : filters.customerStatus[0].name
              }
            />
            <PartnerDropdown
              all
              partnerUuid={partnerUuid}
              setPartnerUuid={setPartnerUuid}
            />
          </SuspendedComponent>
        </div>
        <div className="flex flex-col space-y-3">
          <Button
            bText="Apply filters"
            className="w-full justify-center"
            onClick={() => setShowFilters(false)}
          />
          <Button
            disabled={flatFilters.length === 0}
            bText="Clear all"
            className="w-full justify-center"
            bStyle="clean"
            onClick={() => {
              setFilters(customerUtiltiy.defaultFilters);
              setPartnerUuid('all');
              setShowFilters(false);
            }}
          />
        </div>
      </Drawer>
      <AddUserWithProfile
        profileType="customer"
        open={showAddCustomerModal}
        onClose={() => setShowAddCustomerModal(false)}
      />
    </>
  );
};
export default CustomerTab;
