import { useForm } from '@tanstack/react-form';
import { Modal, ModalWrapper } from '../Base';
import { Input } from '../../../atoms/Input';
import { ReactElement } from 'react';
import UserDropown from '../../UserDropown';
import { Button } from '../../../atoms/Button';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  getFragmentData,
  gql,
  TeamFragmentFragment,
  UserProfile,
} from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { teamsUtiltiy } from '../../../../utility/teams';
import { client } from '../../../../main';
import { notify } from '../../../../utility/notify';
import Alert from '../../../atoms/Alerts';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';

export type TeamForUpsertModal = {
  uuid: string;
  name: string;
  mondayDouble: boolean;
  tuesdayDouble: boolean;
  wednesdayDouble: boolean;
  thursdayDouble: boolean;
  fridayDouble: boolean;
  saturdayDouble: boolean;
  sundayDouble: boolean;
  electricians: string[];
  roofers: string[];
};

type Props = {
  open: boolean;
  onClose: (success: boolean, data?: TeamFragmentFragment) => void;
  team?: TeamForUpsertModal;
};

const CREATE_TEAM = gql(`
    mutation CreateTeam ($input: CreateTeamInput!) {
      createTeam (input: $input) {
        ... TeamFragment
      }
    }
`);

const UPDATE_TEAM = gql(`
    mutation UpdateTeam ($input: UpdateTeamInput!) {
      updateTeam (input: $input) {
        ... TeamFragment
      }
    }
`);

const UpsertTeam = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="w-120" open={open} onClose={onClose}>
    <UpsertTeamInner onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertTeamInner = ({
  team,
  onClose,
}: Omit<Props, 'open'>): ReactElement => {
  const [create, { loading: createLoading }] = useMutation(CREATE_TEAM);
  const [update, { loading: updateLoading }] = useMutation(UPDATE_TEAM);

  const form = useForm<TeamForUpsertModal>({
    defaultValues: {
      uuid: team?.uuid ?? '',
      name: team?.name ?? '',
      mondayDouble: !!team?.mondayDouble,
      tuesdayDouble: !!team?.tuesdayDouble,
      wednesdayDouble: !!team?.wednesdayDouble,
      thursdayDouble: !!team?.thursdayDouble,
      fridayDouble: !!team?.fridayDouble,
      saturdayDouble: !!team?.saturdayDouble,
      sundayDouble: !!team?.sundayDouble,
      electricians: team?.electricians ?? [],
      roofers: team?.roofers ?? [],
    },
    onSubmit: ({ value }) => {
      if (team?.uuid) {
        void update({
          variables: {
            input: {
              uuid: value.uuid,
              name: value.name,
              mondayJobCapacity: value.mondayDouble ? 2 : 1,
              tuesdayJobCapacity: value.tuesdayDouble ? 2 : 1,
              wednesdayJobCapacity: value.wednesdayDouble ? 2 : 1,
              thursdayJobCapacity: value.thursdayDouble ? 2 : 1,
              fridayJobCapacity: value.fridayDouble ? 2 : 1,
              saturdayJobCapacity: value.saturdayDouble ? 2 : 1,
              sundayJobCapacity: value.sundayDouble ? 2 : 1,
              members: [...value.electricians, ...value.roofers],
            },
          },
          update: (cache) =>
            cache.evict({
              fieldName: 'indexUsers',
            }),
          onCompleted: (data) => {
            client.graphqlClient().cache.updateFragment(
              {
                id: client.graphqlClient().cache.identify(team),
                fragment: teamsUtiltiy.queries.TEAM_FRAGMENT,
              },
              (d) => (d ? { ...d, ...data.updateTeam } : null),
            );
            notify.success('Successfully updated team.');
            onClose(true);
          },
        });
      } else {
        void create({
          variables: {
            input: {
              name: value.name,
              mondayJobCapacity: value.mondayDouble ? 2 : 1,
              tuesdayJobCapacity: value.mondayDouble ? 2 : 1,
              wednesdayJobCapacity: value.mondayDouble ? 2 : 1,
              thursdayJobCapacity: value.mondayDouble ? 2 : 1,
              fridayJobCapacity: value.mondayDouble ? 2 : 1,
              saturdayJobCapacity: value.mondayDouble ? 2 : 1,
              sundayJobCapacity: value.mondayDouble ? 2 : 1,
              members: [...value.electricians, ...value.roofers],
            },
          },
          onCompleted: (data) => {
            notify.success('Successfully created team.');
            onClose(
              true,
              getFragmentData(
                teamsUtiltiy.queries.TEAM_FRAGMENT,
                data.createTeam,
              ),
            );
          },
        });
      }
    },
  });

  return (
    <Modal
      title={team?.uuid ? 'Edit team' : 'Add team'}
      onClose={onClose}
      confirmCallback={form.handleSubmit}
      confirmText="Save team"
      loading={createLoading || updateLoading}
      asForm
    >
      <div className="p-5">
        <form.Field
          name="name"
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              required
              onChange={(e) => handleChange(e.target.value)}
              label="Name"
            />
          )}
        />
        <div className="mb-5 flex flex-col">
        <label
          className="block mb-2 font-semibold text-input-label text-text-normal"
        >
          Does the team complete double jobs?
        </label>
        <span className="mb-2 block text-text-low-priority text-body-small">
          The team will have 2 slots generated on the selected days.
        </span>
        <div className='space-y-2'>
          <form.Field
            name="mondayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Monday'
              />
            )}
          />
          <form.Field
            name="tuesdayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Tuesday'
              />
            )}
          />
          <form.Field
            name="wednesdayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Wednesday'
              />
            )}
          />
          <form.Field
            name="thursdayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Thursday'
              />
            )}
          />
          <form.Field
            name="fridayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Friday'
              />
            )}
          />
          <form.Field
            name="saturdayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Saturday'
              />
            )}
          />
          <form.Field
            name="sundayDouble"
            children={({ state, handleChange }) => (
              <SimpleCheckbox
                checked={state.value}
                setChecked={handleChange}
                label='Sunday'
              />
            )}
          />
        </div>
        </div>
        <form.Field
          name="electricians"
          mode="array"
          children={({ state, pushValue, removeValue }) => (
            <div className="mb-5">
              <span className="mb-2 block font-semibold text-input-label text-text-normal">
                Electricians
              </span>
              <div className="mb-2">
                {state.value.map((_, i) => (
                  <form.Field
                    validators={{
                      onSubmit: ({ value }) =>
                        value.length === 0
                          ? 'Please select a contractor'
                          : undefined,
                    }}
                    key={i}
                    name={`electricians[${i}]`}
                  >
                    {({ state, handleChange }) => (
                      <div className="flex items-center space-x-2 mb-2">
                        <UserDropown
                          forTeams={{
                            isElectrician: true,
                          }}
                          hideMargin
                          error={state.meta.errors.join(',')}
                          userProfile={UserProfile.contractor}
                          userUuid={state.value}
                          setUserUuid={(_, profileUuid) =>
                            handleChange(profileUuid)
                          }
                          suspend
                        />
                        <Button
                          className="w-11 h-11 !p-0 justify-center"
                          bStyle="light"
                          onClick={() => void removeValue(i)}
                          Icon={
                            <TrashIcon className="size-5 text-red shrink-0" />
                          }
                        />
                      </div>
                    )}
                  </form.Field>
                ))}
              </div>
              {!!state.meta.errors.length && (
                <div className="mt-5">
                  <Alert alertType="error" text={state.meta.errors.join(',')} />
                </div>
              )}
              <Button
                bText="Add electrician"
                className="mt-5"
                Icon={<PlusIcon className="size-5" />}
                bStyle="light"
                onClick={() => pushValue('')}
              />
            </div>
          )}
        />
        <form.Field
          name="roofers"
          mode="array"
          children={({ state, pushValue, removeValue }) => (
            <>
              <span className="mb-2 block font-semibold text-input-label text-text-normal">
                Roofers
              </span>
              <div className="mb-2">
                {state.value.map((_, i) => (
                  <form.Field
                    validators={{
                      onSubmit: ({ value }) =>
                        value.length === 0
                          ? 'Please select a roofer'
                          : undefined,
                    }}
                    key={i}
                    name={`roofers[${i}]`}
                  >
                    {({ state, handleChange }) => (
                      <div className="flex items-center space-x-2 mb-2">
                        <UserDropown
                          forTeams={{
                            isRoofer: true,
                          }}
                          hideMargin
                          error={state.meta.errors.join(',')}
                          userProfile={UserProfile.contractor}
                          userUuid={state.value}
                          setUserUuid={(_, profileUuid) =>
                            handleChange(profileUuid)
                          }
                          suspend
                        />
                        <Button
                          className="w-11 h-11 !p-0 justify-center"
                          bStyle="light"
                          onClick={() => void removeValue(i)}
                          Icon={
                            <TrashIcon className="size-5 text-red shrink-0" />
                          }
                        />
                      </div>
                    )}
                  </form.Field>
                ))}
              </div>
              {!!state.meta.errors.length && (
                <div className="mt-5">
                  <Alert alertType="error" text={state.meta.errors.join(',')} />
                </div>
              )}
              <Button
                bText="Add roofer"
                className="mt-5"
                Icon={<PlusIcon className="size-5" />}
                bStyle="light"
                onClick={() => pushValue('')}
              />
            </>
          )}
        />
      </div>
    </Modal>
  );
};

export default UpsertTeam;
