import { ReactElement, useEffect, useRef, useState } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import Loader from '../../../icons/Loader';
import Alert from '../../../atoms/Alerts';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

type Props = ModalWrapperPropsExtends & {
  file?: {
    name: string;
    src: string;
    mimeType: string;
  };
};

const maxRetries = 6;

const GViewer = ({
  src,
  name,
}: {
  src: string;
  name: string;
}): ReactElement => {
  const url = `https://docs.google.com/gview?url=${encodeURIComponent(
    src,
  )}&embedded=true`;

  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (loading && retryCount < maxRetries) {
      timerRef.current = setInterval(() => {
        setRetryCount((prev) => prev + 1);
      }, 2500);
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [loading, retryCount]);

  const handleLoad = () => {
    setLoading(false);
    if (timerRef.current) clearInterval(timerRef.current);
  };

  const handleError = () => {
    if (retryCount < 5) {
      setLoading(true);
    }
  };

  return (
    <>
      {loading && retryCount < maxRetries && (
        <div className="flex justify-center mt-5">
          <Loader />
        </div>
      )}
      {retryCount === maxRetries && (
        <div className="m-5">
          <Alert text="Failed to load." alertType="error" />
        </div>
      )}
      <iframe
        ref={ref}
        key={retryCount}
        src={url}
        title={name}
        onLoad={handleLoad}
        onError={handleError}
        className="h-full w-full"
      />
    </>
  );
};

const ExpandedFileViewer = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="h-full max-h-[95%]"
    open={open}
    onClose={onClose}
  >
    <ExpandedFileViewerChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const ExpandedFileViewerChild = ({
  file,
  onClose,
}: Omit<Props, 'open'>): ReactElement => (
  <Modal
    closeText="Close"
    scroll={false}
    title={file?.name ?? 'No image selected'}
    onClose={onClose}
  >
    <div className="flex flex-col w-[1080px] max-w-4/5">
      {file &&
        file.mimeType.includes('image') &&
        (file.mimeType === 'image/heif' || file.mimeType === 'image/heic' ? (
          <div className='flex flex-col items-center h-full justify-center mt-5'>
            <ExclamationCircleIcon className='text-red size-8 mb-2' />
            <h4 className='text-h4 font-nunito font-bold'>Unable to preview image</h4>
          </div>
        ) : (
          <img
            className="object-contain w-full h-full"
            src={file.src}
            alt={file.name}
          />
        ))}
      {file && file.mimeType.includes('pdf') && (
        <iframe className="w-full h-full" src={file.src} title={file.name} />
      )}
      {file &&
        (file.mimeType.includes('officedocument') ||
          file.mimeType.includes('csv')) && (
          <GViewer src={file.src} name={file.name} />
        )}
    </div>
  </Modal>
);
export default ExpandedFileViewer;
