import { ReactElement, startTransition, useState } from 'react';
import { getFragmentData, gql } from '@monorepo/graphql';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { useUser } from '../../../../utility/authentication';
import { RowsPerPage } from '../../Pagination';
import { BellIcon } from '@heroicons/react/24/outline';
import { TagLight } from '../../../atoms/Tag';
import { format } from 'date-fns';
import { CircleIcon } from '../../../icons/Circle';
import { Button } from '../../../atoms/Button';
import { notificationsUtiltiy } from '../../../../utility/notifications';
import { useRouter } from '@tanstack/react-router';
import EmptyState from '../../EmptyState';
import { InView, useInView } from 'react-intersection-observer';
import { client } from '../../../../main';

interface Props {
  type: 'current' | 'dismissed';
  onClose: () => void;
}

const INDEX_NOTIFICATIONS = gql(`
  query IndexNotificationsForNotificationsFeed ($filters: IndexNotificationsFilterInput, $pagination: PaginationInput) {
    indexNotifications (filters: $filters, pagination: $pagination) {
      items {
        ... Notification
      }
      pagination {
        lastPage
      }
    }
  }
`);

const VIEW_NOTIFICATION = gql(`
  mutation ViewNotificationForNotificationsFeed ($uuid: String!) {
    viewNotification (uuid: $uuid) {
      ... Notification
    }
  }
`);

const DISMISS_NOTIFICATION = gql(`
  mutation DismissNotificationForNotificationsFeed ($uuid: String!) {
    dismissNotification (uuid: $uuid) {
      ... Notification
    }
  }
`);

const DISMISS_ALL_NOTIFICATIONS = gql(`
  mutation DismissAllNotificationForNotificationsFeed {
    dismissAllNotifications
  }
`);

const Notifications = ({ type, onClose }: Props): ReactElement => {
  const [page, setPage] = useState(1);
  const { ref } = useInView();
  const { setUser } = useUser();

  const { data, fetchMore } = useSuspenseQuery(INDEX_NOTIFICATIONS, {
    variables: {
      filters: {
        isDismissed: type === 'dismissed',
      },
      pagination: {
        perPage: RowsPerPage.twentyfive,
        page: 1,
      },
    },
  });

  const [view] = useMutation(VIEW_NOTIFICATION);
  const [dismissAll] = useMutation(DISMISS_ALL_NOTIFICATIONS);
  const [dismiss] = useMutation(DISMISS_NOTIFICATION);

  const router = useRouter();

  return (
    <div
      key={type}
      className="flex items-center flex-col overflow-hidden h-full"
    >
      <div className="flex flex-col h-full overflow-scroll w-full">
        {data.indexNotifications.items.length ? (
          <>
            {data.indexNotifications.items
              .map((n) =>
                getFragmentData(
                  notificationsUtiltiy.queries.NOTIFICATION_FRAGMENT,
                  n,
                ),
              )
              .map((n) => (
                <div
                  key={n.uuid}
                  className="border-b border-grey-700 last:border-none"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <BellIcon className="size-6 text-primary" />
                      <div className="flex-grow mx-2">
                        <h4 className="text-h4 font-bold font-nunito">
                          {n.title}
                        </h4>
                      </div>
                      {n.isViewed ? (
                        <TagLight colour="done" text="Seen" />
                      ) : (
                        <TagLight text="New" colour="open" />
                      )}
                    </div>
                    <p className="text-sm my-4">{n.description}</p>
                    <div className="flex items-center">
                      <div className="flex-grow flex items-center space-x-2">
                        <span className="text-text-low-priority text-xs">
                          {format(n.createdAt, 'do MMMM yyyy')}
                        </span>
                        <CircleIcon multiplier={1} />
                        <span className="text-text-low-priority text-xs">
                          {format(n.createdAt, 'H:mm')}
                        </span>
                      </div>
                      {type === 'current' && (
                        <Button
                          bStyle="clean"
                          bText="Dismiss"
                          className="mr-2"
                          onClick={() => {
                            if (!n.isViewed) {
                              setUser((user) => ({
                                ...user,
                                unreadNotificationCount:
                                  user.unreadNotificationCount - 1,
                              }));
                            }
                            void dismiss({
                              variables: {
                                uuid: n.uuid,
                              },
                            });
                            client.graphqlClient().cache.updateQuery(
                              {
                                query: INDEX_NOTIFICATIONS,
                                variables: {
                                  filters: {
                                    isDismissed: false,
                                  },
                                  pagination: {
                                    perPage: RowsPerPage.twentyfive,
                                    page: 1,
                                  },
                                },
                              },
                              (d) =>
                                d
                                  ? {
                                      indexNotifications: {
                                        ...d.indexNotifications,
                                        items: d.indexNotifications.items
                                          .map((d) =>
                                            getFragmentData(
                                              notificationsUtiltiy.queries
                                                .NOTIFICATION_FRAGMENT,
                                              d,
                                            ),
                                          )
                                          .filter(
                                            ({ uuid }) => uuid !== n.uuid,
                                          ),
                                      },
                                    }
                                  : null,
                            );
                            client.graphqlClient().cache.gc();
                            client.graphqlClient().cache.evict({
                              fieldName: 'indexNotifications',
                              args: {
                                filters: {
                                  isDismissed: true,
                                },
                                pagination: {
                                  perPage: RowsPerPage.twentyfive,
                                  page: 1,
                                },
                              },
                            });
                          }}
                        />
                      )}
                      <Button
                        bText={`View ${n.targetType}`}
                        onClick={() => {
                          if (!n.isViewed) {
                            setUser((user) => ({
                              ...user,
                              unreadNotificationCount:
                                user.unreadNotificationCount - 1,
                            }));
                            void view({
                              variables: {
                                uuid: n.uuid,
                              },
                            });
                            client.graphqlClient().cache.updateFragment(
                              {
                                id: client.graphqlClient().cache.identify(n),
                                fragment:
                                  notificationsUtiltiy.queries
                                    .NOTIFICATION_FRAGMENT,
                              },
                              (d) =>
                                d
                                  ? {
                                      ...d,
                                      isViewed: true,
                                    }
                                  : null,
                            );
                          }
                          onClose();
                          void router.navigate({
                            to: notificationsUtiltiy.actionUrl({
                              targetUuid: n.targetUuid,
                              targetType: n.targetType,
                              targetUserUuid: n.targetUserUuid,
                            }),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            <InView
              as="div"
              onChange={(inView) => {
                if (
                  inView &&
                  data.indexNotifications.pagination.lastPage > page
                ) {
                  const nextPage = page + 1;
                  startTransition(() => {
                    void fetchMore({
                      variables: {
                        filters: {
                          isDismissed: type === 'dismissed',
                        },
                        pagination: {
                          perPage: RowsPerPage.twentyfive,
                          page: nextPage,
                        },
                      },
                      updateQuery(previousQueryResult, options) {
                        return {
                          indexNotifications: {
                            ...options.fetchMoreResult.indexNotifications,
                            pagination:
                              options.fetchMoreResult.indexNotifications
                                .pagination,
                            items: [
                              ...previousQueryResult.indexNotifications.items,
                              ...options.fetchMoreResult.indexNotifications
                                .items,
                            ],
                          },
                        };
                      },
                    });
                  });
                  setPage(nextPage);
                }
              }}
            >
              <div className="h-1 w-full" ref={ref} />
            </InView>
          </>
        ) : (
          <EmptyState
            title="Notifications Clear 🎉"
            description="Nothing to see here"
          />
        )}
      </div>

      {!!data.indexNotifications.items.length && type === 'current' && (
        <div className="p-5 w-full border-t border-grey-700">
          <Button
            onClick={() => {
              void dismissAll();
              client.graphqlClient().cache.updateQuery(
                {
                  query: INDEX_NOTIFICATIONS,
                  variables: {
                    filters: {
                      isDismissed: false,
                    },
                    pagination: {
                      perPage: RowsPerPage.twentyfive,
                      page: 1,
                    },
                  },
                },
                (d) =>
                  d
                    ? {
                        indexNotifications: {
                          ...d.indexNotifications,
                          pagination: {
                            lastPage: 1,
                          },
                          items: [],
                        },
                      }
                    : null,
              );
              client.graphqlClient().cache.evict({
                fieldName: 'indexNotifications',
                args: {
                  filters: {
                    isDismissed: true,
                  },
                  pagination: {
                    perPage: RowsPerPage.twentyfive,
                    page: 1,
                  },
                },
              });
              setUser((user) => ({
                ...user,
                unreadNotificationCount: 0,
              }));
            }}
            bText="Dismiss all"
            bStyle="outline"
            className="w-full justify-center"
          />
        </div>
      )}
    </div>
  );
};
export default Notifications;
