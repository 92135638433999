import { ReactElement } from 'react';
import CalendarDay from '../CalendarDay';
import { addDays, format, isSameDay, startOfWeek } from 'date-fns';
import { CalendarPeriod } from '../../../utility/calendarContext';
import { useSuspenseQuery } from '@apollo/client';
import { gql } from '@monorepo/graphql';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

interface Props {
  period: CalendarPeriod;
  selectedDate: Date;
  organisationUuid?: string;
}

const days: Record<CalendarPeriod, number> = {
  week: 7,
  day: 1,
  weekdays: 5,
};

const GET_SLOT_COUNT = gql(`
    query SlotCount($input: SlotCountInput!) {
      slotCount(input: $input)
    }
`);

const FreeDays = ({
  date,
  organisationUuid,
}: {
  date: Date;
  organisationUuid?: string;
}) => {
  const { data } = useSuspenseQuery(GET_SLOT_COUNT, {
    variables: {
      input: {
        date: new Date(format(date, 'yyyy-MM-dd')), // prevent stupid UTC conversion,
        organisationUuid,
      },
    },
    context: {
      isBatched: true,
    },
  });
  return (
    <p className="text-body-small text-text-low-priority">{data.slotCount}</p>
  );
};

const CalendarDays = ({
  period,
  selectedDate,
  organisationUuid,
}: Props): ReactElement => {
  if (period === CalendarPeriod.day) {
    return (
      <div className="flex bg-white flex-col w-full h-full overflow-hidden">
        <div className="flex px-2 items-center justify-center h-fit py-3 border-t border-grey-700 flex-col">
          <div className="mb-2.5 h-10 w-10 shrink-0 rounded-full flex items-center justify-center">
            <h3 className="font-nunito leading-none text-h3 font-bold">
              {format(selectedDate, 'd')}
            </h3>
          </div>
          <p className="text-body-small font-semibold">
            {format(selectedDate, 'EEEE')}
          </p>
          <SuspendedComponent hideErrorText multiplier={0.5}>
            <FreeDays organisationUuid={organisationUuid} date={selectedDate} />
          </SuspendedComponent>
        </div>
        <div className="flex overflow-scroll grow space-x-2 px-2">
          <CalendarDay date={selectedDate} organisationUuid={organisationUuid} />
        </div>
      </div>
    );
  }

  const start = startOfWeek(selectedDate, { weekStartsOn: 1 });

  return (
    <div className="flex bg-white flex-col w-full h-full overflow-hidden">
      <div className="flex">
        {Array.from(new Array(days[period])).map((_, i) => (
          <div
            key={i}
            className="flex px-2 items-center flex-grow justify-center h-fit py-3 border-t border-grey-700 flex-col"
          >
            <div
              className={`mb-2.5 h-10 w-10 shrink-0 rounded-full flex items-center justify-center ${
                isSameDay(addDays(start, i), new Date())
                  ? 'bg-primary-600 text-primary'
                  : ''
              }`}
            >
              <h3 className="font-nunito leading-none text-h3 font-bold">
                {format(addDays(start, i), 'd')}
              </h3>
            </div>
            <p className="text-body-small font-semibold">
              {format(addDays(start, i), 'EEEE')}
            </p>
            <SuspendedComponent hideErrorText multiplier={0.5}>
              <FreeDays organisationUuid={organisationUuid} date={addDays(start, i)} />
            </SuspendedComponent>
          </div>
        ))}
      </div>
      <div className="flex overflow-scroll grow space-x-2 px-2">
        {Array.from(new Array(days[period])).map((_, i) => (
          <CalendarDay
            organisationUuid={organisationUuid}
            key={i}
            date={addDays(start, i)}
          />
        ))}
      </div>
    </div>
  );
};
export default CalendarDays;
