import { ReactElement, useState } from 'react';
import { Exact, getFragmentData, gql } from '@monorepo/graphql';
import {
  IndexTeamsQuery,
  TeamFragmentFragment,
} from '@monorepo/graphql-masked';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../molecules/Table';
import {
  ArrowLeftIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from '@tanstack/react-router';
import { Button } from '../../../atoms/Button';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import AvatarStack from '../../../atoms/AvatarStack';
import UpsertTeam, {
  TeamForUpsertModal,
} from '../../../molecules/Modals/UpsertTeam';
import { teamsUtiltiy } from '../../../../utility/teams';
import { client } from '../../../../main';
import SimpleModal from '../../../molecules/Modals/Simple';
import { notify } from '../../../../utility/notify';

const INDEX_TEAMS = gql(`
  query IndexTeams {
    indexTeams {
      ... TeamFragment
    }
  }  
`);

const DELETE_TEAM = gql(`
    mutation DeleteTeam ($uuid: String!) {
      deleteTeam (uuid: $uuid)
    }
`);

const TeamsInner = ({
  queryRef,
}: {
  queryRef: QueryRef<IndexTeamsQuery, Exact<Record<string, never>>>;
}): ReactElement => {
  const teams = useReadQuery(queryRef);
  const [teamToDelete, setTeamToDelete] = useState<TeamFragmentFragment>();
  const [team, setTeam] = useState<TeamForUpsertModal>();

  const [deleteTeam, { loading }] = useMutation(DELETE_TEAM);

  return (
    <>
      <TableRows
        rows={teams.data.indexTeams
          .map((t) => getFragmentData(teamsUtiltiy.queries.TEAM_FRAGMENT, t))
          .sort((a, b) => {
            const aSize = parseInt(a.name.replace('Infinity ', ''));
            const bSize = parseInt(b.name.replace('Infinity ', ''));
            return aSize > bSize ? 1 : -1;
          }) 
          .map((team) => ({
            uuid: team.uuid,
            cells: [
              {
                width: 30,
                content: team.name,
              },
              {
                width: 31,
                content: (
                  <AvatarStack
                    height="h-9"
                    width="w-9"
                    showNameOnOneResult
                    avatars={team.electricians}
                  />
                ),
              },
              {
                width: 31,
                content: (
                  <AvatarStack
                    height="h-9"
                    width="w-9"
                    showNameOnOneResult
                    avatars={team.roofers}
                  />
                ),
              },
              {
                width: 8,
                content: (
                  <div className="flex space-x-2 justify-end w-full">
                    <Button
                      bStyle="light"
                      className="w-9 h-9 justify-center !p-0"
                      Icon={<TrashIcon className="text-red size-5" />}
                      onClick={() => setTeamToDelete(team)}
                    />
                    <Button
                      bStyle="light"
                      onClick={() =>
                        setTeam({
                          uuid: team.uuid,
                          name: team.name,
                          mondayDouble: team.mondayJobCapacity > 1,
                          tuesdayDouble: team.tuesdayJobCapacity > 1,
                          wednesdayDouble: team.wednesdayJobCapacity > 1,
                          thursdayDouble: team.thursdayJobCapacity > 1,
                          fridayDouble: team.fridayJobCapacity > 1,
                          saturdayDouble: team.saturdayJobCapacity > 1,
                          sundayDouble: team.sundayJobCapacity > 1,
                          roofers: team.roofers.map(({ uuid }) => uuid),
                          electricians: team.electricians.map(
                            ({ uuid }) => uuid,
                          ),
                        })
                      }
                      className="w-9 h-9 justify-center !p-0"
                      Icon={<PencilSquareIcon className="size-5" />}
                    />
                  </div>
                ),
              },
            ],
          }))}
        widthType="pc"
      />
      <UpsertTeam
        open={!!team}
        team={team}
        onClose={() => {
          setTeam(undefined);
        }}
      />
      <SimpleModal
        text={`Are you sure you want to delete ${teamToDelete?.name}? Any jobs that are allocated to this team will be unbooked.`}
        title="Delete team"
        loading={loading}
        onConfirm={() => {
          if (!teamToDelete) return;
          void deleteTeam({
            variables: {
              uuid: teamToDelete.uuid,
            },
            update: (cache) =>
              cache.updateQuery(
                {
                  query: INDEX_TEAMS,
                },
                (q) =>
                  q?.indexTeams
                    ? {
                        indexTeams: q.indexTeams
                          .map((t) =>
                            getFragmentData(
                              teamsUtiltiy.queries.TEAM_FRAGMENT,
                              t,
                            ),
                          )
                          .filter(({ uuid }) => uuid !== teamToDelete.uuid),
                      }
                    : null,
              ),
            onCompleted: () => {
              notify.success('Deleted team');
              setTeamToDelete(undefined);
            },
          });
        }}
        icon="critical"
        onConfirmText="Delete"
        open={!!teamToDelete}
        onClose={() => setTeamToDelete(undefined)}
      />
    </>
  );
};

const Teams = (): ReactElement => {
  const [queryRef] = useBackgroundQuery(INDEX_TEAMS);

  const router = useRouter();

  const [showTeamModal, setShowTeamModal] = useState(false);

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <h1 className="text-h1 font-nunito font-bold">Teams</h1>
            </div>
            <Button
              reverse
              Icon={<PlusIcon className="size-6 text-white" />}
              onClick={() => setShowTeamModal(true)}
              bText="Add team"
            />
          </div>
          <TableContainer>
            <TableColumns
              columns={[
                { width: 30, heading: 'Name' },
                { width: 31, heading: 'Electricians' },
                { width: 31, heading: 'Roofers' },
                { width: 8 },
              ]}
              widthType="pc"
            />
            <SuspendedComponent>
              <TeamsInner queryRef={queryRef} />
            </SuspendedComponent>
          </TableContainer>
        </div>
      </div>
      <UpsertTeam
        open={showTeamModal}
        onClose={(success, data) => {
          if (data) {
            client.graphqlClient().cache.updateQuery(
              {
                query: INDEX_TEAMS,
              },
              (d) =>
                d
                  ? {
                      indexTeams: [data, ...d.indexTeams],
                    }
                  : null,
            );
          }
          setShowTeamModal(false);
        }}
      />
    </>
  );
};
export default Teams;
