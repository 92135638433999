import { ReactElement, useEffect, useState } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import { addressUtility } from '../../../../utility/address';
import {
  gql,
  UpsertOrganisationMutation,
  SlotCreationMethod,
} from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import Alert from '../../../atoms/Alerts';
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import NumberInput from '../../../atoms/NumberInput';
import RadioSelector from '../../../atoms/RadioSelector';

type Props = {
  open: boolean;
  onClose: (
    success: boolean,
    data?: UpsertOrganisationMutation['upsertOrganisation'],
  ) => void;
  uuid?: string;
  name?: string;
  email?: string;
  telephone?: string;
  website?: string;
  servicingRadius?: number;
  slotCreationMethod?: SlotCreationMethod;
  slotCreationAmount?: number;
  address?: {
    line1: string;
    line2?: string;
    city?: string;
    postcode: string;
    latitude?: number;
    longitude?: number;
  };
};

const UPSERT_ORGANISATION = gql(`
  mutation UpsertOrganisation ($input: UpsertOrganisationInput!) {
    upsertOrganisation (input: $input) {
      uuid
      name 
      email
      displayAddress
      telephone
      website
      canDelete
      servicingRadius
      slotCreationMethod
      slotCreationAmount
      address {
        line1
        line2
        city
        postcode
        latitude
        longitude
      }
    }
  }  
`);

const UpsertOrganisation = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="w-140" open={open} onClose={onClose}>
    <UpsertOrganisationChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertOrganisationChild = ({
  onClose,
  uuid,
  name,
  email,
  telephone,
  website,
  address,
  servicingRadius,
  slotCreationMethod,
  slotCreationAmount,
}: Omit<Props, 'open'>): ReactElement => {
  const [upsert, { loading, error }] = useMutation(UPSERT_ORGANISATION);

  const [fetchingPostcode, setFetchingPostcode] = useState(false);

  const form = useForm({
    onSubmit: ({ value }) => {
      void upsert({
        variables: {
          input: {
            uuid,
            ...value,
          },
        },
        onCompleted: (data) => {
          form.reset();
          notify.success('Successfully saved organisation.');
          onClose(true, uuid ? undefined : data.upsertOrganisation);
        },
      });
    },
    defaultValues: {
      name: name ?? '',
      email: email ?? '',
      telephone: telephone ?? '',
      website: website ?? '',
      servicingRadius: servicingRadius ?? 90,
      slotCreationMethod: slotCreationMethod ?? SlotCreationMethod.contractors,
      slotCreationAmount,
      address: {
        line1: address?.line1 ?? '',
        line2: address?.line2 ?? '',
        city: address?.city ?? '',
        postcode: address?.postcode ?? '',
        latitude: address?.latitude ?? 0.0,
        longitude: address?.longitude ?? 0.0,
      },
    },
  });

  const [coordinates, setCoordinates] = useState<
    | {
        lat: number;
        lon: number;
      }
    | undefined
  >(
    address?.latitude && address.longitude
      ? {
          lat: address.latitude,
          lon: address.longitude,
        }
      : undefined,
  );

  useEffect(() => {
    if (address?.latitude && address.longitude) {
      setCoordinates({
        lat: address.latitude,
        lon: address.longitude,
      });
    }
  }, [address]);

  const slotCreationMethodField = form.useField({
    name: 'slotCreationMethod',
  });

  return (
    <Modal
      onClose={onClose}
      loading={loading}
      confirmText="Save organisation"
      confirmCallback={form.handleSubmit}
      title={uuid ? 'Edit Organisation' : 'Add Organisation'}
      asForm
    >
      <div className="space-y-5 p-5">
        <form.Field
          name="name"
          children={({ state, handleChange }) => (
            <Input
              required
              label="Name"
              Icon={<UserGroupIcon className="size-6" />}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              error={state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="email"
          children={({ state, handleChange }) => (
            <Input
              required
              label="Email"
              Icon={<EnvelopeIcon className="size-6" />}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              error={state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="telephone"
          children={({ state, handleChange }) => (
            <Input
              label="Phone (optional)"
              Icon={<PhoneIcon className="size-6" />}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              error={state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="website"
          children={({ state, handleChange }) => (
            <Input
              label="Website (optional)"
              Icon={<GlobeAltIcon className="size-6" />}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              error={state.meta.errors.join(', ')}
            />
          )}
        />

        <form.Field
          name="address.line1"
          children={({ state, handleChange }) => (
            <Input
              required
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Address line one"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />

        <form.Field
          name="address.line2"
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Address line two (optional)"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />

        <form.Field
          name="address.city"
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="City (optional)"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />

        <form.Field
          name="address.postcode"
          validators={{
            onSubmitAsync: async ({ value }) => {
              if (coordinates) return undefined;
              if (!value.length) return 'Please add a valid postcode';
              setFetchingPostcode(true);
              const rsp = await addressUtility.getLatLon(value);
              setFetchingPostcode(false);
              if (!rsp) return 'Invalid postcode.';
              setCoordinates(rsp);
              return undefined;
            },
          }}
          children={({ state, handleChange, handleBlur }) => (
            <Input
              value={state.value}
              required
              onBlur={handleBlur}
              onChange={(e) => {
                if (coordinates) setCoordinates(undefined);
                handleChange(e.target.value);
              }}
              label="Postcode"
              error={state.meta.errors.join(', ')}
              className="w-80"
              loading={fetchingPostcode}
              success={coordinates ? 'Location details saved.' : undefined}
            />
          )}
        />

        <form.Field
          name="servicingRadius"
          children={({ state, handleChange }) => (
            <NumberInput
              max={250}
              min={50}
              title="Servicing radius (miles)"
              count={state.value}
              setCount={handleChange}
            />
          )}
        />

        <form.Field
          name="slotCreationMethod"
          children={({ state, handleChange }) => (
            <RadioSelector
              selectedOption={state.value}
              title="Slot creation method"
              options={[
                {
                  name: 'Fixed amount',
                  value: SlotCreationMethod.fixed,
                },
                {
                  name: 'By contractors available',
                  value: SlotCreationMethod.contractors,
                },
              ]}
              onSelectedOption={handleChange}
            />
          )}
        />

        {slotCreationMethodField.state.value === SlotCreationMethod.fixed && (
          <form.Field
            name="slotCreationAmount"
            children={({ state, handleChange }) => (
              <NumberInput
                max={10000}
                min={0}
                title="How many slots should be created?"
                count={state.value ?? 0}
                setCount={handleChange}
              />
            )}
          />
        )}

        <form.Field
          name="servicingRadius"
          children={({ state, handleChange }) => (
            <NumberInput
              max={250}
              min={50}
              title="Servicing radius (miles)"
              count={state.value}
              setCount={handleChange}
            />
          )}
        />
      </div>

      {error && <Alert alertType="error" text={error.message} />}
    </Modal>
  );
};
export default UpsertOrganisation;
