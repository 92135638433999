import { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import RadioSelector from '../../../atoms/RadioSelector';
import { gql, UserRole } from '@monorepo/graphql';
import { Input } from '../../../atoms/Input';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import Alert from '../../../atoms/Alerts';
import { useFragment, useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { useUser } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';
import { jobsUtility } from '../../../../utility/jobs';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import AddTask from '../AddTask';
import { useJobContext } from '../../../organisms/Job';

const issueOptions = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

const UPDATE_JOB_AUDIT = gql(`
  mutation UpdateJobAudit ($input: UpdateJobAuditInput!) {
    updateJobAudit (input: $input) {
      ... JobAuditFragment
    }
  }  
`);

type Props = {
  uuid: string;
};

const PostGeneralAudit = ({
  open,
  onClose,
  ...props
}: ModalWrapperPropsExtends & Props) => (
  <ModalWrapper dialogPanelClassname="w-[920px]" open={open} onClose={onClose}>
    <SuspendedComponent>
      <PostGeneralAuditChild onClose={onClose} {...props} />
    </SuspendedComponent>
  </ModalWrapper>
);

const PostGeneralAuditChild = ({
  onClose,
  uuid,
}: ModalPropsExtends & Props): ReactElement => {
  const audit = useFragment({
    fragment: jobsUtility.queries.JOB_AUDIT_FRAGMENT,
    from: `JobAudit:${uuid}`,
  });

  if (!audit.complete) throw new Error('Incomplete fragment');

  const { job } = useJobContext();
  const { user } = useUser();

  const [createTask, setCreateTask] = useState(false);

  const [updateJob, { loading, error }] = useMutation(UPDATE_JOB_AUDIT);
  const [canEdit, setCanEdit] = useState(true);

  const form = useForm<{
    pass: boolean;
    hasPassedTechnical: boolean;
    notes?: string;
  }>({
    onSubmit: ({ value }) => {
      void updateJob({
        variables: {
          input: {
            uuid: audit.data.uuid,
            notes: value.notes,
            hasPassed: value.pass,
            meta: [
              {
                metaKey: 'hasPassedTechnical',
                metaValue: String(value.hasPassedTechnical),
              },
            ],
          },
        },
        update: (cache) => {

          cache.evict({
            fieldName: 'indexJobActions',
            args: {
              jobUuid: job.uuid,
            },
          });

        },
      }).then(() => {
        if (createTask) {
          setShowCreateTask(true);
        } else {
          onClose(true);
        }
        notify.success('Audit submitted.');
      });
    },
    defaultValues: {
      pass: audit.data.hasPassed,
      hasPassedTechnical: !!audit.data.meta.find(
        ({ metaKey }) => metaKey === 'hasPassedTechnical',
      )?.metaValue,
      notes: audit.data.notes ?? undefined,
    },
  });

  const values = form.useStore(({ values }) => values);

  useEffect(() => {
    setCanEdit(!audit.data.submittedAt);
  }, [audit]);

  const [showCreateTask, setShowCreateTask] = useState(false);

  const isAuthorised =
    profileUtility.assertRoleSafe(user, [UserRole.superAdministrator]) ||
    audit.data.auditor?.uuid === user.profile.uuid;

  return (
    <>
      <AddTask
        customerProfileUuid={job.customer.uuid}
        jobUuid={job.uuid}
        title={`Book a remedial for ${job.displayLink}`}
        description={values.notes}
        open={showCreateTask}
        onClose={() => {
          setShowCreateTask(false);
          onClose(true);
        }}
        stopNavigation
      />
      <Modal
        onClose={onClose}
        closeText={canEdit ? 'Cancel' : 'Close'}
        title="Post general audit"
        confirmText={canEdit ? 'Submit audit' : undefined}
        confirmCallback={async () => form.handleSubmit()}
        loading={loading}
      >
        <form className="flex flex-col p-5 space-y-5">
          {audit.data.submittedAt && isAuthorised && !canEdit && (
            <Alert
              alertType={audit.data.hasPassed ? 'success' : 'error'}
              text={
                <div className="flex flex-col items-start">
                  <span className="text-body-small break-words">
                    {audit.data.hasPassed
                      ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                      : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                  </span>
                  <button
                    onClick={() => setCanEdit(true)}
                    type="button"
                    className="underline text-body-small mt-5 font-bold font-nunito"
                  >
                    Edit audit
                  </button>
                </div>
              }
            />
          )}
          <div className="flex flex-col space-y-5">
            <form.Field
              name="pass"
              children={({ state, handleChange }) => (
                <RadioSelector<boolean>
                  title="Has the install been carried out as expected?"
                  options={issueOptions.map((opt) => ({
                    ...opt,
                    disabled: !canEdit,
                  }))}
                  onSelectedOption={(value) => {
                    handleChange(value);
                    if (!value) setCreateTask(true);
                  }}
                  selectedOption={state.value}
                />
              )}
            />
            <form.Field
              name="hasPassedTechnical"
              children={({ state, handleChange }) => (
                <RadioSelector<boolean>
                  title="Is the remote systems check passing?"
                  options={issueOptions.map((opt) => ({
                    ...opt,
                    disabled: !canEdit,
                  }))}
                  onSelectedOption={(value) => {
                    handleChange(value);
                    if (!value) setCreateTask(true);
                  }}
                  selectedOption={state.value}
                />
              )}
            />
            <form.Field
              name="notes"
              children={({ state, handleChange }) => (
                <Input
                  disabled={!canEdit}
                  className="!w-120"
                  label="Auditor notes (optional)"
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  max={500}
                  type="textarea"
                />
              )}
            />
          </div>

          {canEdit && (
            <>
              <SimpleCheckbox
                label="I want to raise a task"
                checked={createTask}
                setChecked={setCreateTask}
              />
              {createTask && (
                <div className="mt-5">
                  <Alert
                    alertType="info"
                    text="You will be able to input task details on the next screen."
                  />
                </div>
              )}
            </>
          )}
          {error && (
            <div className="m-5">
              <Alert alertType="error" text={error.message} />
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};
export default PostGeneralAudit;
