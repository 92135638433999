import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import TwoLineText from '../../../atoms/TwoLineText';
import {
  Battery50Icon,
  CalendarDaysIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { getFragmentData, gql, SlotFragment } from '@monorepo/graphql';
import { useForm } from '@tanstack/react-form';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import CheckboxCard from '../../../atoms/CheckboxCard';
import { InstallationIcon } from '../../../icons/Installation';
import { styleUtility } from '../../../../utility/styleUtility';
import SchemeDropdown from '../../SchemeDropdown';
import Alert from '../../../atoms/Alerts';
import { slotsUtility } from '../../../../utility/slots';
import PartnerDropdown from '../../PartnerDropdown';
import OrganisationDropdown from '../../OrganisationDropdown';

type Props = {
  date: Date;
  selectedOrganisationUuid?: string;
  onClose: (success: boolean, data?: SlotFragment) => void;
};

const AddSlotModal = ({
  open,
  onClose,
  ...props
}: Props & { open: boolean }): ReactElement => (
  <ModalWrapper
    dialogPanelClassname="max-w-140"
    open={open}
    onClose={() => onClose(false)}
  >
    <AddSlotChild onClose={onClose} {...props} />
  </ModalWrapper>
);

const CREATE_SLOT = gql(`
  mutation CreateSlot($input: CreateSlotInput!) {
    createSlot(input: $input) {
      ...Slot
    }
  }  
`);

const AddSlotChild = ({
  date,
  selectedOrganisationUuid,
  onClose,
}: Props): ReactElement => {
  const [create, { loading, error }] = useMutation(CREATE_SLOT);

  const form = useForm<{
    schemeUuid?: string;
    partnerUuid: string;
    organisationUuid: string;
    selected: {
      isInstallationSelected: boolean;
      isRemedialSelected: boolean;
      isBatterySelected: boolean;
    };
  }>({
    onSubmit: (vals) => {
      void create({
        variables: {
          input: {
            date,
            partnerUuid: vals.value.partnerUuid,
            schemeUuid: vals.value.schemeUuid,
            organisationUuid: vals.value.organisationUuid,
            ...vals.value.selected,
          },
        },
      }).then((data) => {
        notify.success('Saved slot.');
        onClose(
          true,
          getFragmentData(
            slotsUtility.queries.SLOT_FRAGMENT,
            data.data?.createSlot,
          ),
        );
      });
    },
    defaultValues: {
      partnerUuid: '',
      organisationUuid: selectedOrganisationUuid ?? '',
      selected: {
        isInstallationSelected: true,
        isRemedialSelected: false,
        isBatterySelected: false,
      },
    },
  });

  const partnerUuidField = form.useField({
    name: 'partnerUuid'
  });

  return (
    <Modal
      confirmText="Add slot"
      confirmCallback={form.handleSubmit}
      loading={loading}
      title="Add a one-off slot"
      onClose={() => onClose(false)}
      asForm
    >
      <div className="p-5">
        <div className="mb-5">
          <Alert
            text="Creating a slot manually might lead to jobs being booked without contractors being available."
            alertType="warning"
          />
        </div>
        <div className="flex items-center space-x-5 mb-5">
          <TwoLineText
            label="Date"
            text={format(date, 'EEEE do MMMM yyyy')}
            Icon={<CalendarDaysIcon className="text-grey-400 size-5" />}
          />
        </div>
        <div className="mb-5">
          <div className="mb-5 w-full">
            <span className="mb-2 text-input-label block font-semibold">
              Slot Type
            </span>
            <form.Field
              name="selected"
              validators={{
                onSubmit: ({ value }) =>
                  !value.isBatterySelected &&
                  !value.isInstallationSelected &&
                  !value.isRemedialSelected
                    ? 'Please select a job type'
                    : undefined,
              }}
              children={({ state, handleChange }) => (
                <>
                  <div className="flex space-x-2">
                    <CheckboxCard
                      Icon={
                        <InstallationIcon
                          multiplier={1.1}
                          colour={styleUtility.colours.primary.DEFAULT}
                        />
                      }
                      title="Installation"
                      checked={state.value.isInstallationSelected}
                      setChecked={(checked) =>
                        handleChange((f) => ({
                          ...f,
                          isBatterySelected: false,
                          isRemedialSelected: false,
                          isInstallationSelected: checked,
                        }))
                      }
                      explainer="First time visit"
                    />
                    <CheckboxCard
                      Icon={
                        <WrenchScrewdriverIcon className="text-primary size-8" />
                      }
                      title="Remedial"
                      checked={state.value.isRemedialSelected}
                      setChecked={(checked) =>
                        handleChange((f) => ({
                          ...f,
                          isInstallationSelected: false,
                          isRemedialSelected: checked,
                        }))
                      }
                      explainer="Upgrade or repair"
                    />
                    <CheckboxCard
                      Icon={<Battery50Icon className="text primary size-8" />}
                      title="Battery"
                      checked={state.value.isBatterySelected}
                      setChecked={(checked) =>
                        handleChange((f) => ({
                          ...f,
                          isInstallationSelected: false,
                          isBatterySelected: checked,
                        }))
                      }
                      explainer="Battery only"
                    />
                  </div>
                  {state.meta.errors.length > 0 && (
                    <div className="flex py-2 px-3 border-red--100 bg-red-500 border mb-5 rounded mt-2">
                      <span>{state.meta.errors.join(', ')}</span>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>

        <form.Field
          name="organisationUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length === 0 ? 'Please select an organisaton' : undefined,
          }}
          children={({ state, handleChange }) => (
            <OrganisationDropdown
              suspend
              error={state.meta.errors.join(', ')}
              organisationUuid={state.value}
              setOrganisationUuid={handleChange}
            />
          )}
        />

        <form.Field
          name="partnerUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length === 0 ? 'Please select a partner' : undefined,
          }}
          children={({ state, handleChange }) => (
            <PartnerDropdown
              suspend
              error={state.meta.errors.join(', ')}
              partnerUuid={state.value}
              setPartnerUuid={handleChange}
            />
          )}
        />

        {!!partnerUuidField.state.value.length && (
          <form.Field
            name="schemeUuid"
            children={({ state, handleChange }) => (
              <SchemeDropdown
                suspend
                schemeUuid={state.value ?? ''}
                partnerUuid={partnerUuidField.state.value}
                setSchemeUuid={handleChange}
              />
            )}
          />
        )}
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};

export default AddSlotModal;
