import { ReactElement, useState } from 'react';
import { Button } from '../../../atoms/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import AddSlotModal from '../../Modals/AddSlot';
import { SlotFragment } from '@monorepo/graphql';

interface Props {
  date: Date;
  selectedOrganisationUuid?: string; 
  onAdd: (data?: SlotFragment) => void;
}

const AddSlot = ({
  date,
  onAdd,
  selectedOrganisationUuid
}: Props): ReactElement => {
  const [showAddSlotModal, setShowAddSlotModal] = useState(false);

  return (
    <>
      <div className="p-3 flex items-center pb-10">
        <span className="text-sm flex-grow">Slots</span>
        {/* <Button
          bStyle="light"
          className="h-9 w-9 !p-0 justify-center"
          Icon={<MinusIcon className="size-5" />}
        /> */}
        <Button
          bStyle="light"
          className="h-9 w-9 !p-0 ml-2 justify-center"
          onClick={() => setShowAddSlotModal(true)}
          Icon={<PlusIcon className="size-5" />}
        />
      </div>
      <AddSlotModal
        selectedOrganisationUuid={selectedOrganisationUuid}
        open={showAddSlotModal}
        onClose={(succes, data) => {
          if (succes && data) {
            onAdd(data);
          }
          setShowAddSlotModal(false);
        }}
        date={date}
      />
    </>
  );
};
export default AddSlot;
