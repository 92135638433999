import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import {
  BuildingOffice2Icon,
  CalendarIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { DropdownWithBorder } from '../../../../atoms/Dropdown';
import { AllEnum } from '../../../../../utility/calendarContext';
import {
  Exact,
  gql,
  IndexUsersFilterInput,
  IndexUsersForContactsScreenQuery,
  InputMaybe,
  PaginationInput,
  UserProfile,
  UserRole,
  UserUserProfile,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import AvatarStack from '../../../../atoms/AvatarStack';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import AddUserModal from '../../../../molecules/Modals/AddUser';
import { profileUtility } from '../../../../../utility/profile';
import OrganisationDropdown from '../../../../molecules/OrganisationDropdown';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { useSearch } from '../../../../../utility/search';

const GET_USERS = gql(`
  query IndexUsersForContactsScreen ($filters: IndexUsersFilterInput!, $pagination: PaginationInput) {
	indexUsers (filters: $filters, pagination: $pagination) {
		items {
			uuid
			firstName
      lastName
			avatarSrc
      lastLogin
      profile {
        __typename 
        ... on UserUserProfile {
          role
          organisation {
            name
          }
        }
      }
		}
    pagination {
      lastPage
      total
    }
	}
}
`);

type UserWithUserProfileType =
  IndexUsersForContactsScreenQuery['indexUsers']['items'][0] & {
    profile: UserUserProfile;
  };

const UserTabInner = ({
  queryRef,
  setTotalPages,
  setTotal
}: {
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  queryRef: QueryRef<
    IndexUsersForContactsScreenQuery,
    Exact<{
      filters: InputMaybe<IndexUsersFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotal: Dispatch<SetStateAction<number>>;
}) => {
  const users = useReadQuery(queryRef);
  useEffect(() => {
    setTotalPages(users.data.indexUsers.pagination.lastPage);
    setTotal(users.data.indexUsers.pagination.total);
  }, [setTotalPages, users, setTotal]);
  return (
    <TableRows
      widthType="pc"
      rows={users.data.indexUsers.items
        .filter(
          (i): i is UserWithUserProfileType =>
            i.profile.__typename === 'UserUserProfile',
        )
        .map((u) => ({
          uuid: u.uuid,
          cells: [
            {
              width: 22,
              content: (
                <div className="flex items-center space-x-2">
                  <AvatarStack
                    avatars={[
                      {
                        firstName: u.firstName,
                        lastName: u.lastName,
                        avatarSrc: u.avatarSrc ?? undefined,
                      },
                    ]}
                  />
                  <span className="text-body-small">
                    {u.firstName} {u.lastName}
                  </span>
                </div>
              ),
            },
            {
              width: 22,
              content: (
                <div className="flex items-center space-x-2">
                  <BuildingOffice2Icon className="size-5 text-grey-400" />
                  <span className="text-body-small">
                    {u.profile.organisation.name}
                  </span>
                </div>
              ),
            },
            {
              width: 22,
              content: (
                <div className="flex items-center space-x-2">
                  {profileUtility.roleIcon[u.profile.role]}
                  <span className="text-body-small">
                    {
                      profileUtility.roleOptions.find(
                        ({ value }) => value === u.profile.role,
                      )?.name
                    }
                  </span>
                </div>
              ),
            },
            {
              width: 22,
              content: (
                <div className="flex items-center space-x-2">
                  <CalendarIcon className="size-5 text-grey-400" />
                  <span className="text-body-small">{u.lastLogin ?? '-'}</span>
                </div>
              ),
            },
            {
              width: 12,
              content: (
                <div className="flex justify-end w-full">
                  <Button
                    href={`/contacts/${u.uuid}`}
                    bText="View"
                    bStyle="outline"
                    className="h-9"
                  />
                </div>
              ),
            },
          ],
        }))}
    />
  );
};

const UserTab = ({
  constrainSearch,
  hideOnZero
}: {
  constrainSearch?: boolean; 
  hideOnZero?: boolean;
}): ReactElement => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [total, setTotal] = useState<number>(-1);
  const [organisationUuid, setOrganisationUuid] = useState('all');
  const [role, setRole] = useState<AllEnum.all | UserRole>(AllEnum.all);
  const { setConstraint, debouncedSearchTerm } = useSearch(); 

  useEffect(() => {
    if (constrainSearch) {
      setConstraint('Users');
    }
  }, [setConstraint, constrainSearch]);

  const [queryRef] = useBackgroundQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile: UserProfile.user,
        userUserProfileRole: role !== AllEnum.all ? role : undefined,
        organisationUuid:
          organisationUuid === 'all' ? undefined : organisationUuid,
        term: debouncedSearchTerm
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  return total === 0 && hideOnZero ? <></> : (
    <>
      <TableContainer
        title={`Users ${debouncedSearchTerm.length && total > 0 ? `(${total})` : ''}`}
        toolbar={
          <div className="flex justify-center space-x-2">
            <div className="w-60">
              <SuspendedComponent multiplier={0.5} hidePadding>
                <OrganisationDropdown
                  all
                  organisationUuid={organisationUuid}
                  setOrganisationUuid={setOrganisationUuid}
                  buttonClassname="h-11 border-px !border-grey-500 !w-60 whitespace-nowrap !mb-0"
                  hideLabel
                  respectButtonWidth={false}
                  hideLeadIcon
                />
              </SuspendedComponent>
            </div>
            <DropdownWithBorder<AllEnum | UserRole>
              buttonText={
                profileUtility.roleOptions.find(({ value }) => value === role)
                  ?.name
              }
              options={profileUtility.roleOptions}
              buttonClassname="!w-60 justify-between whitespace-nowrap"
              onOptionSelect={(opt) => setRole(opt.value)}
            />
            <Button
              bText="Add"
              Icon={<PlusIcon className="text-white size-6" />}
              onClick={() => setShowAddUserModal(true)}
              reverse
            />
          </div>
        }
      >
        <TableColumns
          columns={[
            {
              width: 22,
              heading: 'name',
            },
            {
              width: 22,
              heading: 'organisation',
            },
            {
              width: 22,
              heading: 'role',
            },
            {
              width: 22,
              heading: 'last login',
            },
            {
              width: 12,
            },
          ]}
          widthType="pc"
        />
        <SuspendedComponent>
          <UserTabInner queryRef={queryRef} setTotalPages={setTotalPages} 
            setTotal={setTotal} />
        </SuspendedComponent>
        <TablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
        />
      </TableContainer>

      <AddUserModal
        open={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
      />
    </>
  );
};
export default UserTab;
