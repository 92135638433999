import { gql, ReadUserQuery, UserProfile } from '@monorepo/graphql';
import env from '../environment';
import { client } from '../main';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from '@tanstack/react-router';

const USER_FRAGMENT = gql(`
  fragment UserFragment on User {
    uuid
    firstName
    lastName
    email
    avatarSrc
    phoneNumber
    createdAt
    schemeName
    address {
      uuid
      line1
      line2
      city
      county
      postcode
      latitude
      longitude
    }
    profile {
      __typename
      ... on UserUserProfile {
        uuid
        role
        status
        organisation {
          uuid
          name
        }
      }
      ... on UserCustomerProfile {
        uuid
        schemeUuid
        jobStatus
        source
        partner {
          uuid
          name
        }
      }
      ... on UserContractorProfile {
        uuid
        excludeFromAutomaticAllocation
        testInstrumentSerialNumber
        status
        startDate
        availability
        isElectrician
        isRoofer
        isTrainee
        completionRate
        organisation {
          uuid
          name
        }
      }
    }
  }  
`);

const GET_USER = gql(`
  query ReadUser($input: ReadUserInput!) {
    readUser (input: $input) {
      ... UserFragment
    }
  }
`);

export const SignInGql = gql(`
  query SignIn($input: SignInInput!) {
    signIn(input: $input) {
      accessToken
      refreshToken
    }
  }
`);

const SignOutGql = gql(`
  query SignOut {
    logout
  }
`);

export const ResetPasswordGql = gql(`
  query ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`);

export const CompleteResetPasswordGql = gql(`
  query CompleteResetPassword($input: CompleteResetPasswordInput!) {
    completeResetPassword(input: $input)
  }
`);

const useLogin = () => {
  const navigate = useNavigate();
   const [login, { error, loading }] = useLazyQuery(SignInGql, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.signIn.refreshToken) {
        localStorage.setItem(
          '__cognito_refresh__',
          data.signIn.refreshToken
        );
      }
      localStorage.setItem('__ir_logged__', `${new Date().getTime()}`);
      sessionStorage.setItem('__cognito_token__', data.signIn.accessToken);
      void navigate({
        to: '/'
      });
    },
  });

  return {
    error,
    isFetching: loading,
    login
  };
};

const useLogout = () => {
  const navigate = useNavigate();
   const [logout, { error, loading }] = useLazyQuery(SignOutGql, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      localStorage.clear();
      sessionStorage.clear();
      void navigate({
        to: '/login'
      });
    },
  });

  return {
    error,
    isFetching: loading,
    logout
  };
};

export const usersUtility = {
  useLogin,
  useLogout,
  queries: {
    ResetPasswordGql,
    CompleteResetPasswordGql,
    USER_FRAGMENT, 
  },
  formatAddress: (address: ReadUserQuery['readUser']['address']): string =>
    address
      ? [
          address.line1,
          address.line2,
          address.city,
          address.county,
          address.postcode,
        ]
          .filter((v): v is string => !!v)
          .join(', ')
      : '',
  fetch: async ({
    uuid,
    userProfile,
  }: {
    uuid: string;
    userProfile?: UserProfile;
  }) =>
    client
      .graphqlClient({ apiUrl: env.apiUrl })
      .query({
        query: GET_USER,
        variables: {
          input: {
            uuid,
            userProfile,
          },
        }
      })
      .then((d) => d.data.readUser),
};
