import { ReactElement } from 'react';
import Tabs from '../../molecules/Tabs';
import UserTab from './Tabs/Users';
import ContractorTab from './Tabs/Contractors';
import CustomerTab from './Tabs/Customers';

const Contacts = (): ReactElement => (
    <div className="flex flex-col overflow-hidden p-5 h-full">
      <h1 className="text-h1-small font-bold font-nunito mr-5 mb-5">
        Contacts
      </h1>
      <div className="h-full flex flex-col overflow-hidden">
        <Tabs
          tabs={[
            {
              name: 'Customers',
              value: 'customers',
              component: <CustomerTab constrainSearch />,
            },
            {
              name: 'Contractors',
              value: 'contractors',
              component: <ContractorTab constrainSearch />,
            },
            {
              name: 'Users',
              value: 'users',
              component: <UserTab constrainSearch />,
            }
          ]}
        />
      </div>
    </div>
  );
export default Contacts;
