import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ProtectedApp } from './AppShell';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import env from './environment';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import { excludeGraphQLFetch, SentryLink } from 'apollo-link-sentry';
import 'react-day-picker/style.css';
import { onError } from '@apollo/client/link/error';
import { notify } from './utility/notify';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import {
  createFragmentRegistry,
  defaultDataIdFromObject,
} from '@apollo/client/cache';
import { slotsUtility } from './utility/slots';
import { commentsUtility } from './utility/comments';
import { invoicesUtiltiy } from './utility/invoices';
import { notificationsUtiltiy } from './utility/notifications';
import './styles.css';
import { jobsUtility } from './utility/jobs';
import { tasksUtility } from './utility/tasks';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

declare module '@apollo/client' {
  interface DefaultContext {
    isBatched?: boolean;
  }
}

let graphqlClient: ApolloClient<NormalizedCacheObject> | null = null;

const httpLink = (uri: string) =>
  ApolloLink.split(
    (op) => op.getContext().isBatched ?? false,
    new BatchHttpLink({
      uri,
      batchInterval: 20,
      credentials: 'include',
    }),
    new HttpLink({
      uri,
      credentials: 'include',
    }),
  );

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'FORBIDDEN':
          notify.error(err.message);
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/login';
          break;
      }
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem('__cognito_token__');

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
      __cognito_refresh__: localStorage.getItem('__cognito_refresh__'),
    },
  });

  return forward(operation).map((response) => {
    const context = operation.getContext();

    try {
      // eslint-disable-next-line
      if (context.response.headers) {
        // eslint-disable-next-line
        const accessToken = context.response.headers.get('x--cognito-access--');
        // eslint-disable-next-line
        const refreshToken = context.response.headers.get(
          'x--cognito-refresh--',
        );
        if (accessToken) {
          sessionStorage.setItem(
            '__cognito_token__',
            // eslint-disable-next-line
            accessToken,
          );
        }
        if (refreshToken) {
          localStorage.setItem(
            '__cognito_refresh__',
            // eslint-disable-next-line
            refreshToken,
          );
        }
      }
    } catch (e) {
      console.log(e);
      // do nothing
    }

    return response;
  });
});

export const client = {
  graphqlClient: (
    { apiUrl }: { apiUrl: string } | undefined = { apiUrl: env.apiUrl },
  ) => {
    if (!graphqlClient) {
      graphqlClient = new ApolloClient({
        uri: apiUrl,
        cache: new InMemoryCache({
          fragments: createFragmentRegistry(
            slotsUtility.queries.SLOT_FRAGMENT,
            commentsUtility.queries.COMMENT_FRAGMENT,
            invoicesUtiltiy.queries.INVOICE_FRAGMENT,
            notificationsUtiltiy.queries.NOTIFICATION_FRAGMENT,
            jobsUtility.queries.JOB_AUDIT_FRAGMENT,
            jobsUtility.queries.JOB_FRAGMENT,
            tasksUtility.queries.TASK_FRAGMENT,
          ),
          dataIdFromObject(responseObject) {
            if (responseObject.__typename === 'SlotJob') {
              return `${responseObject.__typename}:${String(responseObject.uuid)}:${String(responseObject.displayDuration)}`;
            }

            if (responseObject.uuid)
              return `${responseObject.__typename}:${String(responseObject.uuid)}`;

            return defaultDataIdFromObject(responseObject);
          },
          typePolicies: {
            UserUserProfile: {
              merge(existing, incoming) {
                // eslint-disable-next-line
                return {
                  ...existing,
                  ...incoming,
                };
              },
            },
            UserContractorProfile: {
              merge(existing, incoming) {
                // eslint-disable-next-line
                return {
                  ...existing,
                  ...incoming,
                };
              },
            },
            UserCustomerProfile: {
              merge(existing, incoming) {
                // eslint-disable-next-line
                return {
                  ...existing,
                  ...incoming,
                };
              },
            },
          },
        }),
        credentials: 'include',
        link: ApolloLink.from([
          new SentryLink(),
          errorLink,
          authLink,
          httpLink(apiUrl),
        ]),
      });
    }
    return graphqlClient;
  },
  handleError: (message: string) => {
    const [msg] = message.split(':');
    throw new Error(msg);
  },
};

Sentry.init({
  dsn: 'https://07b3a779c329f8f961410bd284294945@o4507663915483136.ingest.de.sentry.io/4507663917776976',
  environment: env.sentry.environment,
  beforeBreadcrumb: excludeGraphQLFetch,
});

root.render(
  <StrictMode>
    <ToastContainer />
    <ApolloProvider
      client={client.graphqlClient({
        apiUrl: env.apiUrl,
      })}
    >
      <ProtectedApp />
    </ApolloProvider>
  </StrictMode>,
);
