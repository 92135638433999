import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button } from '../../../../atoms/Button';
import { ClipboardIcon, PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { TagLight } from '../../../../atoms/Tag';
import UserDropdown from '../../../../molecules/UserDropown';
import {
  Exact,
  getFragmentData,
  gql,
  IndexTasksFilterInput,
  InputMaybe,
  PaginationInput,
  TaskStatus,
} from '@monorepo/graphql';
import { IndexTasksForCustomerProfileQuery } from '@monorepo/graphql-masked';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import Pagination, { RowsPerPage } from '../../../../molecules/Pagination';
import { format } from 'date-fns';
import { tasksUtility } from '../../../../../utility/tasks';
import { notify } from '../../../../../utility/notify';
import FileArray from '../../../../atoms/FileArray';
import AddTask from '../../../../molecules/Modals/AddTask';
import { client } from '../../../../../main';
import Robot from '../../../../icons/Robot';
import { styleUtility } from '../../../../../utility/styleUtility';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

const TASK_FRAGMENT = gql(`
  fragment TaskOnCustomerProfile on Task {
    uuid
    title
    description
    dueDate
    status
    creator {
      uuid
      firstName
      lastName
    }
    assignee {
      uuid
      firstName
      lastName
    }
    files {
      uuid
      src
      type
      name
    }
  }  
`);

const IndexTasksForCustomer = gql(`
    query IndexTasksForCustomerProfile ($filters: IndexTasksFilterInput, $pagination: PaginationInput) {
      indexTasks (filters: $filters, pagination: $pagination) {
        items {
          ... TaskOnCustomerProfile
        }
        pagination {
          lastPage
        }
      }
    }
`);

interface Props {
  customerProfileUuid: string;
  partnerUuid: string;
}

const UpdateTasksOnJob = gql(`
  mutation UpdateTaskOnCustomerProfile ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
      ... TaskOnCustomerProfile
    }
  }  
`);

const TasksTabInner = ({
  queryRef,
  setTotalPages,
}: {
  queryRef: QueryRef<
    IndexTasksForCustomerProfileQuery,
    Exact<{
      filters: InputMaybe<IndexTasksFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}): ReactElement => {
  const tasks = useReadQuery(queryRef);
  useEffect(() => {
    setTotalPages(tasks.data.indexTasks.pagination.lastPage);
  }, [tasks, setTotalPages]);

  const [saveTask] = useMutation(UpdateTasksOnJob, {
    onError: (err) => notify.error(`Unable to save task ${err.message}`),
    onCompleted: () => notify.success(`Successfully saved task`),
  });

  return (
    <>
      {tasks.data.indexTasks.items.map(t => getFragmentData(TASK_FRAGMENT, t)).map((t) => (
        <div key={t.uuid} className="border border-grey-700 rounded-md p-5 m-auto max-w-170">
          <div className="flex items-center">
            <div className="rounded-full h-12 w-12 bg-primary/10 flex items-center justify-center mr-3">
              <ClipboardIcon className="size-6 text-primary" />
            </div>
            <div className="w-full">
              <div className="flex items-center justify-between mb-2">
                <h4 className="text-h4 font-nunito font-semibold">{t.title}</h4>
                <TagLight
                  colour={t.status}
                  text={tasksUtility.taskStatusNiceMap[t.status]}
                />
              </div>
              <div className="flex items-center">
                {t.creator ? (
                  <>
                    <UserIcon className="size-4.5 text-grey-400 mr-1.5" />
                    <div className="flex items-center text-body-small space-x-2 mr-5">
                      <span>
                        Created by: {t.creator.firstName} {t.creator.lastName}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <Robot
                      multiplier={0.8}
                      colour={styleUtility.colours.grey['400']}
                    />
                    <div className="flex items-center text-body-small space-x-2 mr-5">
                      <span>Created by: System</span>
                    </div>
                  </>
                )}
                {t.dueDate && (
                  <div className="flex items-center text-body-small space-x-2">
                    <span>Due: {format(t.dueDate, 'd, MMMM yyyy')}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="py-3">
            <span className="text-body-small">{t.description}</span>
          </div>
          <FileArray files={t.files} />
          <div className="flex items-center justify-between w-full mt-5">
            <div className="flex items-center flex-grow">
              <span className="block mr-3 text-text-low-priority text-body-small">
                Assigned to:
              </span>
              <div className="w-fit">
                <UserDropdown
                  simple
                  disabled={
                    t.status === TaskStatus.done
                  }
                  userUuid={t.assignee?.uuid}
                  setUserUuid={(_, profileUuid) => {
                    void saveTask({
                      variables: {
                        input: {
                          uuid: t.uuid,
                          operatorUuid: profileUuid,
                        },
                      },
                      onCompleted: () => {
                        notify.success('Saved task');
                      },
                    });
                  }}
                />
              </div>
            </div>
            <Button
              bText="View task"
              bStyle="light"
              bSize="sm"
              className="h-9"
            />
          </div>
        </div>
      ))}
    </>
  );
};

const TasksTab = ({
  customerProfileUuid,
  partnerUuid,
}: Props): ReactElement => {
  const [perPage, setPerPage] = useState(RowsPerPage.ten);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  const [queryRef] = useBackgroundQuery(IndexTasksForCustomer, {
    variables: {
      filters: {
        customerProfileUuid,
      },
      pagination: {
        perPage,
        page,
      },
    },
  });

  return (
    <>
      <div className="bg-white p-5 flex h-full flex-col rounded-b-lg overflow-hidden">
        <div className="flex space-x-3 mb-5 items-center justify-between">
          <h2 className="text-h2 font-bold font-nunito">Tasks</h2>
          <Button
            bText="Add"
            bStyle="outline"
            reverse
            onClick={() => setShowAddTaskModal(true)}
            Icon={<PlusIcon className="text-primary size-6" />}
          />
        </div>
        <div className="flex items-center flex-col overflow-scroll">
          <div className="w-170 space-y-5">
            <SuspendedComponent>
              <TasksTabInner
                queryRef={queryRef}
                setTotalPages={setTotalPages}
              />
            </SuspendedComponent>
            <Pagination
              removePadding
              rowsPerPage={perPage}
              setRowsPerPage={setPerPage}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
      <AddTask
        open={showAddTaskModal}
        customerProfileUuid={customerProfileUuid}
        partnerUuid={partnerUuid}
        onClose={(success) => {
          setShowAddTaskModal(false);
          if (success) {
            void client.graphqlClient().refetchQueries({
              include: [IndexTasksForCustomer],
            });
          }
        }}
      />
    </>
  );
};
export default TasksTab;
