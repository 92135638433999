import { createFileRoute } from '@tanstack/react-router';
import Job from '../../../components/organisms/Job';
import { ReactNode } from 'react';
import { client } from '../../../main';
import { gql } from '@monorepo/graphql';
import ErrorScreen from '../../../components/organisms/ErrorScreen';

const READ_JOB = gql(`
  query ReadJob($uuid: String!) {
    readJob(uuid: $uuid) {
      ... JobFragment
    }
  }  
`);

const JobSingle = (): ReactNode => {
  const { uuid } = Route.useParams();
  return (
    <Job
      jobUuid={uuid}
    />
  );
};

export const Route = createFileRoute('/_authenticated/job/$uuid')({
  component: JobSingle,
  errorComponent: (e) => <ErrorScreen error={e.error} />,
  loader: async ({ params }) =>
    client.graphqlClient().query({
      query: READ_JOB,
      variables: {
        uuid: params.uuid,
      }
    }),
});
