import { gql, SlotStatus } from '@monorepo/graphql';

const slotStatusNiceMap: Record<SlotStatus, string> = {
  [SlotStatus.open]: 'Open',
  [SlotStatus.onHold]: 'On hold',
  [SlotStatus.booked]: 'Booked',
  [SlotStatus.completed]: 'Completed',
  [SlotStatus.rescheduled]: 'Rescheduled',
  [SlotStatus.incomplete]: 'Incomplete',
  [SlotStatus.cancelled]: 'Cancelled',
};

const SLOT_FRAGMENT = gql(`
  fragment Slot on Slot {
    uuid
    isElectricianAvailable
    isRooferAvailable
    isInstallationSelected
    isRemedialSelected 
    isBatterySelected
    isRevisitSelected
    schemeName
    partnerName
    organisationName
    organisationUuid
    partnerUuid
    status
    contractors {
      uuid
      firstName
      lastName
      avatarSrc
    }
    job {
      uuid
      type 
      atRisk
      displayDuration 
      completedActions
      totalActions
      difficulty
      displayInstallationDate
      customer {
        uuid
        firstName
        lastName
        line1
        city
        postcode
        schemeName
      }
    }
  }
`);

export const slotsUtility = {
  queries: {
    SLOT_FRAGMENT,
    GET_SLOTS: gql(`
      query IndexSlots($input: IndexSlotsInput!) {
        indexSlots (input: $input) {
          items {
            ...Slot
          }      
        }
      },
    `),
  },
  slotStatusNiceMap,
};
