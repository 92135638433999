import { ReactElement, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import DateSelector from '../../../atoms/DateSelector';
import { Input } from '../../../atoms/Input';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import NumberInput from '../../../atoms/NumberInput';
import {
  AddDatesToJobType,
  AvailableDate,
  gql,
  JobStatus,
  RevisitRequiredDataStatus,
} from '@monorepo/graphql';
import { addDays, format, isSameDay } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { useJobContext } from '../../../organisms/Job';
import Alert from '../../../atoms/Alerts';
import AddTask from '../AddTask';
import { notify } from '../../../../utility/notify';
import { jobsUtility } from '../../../../utility/jobs';

const SearchAvailableDatesQuery = gql(`
  query SearchForAvailableDates ($input: SearchDatesForBooking!) {
    searchDatesForRebook (input: $input) {
      slotDate
      displayDate
      slotUuids
    }
  }  
`);

const AddDatestoJobMutation = gql(`
  mutation AddDatestoJob ($input: AddDatestoJobInput!) {
    addDatesToJob(input: $input) {
      uuid 
      displayDate
      activeSlots {
        date 
        uuid
      }
    }  
  }
`);

const AddDatesToJob = ({
  open,
  onClose,
  type = AddDatesToJobType.reschedule,
}: ModalWrapperPropsExtends & { type?: AddDatesToJobType }) => (
  <ModalWrapper dialogPanelClassname="w-140" open={open} onClose={onClose}>
    <AddDatesToJobChild type={type} onClose={onClose} />
  </ModalWrapper>
);

const AddDatesToJobChild = ({
  type,
  onClose,
}: ModalPropsExtends & { type: AddDatesToJobType }): ReactElement => {
  const [availableDates, setAvailableDates] = useState<AvailableDate[]>([]);
  const { job } = useJobContext();

  const [daysRequired, setDaysRequired] = useState(job.daysRequired);
  const [targetDate, setTargetDate] = useState(
    job.targetDate ? new Date(job.targetDate) : addDays(new Date(), 1),
  );

  const [creatingTask, setCreatingTask] = useState(false);

  const [rebook, { error: mutationError, loading: mutationLoading }] =
    useMutation(AddDatestoJobMutation);

  const { data, error, loading } = useQuery(SearchAvailableDatesQuery, {
    variables: {
      input: {
        jobUuid: job.uuid,
        numberOfRequiredDays: daysRequired,
        dateToBeginSearch: targetDate,
        partnerUuid: job.partner.uuid,
      },
    },
    onCompleted: (data) => {
      setAvailableDates(data.searchDatesForRebook);
      form.setFieldValue('selectedDate', undefined);
    },
  });

  const form = useForm<{
    selectedDate: Date | undefined;
    manualDate: Date | undefined;
    hoursRequired: number;
    comments: string;
    reason: string;
    raiseTask: boolean;
  }>({
    onSubmit: ({ value }) => {
      const selectedDate = value.selectedDate;
      if (!selectedDate) return;
      const selectedSlots = data?.searchDatesForRebook.find(({ slotDate }) =>
        isSameDay(slotDate, selectedDate),
      );

      if (!selectedSlots && value.hoursRequired === 8) {
        notify.error(`No slots available.`);
        return;
      }

      void rebook({
        variables: {
          input: {
            daysRequired,
            reasonForChangingJob: value.reason,
            notes: value.comments,
            slotUuids:
              value.hoursRequired < 8 || !selectedSlots?.slotUuids.length
                ? []
                : selectedSlots.slotUuids,
            jobUuid: job.uuid,
            date: selectedDate,
            duration: value.hoursRequired,
            type: AddDatesToJobType[type],
          },
        },
        update: (cache, newJob) => {
          cache.updateFragment(
            {
              id: cache.identify(job),
              fragment: jobsUtility.queries.JOB_FRAGMENT,
            },
            (d) =>
              d
                ? {
                    ...d,
                    daysRequired,
                    targetDate: selectedDate.toISOString(),
                    duration: value.hoursRequired,
                    activeSlots:
                      newJob.data?.addDatesToJob.activeSlots ?? d.activeSlots,
                    revisitRequired: d.revisitRequired
                      ? {
                          ...d.revisitRequired,
                          status: RevisitRequiredDataStatus.booked,
                        }
                      : null,
                    status:
                      d.status === JobStatus.onHold
                        ? JobStatus.booked
                        : d.status === JobStatus.cancelled
                          ? job.revisitRequired
                            ? JobStatus.partiallyInstalled
                            : JobStatus.booked
                          : d.status,
                  }
                : null,
          );
          cache.evict({
            fieldName: 'indexContractorsForJob',
          });
          cache.gc();
        },
        onCompleted: () => {
          notify.success('Succesfully rebooked job.');

          onClose(true);
        },
      });
    },
    defaultValues: {
      selectedDate: undefined,
      manualDate: undefined,
      hoursRequired: 8,
      reason: '',
      comments: '',
      raiseTask: false,
    },
  });

  const hoursRequired = form.useField({
    name: 'hoursRequired',
  });

  return (
    <>
      <AddTask
        jobUuid={job.uuid}
        title={form.getFieldValue('reason')}
        description={form.getFieldValue('comments')}
        open={creatingTask}
        onClose={(status) => {
          setCreatingTask(false);
          onClose(status);
        }}
      />
      <Modal
        loading={mutationLoading || loading}
        title={
          type === AddDatesToJobType.reschedule
            ? 'Change job date'
            : job.revisitRequired?.status !== RevisitRequiredDataStatus.booked
              ? 'Book revisit'
              : 'Change revisit data'
        }
        onClose={onClose}
        confirmText="Save changes"
        confirmCallback={form.handleSubmit}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="p-5"
        >
          <div className="mb-5">
            <NumberInput
              max={5}
              count={daysRequired}
              setCount={setDaysRequired}
              title="Number of days required"
              disabled={hoursRequired.state.value < 8}
            />
          </div>
          {type === AddDatesToJobType.revisit && daysRequired === 1 && (
            <form.Field
              name="hoursRequired"
              children={({ state, handleChange }) => (
                <div className="mb-5">
                  <NumberInput
                    min={2}
                    max={8}
                    increment={2}
                    count={state.value}
                    setCount={handleChange}
                    title="Number of hours required"
                  />
                </div>
              )}
            />
          )}

          {hoursRequired.state.value === 8 ? (
            <>
              <div className="mb-5 w-full">
                <span className="mb-2 text-input-label block font-semibold">
                  Target date
                </span>
                <DateSelector
                  selectedDate={targetDate}
                  setSelectedDate={(date) => setTargetDate(date)}
                  showLabel
                />
                {error ? (
                  <div className="mt-2">
                    <Alert alertType="error" text={error.message} />
                  </div>
                ) : (
                  data?.searchDatesForRebook.length === 0 && (
                    <div className="mt-2">
                      <Alert
                        alertType="warning"
                        text="No dates found with this target date, try choosing a different date."
                      />
                    </div>
                  )
                )}
              </div>
              <form.Field
                name="selectedDate"
                validators={{
                  onSubmit: ({ value }) =>
                    !value ? 'Please select a date' : undefined,
                }}
                children={({ state, handleChange }) => (
                  <DropdownWithBorder
                    error={state.meta.errors.join(', ')}
                    buttonClassname="w-full justify-between mb-5"
                    label="Selected date"
                    buttonText={
                      state.value
                        ? format(state.value, 'd MMMM yyyy')
                        : '-- Select --'
                    }
                    disabled={availableDates.length === 0}
                    options={availableDates.map(
                      ({ slotDate, displayDate }) => ({
                        name: displayDate,
                        value: slotDate,
                      }),
                    )}
                    respectButtonWidth
                    onOptionSelect={(option) =>
                      handleChange(new Date(option.value))
                    }
                  />
                )}
              />
            </>
          ) : (
            <>
              <div className="mb-5">
                <Alert
                  alertType="warning"
                  text="This job is a manual allocation, you will be required to assign a contractor."
                />
              </div>
              <form.Field
                name="manualDate"
                validators={{
                  onSubmit: ({ value }) =>
                    !value ? 'Please select a date' : undefined,
                }}
                children={({ state, handleChange }) => (
                  <div className="mb-5 w-full">
                    <span className="mb-2 text-input-label block font-semibold">
                      Selected date
                    </span>
                    <DateSelector
                      selectedDate={state.value}
                      setSelectedDate={handleChange}
                      showLabel
                    />
                  </div>
                )}
              />
            </>
          )}
          {type === AddDatesToJobType.reschedule && (
            <form.Field
              name="reason"
              validators={{
                onSubmit: ({ value }) =>
                  value.length
                    ? undefined
                    : 'Please enter a reason why the job has to be rescheduled.',
              }}
              children={({ state, handleChange }) => (
                <DropdownWithBorder
                  error={state.meta.errors.join(', ')}
                  buttonClassname="w-full justify-between mb-5"
                  label="Reason for changing the date"
                  buttonText={state.value.length ? state.value : '-- Select --'}
                  options={[
                    {
                      value: 'Customer request',
                      name: 'Customer request',
                    },
                    {
                      value: 'Installer sick',
                      name: 'Installer sick',
                    },
                    {
                      value: 'Property not ready',
                      name: 'Property not ready'
                    },
                    {
                      value: 'Scaffolding issue',
                      name: 'Scaffolding issue'
                    }
                  ]}
                  respectButtonWidth
                  onOptionSelect={(option) => handleChange(option.value)}
                />
              )}
            />
          )}

          <form.Field
            name="comments"
            children={({ state, handleChange }) => (
              <Input
                className="mb-5 w-120"
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Comments (optional)"
                type="textarea"
                max={500}
              />
            )}
          />

          <form.Field
            name="raiseTask"
            children={({ state, handleChange }) => (
              <div className="mb-5 flex items-center space-x-3 w-full">
                <SimpleCheckbox
                  checked={state.value}
                  setChecked={() => handleChange((f) => !f)}
                  label="Create a task"
                />
              </div>
            )}
          />
          {mutationError && (
            <Alert alertType="error" text={mutationError.message} />
          )}
        </form>
      </Modal>
    </>
  );
};
export default AddDatesToJob;
