import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import {
  CheckCircleIcon as CheckCircleIconOutline,
  ChevronDownIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { ReactElement, useMemo } from 'react';
import CircularProgress from '../../../atoms/CircularProgress';
import { useJobContext } from '..';
import ProgressBar from '../../../atoms/ProgressBar';
import { differenceInDays } from 'date-fns';
import ExpandMoreDark from '../../../../assets/icons/ExpandMoreDark.svg';
import { gql, JobActionPlace, JobStatus } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { jobsUtility } from '../../../../utility/jobs';

const INDEX_ACTIONS = gql(`
  query IndexJobActions ($jobUuid: String!) {
    indexJobActions (jobUuid: $jobUuid) {
      uuid
      type
      place 
      completedAt
      failedAt
    }
  }
`);

const ProgressIndicator = (): ReactElement => {
  const { job } = useJobContext();

  const daysLeft = useMemo(() => {
    if (job.activeSlots.length)
      return differenceInDays(
        job.activeSlots[job.activeSlots.length - 1].date,
        new Date(),
      );
    if (job.targetDate)
      return differenceInDays(new Date(job.targetDate), new Date());
    return -1;
  }, [job]);

  const { data } = useSuspenseQuery(INDEX_ACTIONS, {
    variables: {
      jobUuid: job.uuid,
    },
  });

  const pre = useMemo(
    () =>
      data.indexJobActions.filter(({ place }) => place === JobActionPlace.pre),
    [data],
  );

  const post = useMemo(
    () =>
      data.indexJobActions.filter(({ place }) => place === JobActionPlace.post),
    [data],
  );

  const completedPost = useMemo(
    () => post.filter(({ completedAt }) => !!completedAt),
    [post],
  );
  const completedPre = useMemo(
    () => pre.filter(({ completedAt }) => !!completedAt),
    [pre],
  );

  const { completed, progress, total } = useMemo(() => {
    if (
      job.status === JobStatus.installed ||
      job.status === JobStatus.completed
    ) {
      console.log({
        total: post.length,
        completed: completedPost.length,
        progress: (completedPost.length / post.length) * 100,
      });
      if (completedPost.length === 0 && post.length === 0)
        return {
          total: completedPost.length,
          completed: 0,
          progress: 100,
        };
      return {
        total: post.length,
        completed: completedPost.length,
        progress: (completedPost.length / post.length) * 100,
      };
    }

    if (completedPre.length === 0 && pre.length === 0)
      return {
        total: completedPre.length,
        completed: 0,
        progress: 100,
      };
    return {
      total: pre.length,
      completed: completedPre.length,
      progress: (completedPre.length / post.length) * 100,
    };
  }, [job, completedPre, completedPost, post, pre]);

  return (
    <Popover className="relative">
      <PopoverButton className="flex flex-col flex-grow justify-center items-center outline-none">
        <CircularProgress size={56} completed={completed} total={total} />
        <div className="flex items-center mt-1.5">
          <span className="text-body-small">View progress</span>
          <ChevronDownIcon className="size-2.5 text-text-normal ml-1" />
        </div>
      </PopoverButton>
      <PopoverPanel
        transition
        anchor="bottom"
        className="shadow-regular border border-grey-700 overflow-hidden origin-top transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 outline-none"
      >
        <div className="p-5 bg-white w-120 rounded">
          <h4 className="text-h4 font-nunito text-bold mb-5">Job Progress</h4>
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2">
              <span className="text-xs font-bold">
                {Math.round(progress)}% completed
              </span>
              {daysLeft > -1 && (
                <span className="text-xs text-text-low-priority">
                  {daysLeft >= 0
                    ? daysLeft === 0
                      ? 'Final day'
                      : `${daysLeft + 1} days left`
                    : 'Installation complete'}
                </span>
              )}
            </div>
            <ProgressBar progress={progress} />
          </div>

          <Disclosure
            as="div"
            className="p-2 border rounded border-grey-700 flex flex-col mb-2"
          >
            <DisclosureButton className="flex items-center">
              {({ open }) => (
                <>
                  <div className="h-6 w-6 flex items-center justify-center">
                    {pre.some(({ completedAt }) => !completedAt) ? (
                      <CircularProgress
                        hideText
                        size={18}
                        strokeWidth={4}
                        completed={
                          pre.filter(({ completedAt }) => completedAt).length
                        }
                        total={pre.length}
                      />
                    ) : (
                      <CheckCircleIcon className="size-6 text-primary" />
                    )}
                  </div>
                  <h6 className="text-h6 font-semibold ml-2">
                    Pre installation
                  </h6>
                  <div className="flex items-center space-x-2 justify-end flex-grow">
                    <span className="text-low-priority text-sm">
                      {pre.length} Actions
                    </span>
                    <button>
                      <img
                        className={`${open ? 'rotate-180' : ''} transition-all`}
                        src={ExpandMoreDark}
                        alt="Expand more"
                      />
                    </button>
                  </div>
                </>
              )}
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="origin-top translate-y-0 transition-all duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              <ul className="flex flex-col mt-4 space-y-1">
                {pre.map((p) => (
                  <li key={p.uuid} className="flex items-center space-x-2">
                    <div className="h-6 w-6 flex items-center justify-center">
                      {p.failedAt ? (
                        <XCircleIcon className="size-5 text-red" />
                      ) : p.completedAt ? (
                        <CheckCircleIconOutline className="size-5 text-primary" />
                      ) : (
                        <div className="h-4 w-4 border border-grey-700 rounded-full" />
                      )}
                    </div>
                    <span className="text-sm">
                      {jobsUtility.jobActionNiceMap({ action: p })}
                    </span>
                  </li>
                ))}
              </ul>
            </DisclosurePanel>
          </Disclosure>

          <Disclosure
            as="div"
            className="p-2 border rounded border-grey-700 flex flex-col"
          >
            <DisclosureButton className="flex items-center">
              {({ open }) => (
                <>
                  <div className="h-6 w-6 flex items-center justify-center">
                    {post.some(({ completedAt }) => !completedAt) ? (
                      <CircularProgress
                        hideText
                        size={18}
                        strokeWidth={4}
                        completed={
                          post.filter(({ completedAt }) => completedAt).length
                        }
                        total={post.length}
                      />
                    ) : (
                      <CheckCircleIcon className="size-6 text-primary" />
                    )}
                  </div>
                  <h6 className="text-h6 font-semibold ml-2">
                    Post installation
                  </h6>
                  <div className="flex items-center space-x-2 justify-end flex-grow">
                    <span className="text-low-priority text-sm">
                      {post.length} Actions
                    </span>
                    <button>
                      <img
                        className={`${open ? 'rotate-180' : ''} transition-all`}
                        src={ExpandMoreDark}
                        alt="Expand more"
                      />
                    </button>
                  </div>
                </>
              )}
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="origin-top translate-y-0 transition-all duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              <ul className="flex flex-col mt-4 space-y-1">
                {post.map((p) => (
                  <li key={p.uuid} className="flex items-center space-x-2">
                    <div className="h-6 w-6 flex items-center justify-center">
                      {p.failedAt ? (
                        <XCircleIcon className="size-5 text-red" />
                      ) : p.completedAt ? (
                        <CheckCircleIconOutline className="size-5 text-primary" />
                      ) : (
                        <div className="h-4 w-4 border border-grey-700 rounded-full" />
                      )}
                    </div>
                    <span className="text-sm">
                      {jobsUtility.jobActionNiceMap({ action: p })}
                    </span>
                  </li>
                ))}
              </ul>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </PopoverPanel>
    </Popover>
  );
};
export default ProgressIndicator;
