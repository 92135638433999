import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import ErrorScreen from '../components/organisms/ErrorScreen';
import { gql } from '@monorepo/graphql';
import { client } from '../main';
import ExternalBooking from '../components/organisms/ExternalBooking';

const ZodSearchParams = z.object({
  token: z.string(),
  partnerUuid: z.string(),
});

const VERIFY_PARTNER_TOKEN = gql(`
  query VerifyPartnerToken ($input: VerifyPartnerTokenInput!) {
    verifyPartnerToken (input: $input) {
      uuid
      name
    }
  }
`);

const FeedbackRoute = () => {
  const partner = Route.useLoaderData(); 
  const { token } = Route.useSearch(); 
  return <ExternalBooking token={token} verifiedPartner={partner.data.verifyPartnerToken} />;
};

export const Route = createFileRoute('/external/booking')({
  validateSearch: ZodSearchParams,
  component: FeedbackRoute,
  loaderDeps: ({ search: { token, partnerUuid } }) => ({ token, partnerUuid }),
  errorComponent: () => <ErrorScreen error={new Error('Token required')} />,
  loader: async ({ deps }) =>
    client.graphqlClient().query({
      query: VERIFY_PARTNER_TOKEN,
      variables: {
        input: {
          partnerUuid: deps.partnerUuid,
          token: deps.token
        }
      },
    }),
});
