/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as FeedbackImport } from './routes/feedback'
import { Route as UnauthenticatedImport } from './routes/_unauthenticated'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as ExternalBookingImport } from './routes/external.booking'
import { Route as UnauthenticatedResetPasswordImport } from './routes/_unauthenticated/reset-password'
import { Route as UnauthenticatedLoginImport } from './routes/_unauthenticated/login'
import { Route as AuthenticatedBusinessIntelligenceImport } from './routes/_authenticated/business-intelligence'
import { Route as AuthenticatedTasksIndexImport } from './routes/_authenticated/tasks.index'
import { Route as AuthenticatedSupportTicketsIndexImport } from './routes/_authenticated/support-tickets.index'
import { Route as AuthenticatedPartnersIndexImport } from './routes/_authenticated/partners.index'
import { Route as AuthenticatedContactsIndexImport } from './routes/_authenticated/contacts.index'
import { Route as AuthenticatedConfigurationIndexImport } from './routes/_authenticated/configuration.index'
import { Route as AuthenticatedCalendarIndexImport } from './routes/_authenticated/calendar.index'
import { Route as AuthenticatedTicketsUuidImport } from './routes/_authenticated/tickets/$uuid'
import { Route as AuthenticatedTasksUuidImport } from './routes/_authenticated/tasks/$uuid'
import { Route as AuthenticatedSchemesUuidImport } from './routes/_authenticated/schemes/$uuid'
import { Route as AuthenticatedPartnersUuidImport } from './routes/_authenticated/partners/$uuid'
import { Route as AuthenticatedJobUuidImport } from './routes/_authenticated/job/$uuid'
import { Route as AuthenticatedContactsUuidImport } from './routes/_authenticated/contacts/$uuid'
import { Route as AuthenticatedConfigurationTeamsImport } from './routes/_authenticated/configuration/teams'
import { Route as AuthenticatedConfigurationSlasImport } from './routes/_authenticated/configuration/slas'
import { Route as AuthenticatedConfigurationSkillsImport } from './routes/_authenticated/configuration/skills'
import { Route as AuthenticatedConfigurationOrganisationsImport } from './routes/_authenticated/configuration/organisations'

// Create/Update Routes

const FeedbackRoute = FeedbackImport.update({
  id: '/feedback',
  path: '/feedback',
  getParentRoute: () => rootRoute,
} as any)

const UnauthenticatedRoute = UnauthenticatedImport.update({
  id: '/_unauthenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const ExternalBookingRoute = ExternalBookingImport.update({
  id: '/external/booking',
  path: '/external/booking',
  getParentRoute: () => rootRoute,
} as any)

const UnauthenticatedResetPasswordRoute =
  UnauthenticatedResetPasswordImport.update({
    id: '/reset-password',
    path: '/reset-password',
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const UnauthenticatedLoginRoute = UnauthenticatedLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const AuthenticatedBusinessIntelligenceRoute =
  AuthenticatedBusinessIntelligenceImport.update({
    id: '/business-intelligence',
    path: '/business-intelligence',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedTasksIndexRoute = AuthenticatedTasksIndexImport.update({
  id: '/tasks/',
  path: '/tasks/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedSupportTicketsIndexRoute =
  AuthenticatedSupportTicketsIndexImport.update({
    id: '/support-tickets/',
    path: '/support-tickets/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedPartnersIndexRoute = AuthenticatedPartnersIndexImport.update(
  {
    id: '/partners/',
    path: '/partners/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedContactsIndexRoute = AuthenticatedContactsIndexImport.update(
  {
    id: '/contacts/',
    path: '/contacts/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedConfigurationIndexRoute =
  AuthenticatedConfigurationIndexImport.update({
    id: '/configuration/',
    path: '/configuration/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedCalendarIndexRoute = AuthenticatedCalendarIndexImport.update(
  {
    id: '/calendar/',
    path: '/calendar/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedTicketsUuidRoute = AuthenticatedTicketsUuidImport.update({
  id: '/tickets/$uuid',
  path: '/tickets/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTasksUuidRoute = AuthenticatedTasksUuidImport.update({
  id: '/tasks/$uuid',
  path: '/tasks/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedSchemesUuidRoute = AuthenticatedSchemesUuidImport.update({
  id: '/schemes/$uuid',
  path: '/schemes/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPartnersUuidRoute = AuthenticatedPartnersUuidImport.update({
  id: '/partners/$uuid',
  path: '/partners/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedJobUuidRoute = AuthenticatedJobUuidImport.update({
  id: '/job/$uuid',
  path: '/job/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedContactsUuidRoute = AuthenticatedContactsUuidImport.update({
  id: '/contacts/$uuid',
  path: '/contacts/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedConfigurationTeamsRoute =
  AuthenticatedConfigurationTeamsImport.update({
    id: '/configuration/teams',
    path: '/configuration/teams',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedConfigurationSlasRoute =
  AuthenticatedConfigurationSlasImport.update({
    id: '/configuration/slas',
    path: '/configuration/slas',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedConfigurationSkillsRoute =
  AuthenticatedConfigurationSkillsImport.update({
    id: '/configuration/skills',
    path: '/configuration/skills',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedConfigurationOrganisationsRoute =
  AuthenticatedConfigurationOrganisationsImport.update({
    id: '/configuration/organisations',
    path: '/configuration/organisations',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_unauthenticated': {
      id: '/_unauthenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UnauthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/feedback': {
      id: '/feedback'
      path: '/feedback'
      fullPath: '/feedback'
      preLoaderRoute: typeof FeedbackImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/business-intelligence': {
      id: '/_authenticated/business-intelligence'
      path: '/business-intelligence'
      fullPath: '/business-intelligence'
      preLoaderRoute: typeof AuthenticatedBusinessIntelligenceImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_unauthenticated/login': {
      id: '/_unauthenticated/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof UnauthenticatedLoginImport
      parentRoute: typeof UnauthenticatedImport
    }
    '/_unauthenticated/reset-password': {
      id: '/_unauthenticated/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof UnauthenticatedResetPasswordImport
      parentRoute: typeof UnauthenticatedImport
    }
    '/external/booking': {
      id: '/external/booking'
      path: '/external/booking'
      fullPath: '/external/booking'
      preLoaderRoute: typeof ExternalBookingImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/configuration/organisations': {
      id: '/_authenticated/configuration/organisations'
      path: '/configuration/organisations'
      fullPath: '/configuration/organisations'
      preLoaderRoute: typeof AuthenticatedConfigurationOrganisationsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/configuration/skills': {
      id: '/_authenticated/configuration/skills'
      path: '/configuration/skills'
      fullPath: '/configuration/skills'
      preLoaderRoute: typeof AuthenticatedConfigurationSkillsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/configuration/slas': {
      id: '/_authenticated/configuration/slas'
      path: '/configuration/slas'
      fullPath: '/configuration/slas'
      preLoaderRoute: typeof AuthenticatedConfigurationSlasImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/configuration/teams': {
      id: '/_authenticated/configuration/teams'
      path: '/configuration/teams'
      fullPath: '/configuration/teams'
      preLoaderRoute: typeof AuthenticatedConfigurationTeamsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/contacts/$uuid': {
      id: '/_authenticated/contacts/$uuid'
      path: '/contacts/$uuid'
      fullPath: '/contacts/$uuid'
      preLoaderRoute: typeof AuthenticatedContactsUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/job/$uuid': {
      id: '/_authenticated/job/$uuid'
      path: '/job/$uuid'
      fullPath: '/job/$uuid'
      preLoaderRoute: typeof AuthenticatedJobUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/partners/$uuid': {
      id: '/_authenticated/partners/$uuid'
      path: '/partners/$uuid'
      fullPath: '/partners/$uuid'
      preLoaderRoute: typeof AuthenticatedPartnersUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/schemes/$uuid': {
      id: '/_authenticated/schemes/$uuid'
      path: '/schemes/$uuid'
      fullPath: '/schemes/$uuid'
      preLoaderRoute: typeof AuthenticatedSchemesUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/tasks/$uuid': {
      id: '/_authenticated/tasks/$uuid'
      path: '/tasks/$uuid'
      fullPath: '/tasks/$uuid'
      preLoaderRoute: typeof AuthenticatedTasksUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/tickets/$uuid': {
      id: '/_authenticated/tickets/$uuid'
      path: '/tickets/$uuid'
      fullPath: '/tickets/$uuid'
      preLoaderRoute: typeof AuthenticatedTicketsUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/calendar/': {
      id: '/_authenticated/calendar/'
      path: '/calendar'
      fullPath: '/calendar'
      preLoaderRoute: typeof AuthenticatedCalendarIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/configuration/': {
      id: '/_authenticated/configuration/'
      path: '/configuration'
      fullPath: '/configuration'
      preLoaderRoute: typeof AuthenticatedConfigurationIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/contacts/': {
      id: '/_authenticated/contacts/'
      path: '/contacts'
      fullPath: '/contacts'
      preLoaderRoute: typeof AuthenticatedContactsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/partners/': {
      id: '/_authenticated/partners/'
      path: '/partners'
      fullPath: '/partners'
      preLoaderRoute: typeof AuthenticatedPartnersIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/support-tickets/': {
      id: '/_authenticated/support-tickets/'
      path: '/support-tickets'
      fullPath: '/support-tickets'
      preLoaderRoute: typeof AuthenticatedSupportTicketsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/tasks/': {
      id: '/_authenticated/tasks/'
      path: '/tasks'
      fullPath: '/tasks'
      preLoaderRoute: typeof AuthenticatedTasksIndexImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedBusinessIntelligenceRoute: typeof AuthenticatedBusinessIntelligenceRoute
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedConfigurationOrganisationsRoute: typeof AuthenticatedConfigurationOrganisationsRoute
  AuthenticatedConfigurationSkillsRoute: typeof AuthenticatedConfigurationSkillsRoute
  AuthenticatedConfigurationSlasRoute: typeof AuthenticatedConfigurationSlasRoute
  AuthenticatedConfigurationTeamsRoute: typeof AuthenticatedConfigurationTeamsRoute
  AuthenticatedContactsUuidRoute: typeof AuthenticatedContactsUuidRoute
  AuthenticatedJobUuidRoute: typeof AuthenticatedJobUuidRoute
  AuthenticatedPartnersUuidRoute: typeof AuthenticatedPartnersUuidRoute
  AuthenticatedSchemesUuidRoute: typeof AuthenticatedSchemesUuidRoute
  AuthenticatedTasksUuidRoute: typeof AuthenticatedTasksUuidRoute
  AuthenticatedTicketsUuidRoute: typeof AuthenticatedTicketsUuidRoute
  AuthenticatedCalendarIndexRoute: typeof AuthenticatedCalendarIndexRoute
  AuthenticatedConfigurationIndexRoute: typeof AuthenticatedConfigurationIndexRoute
  AuthenticatedContactsIndexRoute: typeof AuthenticatedContactsIndexRoute
  AuthenticatedPartnersIndexRoute: typeof AuthenticatedPartnersIndexRoute
  AuthenticatedSupportTicketsIndexRoute: typeof AuthenticatedSupportTicketsIndexRoute
  AuthenticatedTasksIndexRoute: typeof AuthenticatedTasksIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedBusinessIntelligenceRoute:
    AuthenticatedBusinessIntelligenceRoute,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedConfigurationOrganisationsRoute:
    AuthenticatedConfigurationOrganisationsRoute,
  AuthenticatedConfigurationSkillsRoute: AuthenticatedConfigurationSkillsRoute,
  AuthenticatedConfigurationSlasRoute: AuthenticatedConfigurationSlasRoute,
  AuthenticatedConfigurationTeamsRoute: AuthenticatedConfigurationTeamsRoute,
  AuthenticatedContactsUuidRoute: AuthenticatedContactsUuidRoute,
  AuthenticatedJobUuidRoute: AuthenticatedJobUuidRoute,
  AuthenticatedPartnersUuidRoute: AuthenticatedPartnersUuidRoute,
  AuthenticatedSchemesUuidRoute: AuthenticatedSchemesUuidRoute,
  AuthenticatedTasksUuidRoute: AuthenticatedTasksUuidRoute,
  AuthenticatedTicketsUuidRoute: AuthenticatedTicketsUuidRoute,
  AuthenticatedCalendarIndexRoute: AuthenticatedCalendarIndexRoute,
  AuthenticatedConfigurationIndexRoute: AuthenticatedConfigurationIndexRoute,
  AuthenticatedContactsIndexRoute: AuthenticatedContactsIndexRoute,
  AuthenticatedPartnersIndexRoute: AuthenticatedPartnersIndexRoute,
  AuthenticatedSupportTicketsIndexRoute: AuthenticatedSupportTicketsIndexRoute,
  AuthenticatedTasksIndexRoute: AuthenticatedTasksIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface UnauthenticatedRouteChildren {
  UnauthenticatedLoginRoute: typeof UnauthenticatedLoginRoute
  UnauthenticatedResetPasswordRoute: typeof UnauthenticatedResetPasswordRoute
}

const UnauthenticatedRouteChildren: UnauthenticatedRouteChildren = {
  UnauthenticatedLoginRoute: UnauthenticatedLoginRoute,
  UnauthenticatedResetPasswordRoute: UnauthenticatedResetPasswordRoute,
}

const UnauthenticatedRouteWithChildren = UnauthenticatedRoute._addFileChildren(
  UnauthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof UnauthenticatedRouteWithChildren
  '/feedback': typeof FeedbackRoute
  '/business-intelligence': typeof AuthenticatedBusinessIntelligenceRoute
  '/login': typeof UnauthenticatedLoginRoute
  '/reset-password': typeof UnauthenticatedResetPasswordRoute
  '/external/booking': typeof ExternalBookingRoute
  '/': typeof AuthenticatedIndexRoute
  '/configuration/organisations': typeof AuthenticatedConfigurationOrganisationsRoute
  '/configuration/skills': typeof AuthenticatedConfigurationSkillsRoute
  '/configuration/slas': typeof AuthenticatedConfigurationSlasRoute
  '/configuration/teams': typeof AuthenticatedConfigurationTeamsRoute
  '/contacts/$uuid': typeof AuthenticatedContactsUuidRoute
  '/job/$uuid': typeof AuthenticatedJobUuidRoute
  '/partners/$uuid': typeof AuthenticatedPartnersUuidRoute
  '/schemes/$uuid': typeof AuthenticatedSchemesUuidRoute
  '/tasks/$uuid': typeof AuthenticatedTasksUuidRoute
  '/tickets/$uuid': typeof AuthenticatedTicketsUuidRoute
  '/calendar': typeof AuthenticatedCalendarIndexRoute
  '/configuration': typeof AuthenticatedConfigurationIndexRoute
  '/contacts': typeof AuthenticatedContactsIndexRoute
  '/partners': typeof AuthenticatedPartnersIndexRoute
  '/support-tickets': typeof AuthenticatedSupportTicketsIndexRoute
  '/tasks': typeof AuthenticatedTasksIndexRoute
}

export interface FileRoutesByTo {
  '': typeof UnauthenticatedRouteWithChildren
  '/feedback': typeof FeedbackRoute
  '/business-intelligence': typeof AuthenticatedBusinessIntelligenceRoute
  '/login': typeof UnauthenticatedLoginRoute
  '/reset-password': typeof UnauthenticatedResetPasswordRoute
  '/external/booking': typeof ExternalBookingRoute
  '/': typeof AuthenticatedIndexRoute
  '/configuration/organisations': typeof AuthenticatedConfigurationOrganisationsRoute
  '/configuration/skills': typeof AuthenticatedConfigurationSkillsRoute
  '/configuration/slas': typeof AuthenticatedConfigurationSlasRoute
  '/configuration/teams': typeof AuthenticatedConfigurationTeamsRoute
  '/contacts/$uuid': typeof AuthenticatedContactsUuidRoute
  '/job/$uuid': typeof AuthenticatedJobUuidRoute
  '/partners/$uuid': typeof AuthenticatedPartnersUuidRoute
  '/schemes/$uuid': typeof AuthenticatedSchemesUuidRoute
  '/tasks/$uuid': typeof AuthenticatedTasksUuidRoute
  '/tickets/$uuid': typeof AuthenticatedTicketsUuidRoute
  '/calendar': typeof AuthenticatedCalendarIndexRoute
  '/configuration': typeof AuthenticatedConfigurationIndexRoute
  '/contacts': typeof AuthenticatedContactsIndexRoute
  '/partners': typeof AuthenticatedPartnersIndexRoute
  '/support-tickets': typeof AuthenticatedSupportTicketsIndexRoute
  '/tasks': typeof AuthenticatedTasksIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_unauthenticated': typeof UnauthenticatedRouteWithChildren
  '/feedback': typeof FeedbackRoute
  '/_authenticated/business-intelligence': typeof AuthenticatedBusinessIntelligenceRoute
  '/_unauthenticated/login': typeof UnauthenticatedLoginRoute
  '/_unauthenticated/reset-password': typeof UnauthenticatedResetPasswordRoute
  '/external/booking': typeof ExternalBookingRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/configuration/organisations': typeof AuthenticatedConfigurationOrganisationsRoute
  '/_authenticated/configuration/skills': typeof AuthenticatedConfigurationSkillsRoute
  '/_authenticated/configuration/slas': typeof AuthenticatedConfigurationSlasRoute
  '/_authenticated/configuration/teams': typeof AuthenticatedConfigurationTeamsRoute
  '/_authenticated/contacts/$uuid': typeof AuthenticatedContactsUuidRoute
  '/_authenticated/job/$uuid': typeof AuthenticatedJobUuidRoute
  '/_authenticated/partners/$uuid': typeof AuthenticatedPartnersUuidRoute
  '/_authenticated/schemes/$uuid': typeof AuthenticatedSchemesUuidRoute
  '/_authenticated/tasks/$uuid': typeof AuthenticatedTasksUuidRoute
  '/_authenticated/tickets/$uuid': typeof AuthenticatedTicketsUuidRoute
  '/_authenticated/calendar/': typeof AuthenticatedCalendarIndexRoute
  '/_authenticated/configuration/': typeof AuthenticatedConfigurationIndexRoute
  '/_authenticated/contacts/': typeof AuthenticatedContactsIndexRoute
  '/_authenticated/partners/': typeof AuthenticatedPartnersIndexRoute
  '/_authenticated/support-tickets/': typeof AuthenticatedSupportTicketsIndexRoute
  '/_authenticated/tasks/': typeof AuthenticatedTasksIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/feedback'
    | '/business-intelligence'
    | '/login'
    | '/reset-password'
    | '/external/booking'
    | '/'
    | '/configuration/organisations'
    | '/configuration/skills'
    | '/configuration/slas'
    | '/configuration/teams'
    | '/contacts/$uuid'
    | '/job/$uuid'
    | '/partners/$uuid'
    | '/schemes/$uuid'
    | '/tasks/$uuid'
    | '/tickets/$uuid'
    | '/calendar'
    | '/configuration'
    | '/contacts'
    | '/partners'
    | '/support-tickets'
    | '/tasks'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/feedback'
    | '/business-intelligence'
    | '/login'
    | '/reset-password'
    | '/external/booking'
    | '/'
    | '/configuration/organisations'
    | '/configuration/skills'
    | '/configuration/slas'
    | '/configuration/teams'
    | '/contacts/$uuid'
    | '/job/$uuid'
    | '/partners/$uuid'
    | '/schemes/$uuid'
    | '/tasks/$uuid'
    | '/tickets/$uuid'
    | '/calendar'
    | '/configuration'
    | '/contacts'
    | '/partners'
    | '/support-tickets'
    | '/tasks'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_unauthenticated'
    | '/feedback'
    | '/_authenticated/business-intelligence'
    | '/_unauthenticated/login'
    | '/_unauthenticated/reset-password'
    | '/external/booking'
    | '/_authenticated/'
    | '/_authenticated/configuration/organisations'
    | '/_authenticated/configuration/skills'
    | '/_authenticated/configuration/slas'
    | '/_authenticated/configuration/teams'
    | '/_authenticated/contacts/$uuid'
    | '/_authenticated/job/$uuid'
    | '/_authenticated/partners/$uuid'
    | '/_authenticated/schemes/$uuid'
    | '/_authenticated/tasks/$uuid'
    | '/_authenticated/tickets/$uuid'
    | '/_authenticated/calendar/'
    | '/_authenticated/configuration/'
    | '/_authenticated/contacts/'
    | '/_authenticated/partners/'
    | '/_authenticated/support-tickets/'
    | '/_authenticated/tasks/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  UnauthenticatedRoute: typeof UnauthenticatedRouteWithChildren
  FeedbackRoute: typeof FeedbackRoute
  ExternalBookingRoute: typeof ExternalBookingRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  UnauthenticatedRoute: UnauthenticatedRouteWithChildren,
  FeedbackRoute: FeedbackRoute,
  ExternalBookingRoute: ExternalBookingRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_unauthenticated",
        "/feedback",
        "/external/booking"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/business-intelligence",
        "/_authenticated/",
        "/_authenticated/configuration/organisations",
        "/_authenticated/configuration/skills",
        "/_authenticated/configuration/slas",
        "/_authenticated/configuration/teams",
        "/_authenticated/contacts/$uuid",
        "/_authenticated/job/$uuid",
        "/_authenticated/partners/$uuid",
        "/_authenticated/schemes/$uuid",
        "/_authenticated/tasks/$uuid",
        "/_authenticated/tickets/$uuid",
        "/_authenticated/calendar/",
        "/_authenticated/configuration/",
        "/_authenticated/contacts/",
        "/_authenticated/partners/",
        "/_authenticated/support-tickets/",
        "/_authenticated/tasks/"
      ]
    },
    "/_unauthenticated": {
      "filePath": "_unauthenticated.tsx",
      "children": [
        "/_unauthenticated/login",
        "/_unauthenticated/reset-password"
      ]
    },
    "/feedback": {
      "filePath": "feedback.tsx"
    },
    "/_authenticated/business-intelligence": {
      "filePath": "_authenticated/business-intelligence.tsx",
      "parent": "/_authenticated"
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/reset-password": {
      "filePath": "_unauthenticated/reset-password.tsx",
      "parent": "/_unauthenticated"
    },
    "/external/booking": {
      "filePath": "external.booking.tsx"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/configuration/organisations": {
      "filePath": "_authenticated/configuration/organisations.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/configuration/skills": {
      "filePath": "_authenticated/configuration/skills.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/configuration/slas": {
      "filePath": "_authenticated/configuration/slas.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/configuration/teams": {
      "filePath": "_authenticated/configuration/teams.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/contacts/$uuid": {
      "filePath": "_authenticated/contacts/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/job/$uuid": {
      "filePath": "_authenticated/job/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/partners/$uuid": {
      "filePath": "_authenticated/partners/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/schemes/$uuid": {
      "filePath": "_authenticated/schemes/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/tasks/$uuid": {
      "filePath": "_authenticated/tasks/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/tickets/$uuid": {
      "filePath": "_authenticated/tickets/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/calendar/": {
      "filePath": "_authenticated/calendar.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/configuration/": {
      "filePath": "_authenticated/configuration.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/contacts/": {
      "filePath": "_authenticated/contacts.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/partners/": {
      "filePath": "_authenticated/partners.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/support-tickets/": {
      "filePath": "_authenticated/support-tickets.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/tasks/": {
      "filePath": "_authenticated/tasks.index.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
