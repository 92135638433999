import { ReactElement, useState } from 'react';
import { gql, TargetType, UserProfileStatus } from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import { notFound, useRouter } from '@tanstack/react-router';
import {
  ArrowLeftIcon,
  BoltIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  MapPinIcon,
  PhoneIcon,
  PowerIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline';
import SimpleModal from '../../molecules/Modals/Simple';
import { notify } from '../../../utility/notify';
import { usersUtility } from '../../../utility/users';
import { profileUtility } from '../../../utility/profile';
import { useMutation } from '@apollo/client';
import Tag, { TagLight } from '../../atoms/Tag';
import AvatarStack from '../../atoms/AvatarStack';
import TwoLineText from '../../atoms/TwoLineText';
import { format } from 'date-fns';
import { contractorUtility } from '../../../utility/contractors';
import { RooferIcon } from '../../icons/Roofer';

import { styleUtility } from '../../../utility/styleUtility';
import Tabs from '../../molecules/Tabs';
import JobsTab from './Tabs/Jobs';
import SkillsTab from './Tabs/Skills';
import CommentsTab from './Tabs/Comments';
import EditContractor from '../../molecules/Modals/EditContractor';
import EditAddress from '../../molecules/Modals/EditAddress';
import ExtrasTab from './Tabs/Extras';
import AvailabilityTab from './Tabs/Availability';
import AuditModal from '../../molecules/Modals/Audit';
import HolidayTab from './Tabs/Holiday';
import { client } from '../../../main';

interface Props {
  uuid: string;
}

const UPDATE_USER_CONTRACTOR_PROFILE = gql(`
  mutation UpdateUserContractorProfileStatus($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile(input: $input) {
      userUuid
    }
  }
`);

const UserContractorProfile = ({ uuid }: Props): ReactElement => {
  const user = client.graphqlClient().cache.readFragment({
    id: `User:${uuid}`,
    fragment: usersUtility.queries.USER_FRAGMENT,
  });

  if (!user) throw notFound();

  profileUtility.assertProfile(user, 'UserContractorProfile');

  const router = useRouter();

  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [amendUser] = useMutation(UPDATE_USER_CONTRACTOR_PROFILE);
  const userIsActive = user.profile.status === UserProfileStatus.active;

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Contractor #{user.profile.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
            <Button
              className=" enabled:!text-red"
              onClick={() => setShowStatusModal(true)}
              bText={
                user.profile.status === UserProfileStatus.active
                  ? 'Deactivate'
                  : 'Activate'
              }
              bSize="sm"
              Icon={
                user.profile.status === UserProfileStatus.active ? (
                  <ExclamationCircleIcon className="size-5 text-red" />
                ) : (
                  <PowerIcon className="size-5" />
                )
              }
              bStyle="light"
            />
          </div>
          <div className="bg-white rounded p-5 flex flex-col mb-5">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <Tag
                  size="lg"
                  text={userIsActive ? 'Active' : 'Inactive'}
                  colour={userIsActive ? 'completed' : 'cancelled'}
                />
                {user.profile.isTrainee && (
                  <TagLight size="lg" text="Trainee" colour="inProgress" />
                )}
              </div>
              <Button
                bStyle="outline"
                onClick={() => setShowEditModal(true)}
                bText="Edit"
              />
            </div>
            <div className="flex">
              <div className="flex items-center flex-col max-w-100 w-1/3 p-5">
                <AvatarStack
                  heading
                  height="h-20"
                  width="w-20"
                  avatars={[
                    {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      avatarSrc: user.avatarSrc ?? undefined,
                    },
                  ]}
                />
                <h1 className="text-h1-small font-nunito font-bold mt-4 mb-2">
                  {user.firstName} {user.lastName}
                </h1>
                <div className="flex mb-5">
                  <MapPinIcon className="size-5 text-grey-400 shrink-0" />
                  <div className="text-center">
                    <span>{usersUtility.formatAddress(user.address)} •</span>{' '}
                    <button
                      onClick={() => setShowEditAddressModal(true)}
                      className="text-primary underline font-semibold"
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="flex w-full justify-center space-x-6 py-5">
                  {user.profile.isElectrician && (
                    <div className="flex w-1/3 flex-col items-center">
                      <BoltIcon className="size-9 text-primary" />
                      <span className="text-body small mt-2 block">
                        Electrician
                      </span>
                    </div>
                  )}
                  {user.profile.isRoofer && (
                    <div className="flex w-1/3 flex-col items-center">
                      <RooferIcon
                        multiplier={1.5}
                        colour={styleUtility.colours.primary.DEFAULT}
                      />
                      <span className="text-body small mt-2 block">Roofer</span>
                    </div>
                  )}
                  <div className="flex w-1/3 flex-col items-center space-between">
                    <h1 className="text-h1 leading-none mt-1 font-nunito  font-semibold text-primary">
                      {user.profile.completionRate}%
                    </h1>
                    <span className="text-body text-center small mt-2 block">
                      Completion
                    </span>
                  </div>
                </div>
              </div>
              <hr className="border-none w-px h-full bg-grey-700" />
              <div className="grid grid-cols-2 gap-5 p-5 flex-grow">
                <div className="basis-1/2">
                  <TwoLineText
                    label="Email"
                    text={user.email}
                    Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Phone"
                    text={user.phoneNumber ?? '-'}
                    Icon={<PhoneIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Organisation"
                    text={user.profile.organisation.name}
                    Icon={
                      <BuildingOffice2Icon className="size-5 text-grey-400" />
                    }
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Test instrument serial number"
                    text={user.profile.testInstrumentSerialNumber ?? '-'}
                    Icon={<WrenchIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Availability"
                    text={`${contractorUtility.getAvailability(
                      user.profile.availability,
                    )}`}
                    Icon={<CalendarIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Start date"
                    text={format(
                      new Date(user.profile.startDate),
                      'do MMMM yyyy',
                    )}
                    Icon={<CalendarIcon className="size-5 text-grey-400" />}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tabs
            tabs={[
              {
                name: 'Jobs',
                value: 'jobs',
                component: (
                  <JobsTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Skills',
                value: 'skills',
                component: (
                  <SkillsTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Availability',
                value: 'availability',
                component: <AvailabilityTab userUuid={user.uuid} />,
              },
              {
                name: 'Holiday',
                value: 'holiday',
                component: (
                  <HolidayTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Comments',
                value: 'comments',
                component: (
                  <CommentsTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Extras',
                value: 'extras',
                component: (
                  <ExtrasTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
            ]}
          />
        </div>
      </div>

      <EditContractor
        contractorProfilleUuid={user.profile.uuid}
        open={showEditModal}
        organisationName={user.profile.organisation.name}
        onClose={(success, data) => {
          if (success && data) {
            client.graphqlClient().cache.updateFragment(
              {
                id: client.graphqlClient().cache.identify(user),
                fragment: usersUtility.queries.USER_FRAGMENT,
              },
              (d) => {
                if (!d) return null;

                profileUtility.assertProfile(d, 'UserContractorProfile');

                const {
                  excludeFromAutomaticAllocation,
                  testInstrumentSerialNumber,
                  isTrainee,
                  ...rest
                } = data;

                return {
                  ...d,
                  ...rest,
                  profile: {
                    ...d.profile,
                    isTrainee,
                    excludeFromAutomaticAllocation,
                    testInstrumentSerialNumber:
                      testInstrumentSerialNumber ?? null,
                  },
                };
              },
            );
          }
          setShowEditModal(false);
        }}
        testInstrumentSerialNumber={
          user.profile.testInstrumentSerialNumber ?? undefined
        }
        firstName={user.firstName}
        phoneNumber={user.phoneNumber ?? undefined}
        lastName={user.lastName}
        email={user.email}
        trades={{
          isElectrician: user.profile.isElectrician,
          isRoofer: user.profile.isRoofer,
        }}
        isTrainee={user.profile.isTrainee}
        organisationUuid={user.profile.organisation.uuid}
        excludeFromAutomaticAllocation={
          user.profile.excludeFromAutomaticAllocation
        }
      />

      {user.address && (
        <EditAddress
          uuid={user.address.uuid}
          open={showEditAddressModal}
          onClose={(success, data) => {
            if (success && data) {
              client.graphqlClient().cache.updateFragment(
                {
                  id: client.graphqlClient().cache.identify(user),
                  fragment: usersUtility.queries.USER_FRAGMENT,
                },
                (d) =>
                  d
                    ? {
                        ...d,
                        address: d.address
                          ? {
                              ...d.address,
                              ...data,
                            }
                          : null,
                      }
                    : null,
              );
            }
            setShowEditAddressModal(false);
          }}
          line1={user.address.line1}
          line2={user.address.line2 ?? undefined}
          city={user.address.city ?? undefined}
          county={user.address.county ?? undefined}
          postcode={user.address.postcode}
        />
      )}

      <AuditModal
        targetUuid={user.uuid}
        targetType={TargetType.user}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />

      <SimpleModal
        icon="warning"
        text="Are you sure you want to update the users access?"
        title={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        onConfirm={() => {
          amendUser({
            variables: {
              input: {
                uuid: user.profile.uuid,
                status: userIsActive
                  ? UserProfileStatus.inactive
                  : UserProfileStatus.active,
              },
            },
            update: (cache) => {
              cache.updateFragment(
                {
                  id: cache.identify(user),
                  fragment: usersUtility.queries.USER_FRAGMENT,
                },
                (d) =>
                  d
                    ? {
                        ...d,
                        profile: {
                          ...d.profile,
                          status: userIsActive
                            ? UserProfileStatus.inactive
                            : UserProfileStatus.active,
                        },
                      }
                    : null,
              );
            },
          })
            .then(() => {
              notify.success('Saved user');
            })
            .catch(() => notify.error(`Unable to save user.`))
            .finally(() => setShowStatusModal(false));
        }}
        onConfirmText={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        open={showStatusModal}
        onClose={() => setShowStatusModal(false)}
      />
    </>
  );
};

export default UserContractorProfile;
